/*######################################################
Creation Date: 01/02/2021
Author: Jean-Baptiste ROUGET
Updates:
# 01/02/2021: Creation
# 21/07/2021: Add logic to get user detail from DB
# 31/08/2021: Code review. Look and feel change
######################################################*/
import React from 'react';
import Container from '@material-ui/core/Container';
import AppBar from '@material-ui/core/AppBar';
import Button from '@material-ui/core/Button';
import Toolbar from '@material-ui/core/Toolbar';
import Link from '@material-ui/core/Link';
import Brand from '../components/brand/Brand';
import Box from '@material-ui/core/Box';
import IconButton from '@material-ui/core/IconButton';
import MenuOutlinedIcon from '@material-ui/icons/MenuOutlined';
//import Tooltip from '@mui/material/Tooltip';
import GeniusDrawer from './GeniusDrawer';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import theme from '../theme/lightTheme';
import styles from '../theme/style';
//import {   selectIsLoggedIn, selectToken} from '../store/reducers/authentication';
//import { selectCookieSetup, selectAuthCookie, selectIdpassreminder } from '../store/reducers/legal';
//import { selectMyActiveDraftReceipe, selectMyDraftReceipes, GetMyReceipeDrafts } from '../store/reducers/receipe';
//import { getUserInfo, selectUserEmail } from '../store/reducers/currentuser';
//import { useDispatch, useSelector } from 'react-redux';
//import { useCookies } from 'react-cookie';
//import {  useHistory } from 'react-router-dom';

const Header = (props) => {
    //VARIABLES
    //let historynavigator = useHistory();                                                //Allow page change without re-rendering
    const classes = styles();                                                           //Loading page CSS
    const matches = useMediaQuery(theme.breakpoints.between('xs', 'xs'));               //Checks page size




    const [opendrawer, setOpendrawer] = React.useState(false);
    // eslint-disable-next-line



    function handleMenuOpen() {
        if (opendrawer) { setOpendrawer(false); } else { setOpendrawer(true); }
    }

    /*
     *  {cookieSetup ?
                                        <Button href='/auth/login' color="primary" variant="outlined" className={classes.link} style={{ maxHeight: "32px" }}>Me connecter</Button>
                                        :
                                        <Tooltip title="Veuillez paramétrer les préférences cookies avant de vous connecter.">
                                            <Button color="primary" variant="outlined" onClick={() => {
                                                alert('Veuillez accepter ou refuser les cookies avant de vous connecter.');
                                            }} className={classes.link} style={{ maxHeight: "32px" }}>Me connecter</Button>
                                        </Tooltip>
                                    }
     */

    let size = 'xl';
   

    if (matches === false) { //CHECK IF SCREEN IS LARGE
        //More than tablet
        return (
            <Container className={classes.main}>
                <Container component='main' className={classes.containerXL}>
                    <AppBar style={{ backgroundColor: 'white' }} elevation={0} className={classes.appBar}>
                        <Toolbar>
                            <Box align='left'>
                                <Link href="/"><Brand /></Link>
                            </Box>
                            <Box flexGrow={1}>
                                <Link variant="h6" color="textPrimary" href="/" className={classes.toolbarTitle}>Les Toqués</Link>
                            </Box>
                            
                                <Box>
                                    <Link variant="h6" color="textPrimary" href="#receipes" className={classes.toolbarTitle}>Recettes</Link>
                                    <Link variant="h6" color="textPrimary" href="#menus" className={classes.toolbarTitle}>Menus</Link>
                                    
                                        <Button href='/auth/login' color="primary" variant="outlined" className={classes.link} style={{ maxHeight: "32px" }}>Me connecter</Button>
                                        
                                    
                                </Box>
                           

                        </Toolbar>

                       
                    </AppBar>

                </Container>
            </Container>
        );
    }
    else {
        //Tablet or less
        return (
            <Container className={classes.main}>
                <Container component='main' className={classes.containerXL}>
                    <AppBar color="white" elevation={0} className={classes.appBar}>
                        <Container maxWidth={size} component='main'>
                            <Toolbar className={classes.toolbar}>
                                <IconButton edge="start" aria-label="menu" onClick={() => { handleMenuOpen() }}>
                                    <MenuOutlinedIcon color="primary" />
                                </IconButton>

                                <Box flexGrow={1}>
                                    <Link variant="h6" color="textPrimary" href="/" className={classes.toolbarTitle}>Les Toqués</Link>
                                </Box>
                               
                                    <Box>
                                        <Button href="/auth/login" color="primary" variant="outlined" className={classes.link} style={{ maxHeight: "32px" }}>Me connecter</Button>
                                    </Box>
                               
                            </Toolbar>
                        </Container>
                        <GeniusDrawer connected={false} open={opendrawer} onClose={() => { setOpendrawer(false) }} />
                    </AppBar>
                </Container>
            </Container>
        );
    }
}

/*
function mapStateToProps({ authentication, legal }) {
    return { authentication, legal };
}

export default connect(mapStateToProps) (Header);
*/

export default Header;


