/*######################################################
Creation Date: 01/01/2021
Author: Jean-Baptiste ROUGET
Definition: Definition of the app setup: routing, and initiating launch
Updates:
# 01/01/2021: Creation
# 31/08/2021: Refining code, adding comprehensive comments, decreasing file size. Code review and corrections
# 28/09/2021: Adding back pages after code refinement
######################################################*/
import React from 'react';
import CssBaseline from '@material-ui/core/CssBaseline';
import AuthenticationLayer from './components/library/AuthenticationLayer';
import "fontsource-roboto";
import { Router, Route, Switch } from 'react-router-dom';
import { ThemeProvider } from '@material-ui/core/styles';
import { CookiesProvider } from 'react-cookie';
import lightTheme from "./theme/lightTheme";
import Footer from './components/Footer';
import Header from './components/Header';
import Home from './pages/Home';
import Login from './pages/authentication/Login';
import { selectCookieSetup } from './store/reducers/legal';
import Signin from './pages/authentication/Signin';
import AccountValidation from './pages/authentication/AccountValidation';
import ForgottenPassword from './pages/authentication/ForgottenPassword';
import ViewReceipe from './pages/receipe/view-receipe';
import CreateActionInReceipe from './pages/receipe/createActionInReceipe';
import EditDraft from './pages/app/EditDraft';
import Privacy from './pages/legal/Privacy';
import Terms from './pages/legal/Terms';
import Version from './pages/Version';
import AppStructure from './components/AppStructure';
import CookieBanner from './components/CookieBanner';
import Dashboard from './pages/app/Dashboard';
import { selectIsLoggedIn } from './store/reducers/authentication';
import history from "./history";
import { useSelector } from 'react-redux';
import './App.css';


function App() {
    const amIloggedin = useSelector(selectIsLoggedIn);
    const cookieSetup = useSelector(selectCookieSetup);
    if (amIloggedin) {
        //In case I am logged in, show App pages and structure 
        return (
            <CookiesProvider>
                <ThemeProvider theme={lightTheme}>
                    <React.Fragment>
                        <CssBaseline />
                        <AuthenticationLayer>
                            
                            <Router history={history}>
                                <AppStructure>
                                    <Route exact path="/" component={Dashboard} />
                                    <Route exact path="/receipe/draft/:receipe_slug" component={EditDraft} />
                                    <Route exact path="/receipe/draft/:receipe_slug/action" component={CreateActionInReceipe} />
                                    {cookieSetup ? <></> : <CookieBanner />}
                                </AppStructure>
                                </Router>
                            
                        </AuthenticationLayer>
                    </React.Fragment>
                </ThemeProvider>
            </CookiesProvider>
        );
    }
    else {
        //In case I am not logged in, show basic website and structure
        return (
            <CookiesProvider>
                <ThemeProvider theme={lightTheme}>
                    <React.Fragment>
                        <CssBaseline />
                        <AuthenticationLayer>
                            <Router history={history}>
                                <Header />
                                <Switch>
                                    <Route exact path="/" component={Home} />
                                    <Route exact path="/auth/forgottenpassword" component={ForgottenPassword} />
                                    <Route exact path="/auth/login" component={Login} />
                                    <Route exact path="/auth/signin" component={Signin} />
                                    <Route exact path="/auth/validation/:validationkey" component={AccountValidation} />
                                    <Route exact path="/legal/privacy" component={Privacy} />
                                    <Route exact path="/legal/terms" component={Terms} />
                                    <Route exact path="/receipe/:receipe_slug" component={ViewReceipe} />
                                    <Route exact path="/version" component={Version} />
                                   
                                </Switch>
                                <Footer />
                                {cookieSetup ? <></> : <CookieBanner />}
                                
                            </Router>
                        </AuthenticationLayer>
                    </React.Fragment>
                </ThemeProvider>
            </CookiesProvider>
        );
    }

  
}

//<Route exact path="/check/version" component={Version} />

export default App;
