/*######################################################
Creation Date: 01/02/2021
Author: Jean-Baptiste ROUGET
Updates:
# 01/02/2021: Creation
# 27/09/2021: Refine and comment code
######################################################*/
import Typography from '@material-ui/core/Typography';
import React from 'react';
import Container from '@material-ui/core/Container';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
//import Fade from '@material-ui/core/Fade';
import Button from '@material-ui/core/Button';
import Switch from '@material-ui/core/Switch';
import FormLabel from '@material-ui/core/FormLabel';
import FormControl from '@material-ui/core/FormControl';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Link from '@material-ui/core/Link';
import cookiemonamour from '../assets/images/cookies.jpg';
import styles from "../theme/style";
import { AcceptCookies } from '../store/reducers/legal';
import { useDispatch } from 'react-redux';
import { connect } from 'react-redux';
import { useCookies } from 'react-cookie';

function CookieBanner(props) {
const classes = styles();
const dispatch = useDispatch();
// eslint-disable-next-line
const [cookieRight, setCookieRight] = useCookies(["Cookies"]);
const [showCookieDetails, setShowCookieDetails] = React.useState(false);
const [cookieChoice, setCookieChoice] = React.useState({
    cookie1: true, //authCookie
    cookie2: true, //idpassreminder memoriser mot de passe
    //cookie3: true,
});

  //console.log("PROPS : ", props);

const handleChange = (event) => {
    setCookieChoice({ ...cookieChoice, [event.target.name]: event.target.checked });
};

const actionCookieDetails = () => {
    if (showCookieDetails === true) {
      setShowCookieDetails(false);
    }
    else {
      setShowCookieDetails(true);
    }
}

const refuseAllCookies = () => {
    // Créé un cookie qui mémorise cookieChoice = false
    //console.log("Cookie refusal process")
    const values = {cookieSetup: true, authCookie: false}
    setCookieRight("AuthCookie", false, { path: "/", maxAge: 60*60*24*365 });
    AcceptCookies(dispatch, values);
}

function AcceptCook() {
    //Créé un cookie qui mémorise cookieChoice = true
    //Et mémorise les informations de cookies -> true or false selon le nom du cookie
    //console.log("Cookie setup process")
    const values = {cookieSetup: true, authCookie: cookieChoice.cookie1}
    //Sets the needed cookies
    setCookieRight("AuthCookie", values.authCookie, { path: "/", maxAge: 60*60*24*365 });
    AcceptCookies(dispatch, values);
    //AcceptCookies(dispatch, {cookieSetup: true, authCookie: cookieChoice.cookie1, idpassreminder: cookieChoice.cookie})
    //
    //console.log("Cookie setup process end")
    //LoginCustom(dispatch, currentEmail, currentPassword, "sotasty web");
}
/* ADDING A NEW COOKIE -> value will be cookieChoice.cookie2
    <FormControlLabel style={{marginBottom: '10px'}}
        control={<Switch checked={cookieChoice.cookie2} onChange={handleChange} name="cookie2" color="primary"/>}
        label="Mémoriser mon identifiant et mon mot de passe: Ce cookie permet de stocker de manière cryptée vos informations de connection, ce qui vous évitera de les écrire à nouveau dans le formulaire de connection."
    />
*/


    return (
   
  <Container component='main' maxWidth="xl" className={classes.cookieContainer} >
  <Box boxShadow={4} style={{background:'white'}}>
      <Container maxWidth="lg" component="main" style={{paddingBottom:"10px"}}>
          <Typography variant="h3">Et si on se préparait des Cookies ?</Typography>

          { showCookieDetails ? <>
              <FormControl component="fieldset" style={{marginBottom: '10px'}}>
                  <FormLabel component="legend"><Typography variant="h5">Voici les cookies que nous pouvons utiliser</Typography></FormLabel>
                  <FormGroup>
                      <FormControlLabel style={{ marginBottom: '10px' }}
                          id="toggleCookie1"
                          control={<Switch checked={cookieChoice.cookie1} onChange={handleChange} name="cookie1" color="primary"/>}
                          label="Connection automatique (token): Ce cookie permet d'enregistrer vos autorisations d'accès basées sur votre dernière connection. Cela permet, lorsque vous reviendrez manger des gâteaux en notre compagnie, de vous reconnecter sans même vous en rendre compte"
                      />


                  </FormGroup>
              </FormControl >
          </>: <>
          <Grid container spacing={10} alignItems="stretch" style={{marginBottom:"10px"}}>
              <Grid item component={Box} xs={12} sm={3} md={2} lg={2} xl={2}>
                  <Box justifyContent="center" display="flex">
                       <img src={cookiemonamour} style={{ align: 'center', maxWidth: '130px'}} alt="Des cookies toujours des cookies"/>
                  </Box>
              </Grid>
              <Grid item component={Box} xs={12} sm={9} md={10} lg={10} xl={10}>
                  <Box>
                      <Typography variant="body2" align="justify">
                                            Ce n'est pas notre dessert préféré, mais les cookies sont un bon moyen de fournir une expérience utilisateur agréable et enrichie.        
                      </Typography>
                      <Typography variant="body2" align="justify">
                          Comme nous aimons nos utilisateurs plus que les cookies, nous ne les utilisons pas pour vous vendre de la publicité
                          ou pour vous suivre sur le web. Plus de détails dans notre <Link variant="h6" color="primary" href="/legal/privacy" >politique de confidentialité</Link>
                      </Typography>
                  </Box>
              </Grid>
          </Grid>
          </>
          }
          <Grid container direction="row-reverse" justify="flex-start" alignItems="center" style={{ marginBottom: "10px" }}>
              <Grid item  xs={12} sm={12} md={4} lg={4} xl={4}>       
                   <Button style={{ fontFamily: "Lobster", fontSize: "18px", color: "#FFFFFF", textTransform: 'none', maxHeight: "32px"}} onClick={() => { AcceptCook() }} color="primary" fullWidth variant="contained" className={classes.pushToAction}>{showCookieDetails ? <>Enregistrer mes choix</> : <>Tout accepter</>}</Button>      
              </Grid>
              <Grid item  xs={12} sm={12} md={4} lg={4} xl={4}>         
                   <Button onClick={() => { refuseAllCookies() }} color="secondary" variant="outlined" fullWidth  className={classes.link} style={{ maxHeight: "32px" }}>Tout refuser</Button>           
              </Grid>
              <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>        
                   <Button onClick={() => { actionCookieDetails() }} color="primary" fullWidth  variant="outlined" className={classes.link} style={{ maxHeight: "32px" }}>Je suis curieux, je veux choisir</Button>          
              </Grid>
          </Grid>
      </Container>
  </Box>
  </Container>
            
            
);
}

//export default CookieBanner;
function mapStateToProps({ legal }) {
  return { legal };

}

export default connect(mapStateToProps) (CookieBanner);
