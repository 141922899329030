/*######################################################
Creation Date: 14/07/2021
Author: Jean-Baptiste ROUGET
Updates:
# 29/09/2021: Creation in order to rewrite code a better way
#
#
######################################################*/
import React from 'react';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Link from '@material-ui/core/Link';
import HomeIcon from '@material-ui/icons/Home';
import LibraryAddIcon from '@material-ui/icons/LibraryAdd';
import Drawer from '@material-ui/core/Drawer';
import AssignmentTurnedInOutlinedIcon from '@material-ui/icons/AssignmentTurnedInOutlined';
import BallotOutlinedIcon from '@material-ui/icons/BallotOutlined';
import DialogCreateReceipe from './dialog/CreateReceipe';
import styles from "../theme/style";



const GeniusDrawer = (props) => {
    let connected = props.connected;
    let open = props.open; // Actif quand connecté ou quand écran petit non connecté
    //let size = props.size;
    //let onclose = props.onClose;
    let customstyle = "";
    const classes = styles();
    const [openDialogCreateReceipe, setOpenDialogCreateReceipe] = React.useState(false);

    const handleClickOpenDialogCreateReceipe = () => {
        setOpenDialogCreateReceipe(true);
        //console.log("CLICK open !");
    };
    const handleClickCloseDialogCreateReceipe = () => {
        setOpenDialogCreateReceipe(false);
        //console.log("CLICK close !");
    };

    if (open) {
        customstyle = classes.drawerOpen;
    }
    else {
        customstyle = classes.drawerClose;
    }


  // Si inactif, ne renvoie rien

    if (!connected) {
        return(
            <Drawer anchor="left" open={open} onClose={()=>{props.onClose()}}>
                <List>
                    <ListItem button key="values">
                        <ListItemIcon> <AssignmentTurnedInOutlinedIcon color="primary" /></ListItemIcon>
                        <ListItemText primary={<Link variant="button h6" color="textPrimary" href="#receipes" className={classes.toolbarTitle}>Recettes</Link>} />
                    </ListItem>
                    <ListItem button key="Functionalities">
                        <ListItemIcon> <BallotOutlinedIcon color="primary" /></ListItemIcon>
                        <ListItemText primary={<Link variant="button h6" color="textPrimary" href="#menus" className={classes.toolbarTitle}>Menus</Link>} />
                    </ListItem>
                </List>
            </Drawer>
        );
    }
    else {
        return(
            <Drawer anchor="left" variant='permanent' className={customstyle}>
                <List style={{marginTop: '50px'}}>
                    <ListItem button key="home">
                        <ListItemIcon> <HomeIcon color="primary" /></ListItemIcon>
                        <ListItemText primary={<Link variant="button h6" color="textPrimary" href="/" className={classes.toolbarTitle}>Accueil</Link>} />
                    </ListItem>
                    <ListItem button key="add_receipe">
                        <ListItemIcon> <LibraryAddIcon color="primary" /></ListItemIcon>
                        <ListItemText className={classes.toolbarTitle} onClick={handleClickOpenDialogCreateReceipe} primary={<Link variant="button h6" color="textPrimary" className={classes.toolbarTitle}>Créer recette</Link>} />
                    </ListItem>


                </List>
                <DialogCreateReceipe open={openDialogCreateReceipe} onClose={handleClickCloseDialogCreateReceipe}></DialogCreateReceipe>
            </Drawer>
        );
    }
}

export default GeniusDrawer;
