/*######################################################
Creation Date: 01/01/2021
Author: Jean-Baptiste ROUGET
Definition: Page to view a receipe
Updates:
# 11/01/2021: Creation
# 26/08/2021: Refining code, adding comprehensive comments, decreasing file size
#
######################################################*/
import React from 'react';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';
import styles from '../../theme/style';
import { useSelector } from 'react-redux';
//import {  useHistory } from 'react-router-dom';
//import { selectIsLoggedIn, } from '../../store/reducers/authentication';
import { selectMyDraftReceipes } from '../../store/reducers/receipe';
import CardReceipe from '../../components/receipe/card';
import AppStructure from '../../components/AppStructure';

const ViewReceipe = (props) => {
    const classes = styles();
    //let historynavigator = useHistory();
    const myDraftReceipes = useSelector(selectMyDraftReceipes);
    //const loggedin =  useSelector(selectIsLoggedIn);




   
      return (
          <AppStructure>
        <Container component='main' maxWidth="lg" className={classes.brandValues}>
            <Container maxWidth="xl" component="main">
                <Typography>{props.match.params.receipe_slug}</Typography>
                <Grid id="values" container spacing={2} alignItems="stretch" style={{marginBottom:"60px"}}>
                    {myDraftReceipes.map((myDraft) => ( // A FAIRE LE SYSTEME DE AFFICHAGE DES RECETTES ! ET SI RIEN AUSSI
                        <Grid item xs={12} sm={12} md={6} lg={4} xl={3}>
                            <Typography>{myDraft.name}</Typography>
                            <CardReceipe draft={myDraft}>

                            </CardReceipe>
                        </Grid>
                    ))}
                </Grid>



            </Container>
        </Container>
        <Container maxWidth="lg" component="main">



        </Container>
              </AppStructure>
      );


   





    }




/*
function mapStateToProps({ authentication, currentUser, pointofsale }) {
  return { authentication, currentUser, pointofsale };

}

export default connect(mapStateToProps) (Header);

*/
export default ViewReceipe;
