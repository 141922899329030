/*######################################################
Creation Date: 14/07/2021
Author: Jean-Baptiste ROUGET
Updates:
# 30/12/2021: Creation of the show Text or Edit for Draft Receipes
#
#
######################################################*/
import React from 'react';
import Container from '@material-ui/core/Container';
import { useDispatch, useSelector } from 'react-redux';
import { selectMyReceipeLoading, UpdateDraftReceipeInformation, DeleteIngredientFromReceipe, DeleteActionFromReceipe } from '../../store/reducers/receipe';
//import { selectActionTypes, selectActions, CreateAction, selectTempIngredientMap, UpdateTempIngredientMap } from '../../store/reducers/action';
import { selectToken } from '../../store/reducers/authentication';
import DeleteIcon from '@mui/icons-material/Delete';
import Typography from '@material-ui/core/Typography';
import TextField from '@mui/material/TextField';
import IconButton from '@mui/material/IconButton';
import ModeEditOutlineOutlinedIcon from '@mui/icons-material/ModeEditOutlineOutlined';
import SaveOutlinedIcon from '@mui/icons-material/SaveOutlined';
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';
import CircularProgress from '@material-ui/core/CircularProgress';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import AddBoxOutlinedIcon from '@mui/icons-material/AddBoxOutlined';
import DialogCreateReceipeIngredient from '../dialog/CreateReceipeIngredient';
//import DialogCreateReceipeAction from '../dialog/CreateReceipeAction';
import { useHistory } from 'react-router-dom';
//import Button from '@material-ui/core/Button';
//import Box from '@material-ui/core/Box';
//import Grid from '@material-ui/core/Grid';
//import Link from '@material-ui/core/Link';
//import StarOutlined from '@material-ui/icons/StarOutlined';
//import StarHalfOutlined from '@material-ui/icons/StarHalfOutlined';
//import StarBorderOutlined from '@material-ui/icons/StarBorderOutlined';
//import useMediaQuery from '@material-ui/core/useMediaQuery';
//import theme from '../../theme/lightTheme';
//import styles from "../../theme/style";


function DraftTextEdit(props) {
    //const classes = styles();
    let historynavigator = useHistory();
    const isLoading = useSelector(selectMyReceipeLoading);
    const token = useSelector(selectToken);
    const dispatch = useDispatch();
    const [openDialogCreateReceipeIngredient, setOpenDialogCreateReceipeIngredient] = React.useState(false);
    //const [openDialogCreateReceipeAction, setOpenDialogCreateReceipeAction] = React.useState(false);
    let receipe = props.receipe_draft;
    let myreceipe = props.receipe_draft;
    let index = props.index;
    let field = props.field;
    console.log("Receipe : ", receipe, " et id : ", receipe._id);
    //console.log("Index : ", index);
    //console.log("Field : ", field);

    const [item, setItem] = React.useState(receipe[field]);


    const [editMode, setEditMode] = React.useState(false);


    var dict = {};
    dict["unit"] = "unité";
    dict["g"] = "g";
    dict["kg"] = "kg";
    dict["cL"] = "cL";
    dict["dL"] = "dL";
    dict["L"] = "L";
    dict["pinch"] = "pincée";
    dict["table_spoon"] = "cuillère à soupe";
    dict["tea_spoon"] = "cuillère à café";
    dict["slice"] = "tranche";
    

    







    const onClickEdit = () => {
        if (editMode) {
            setEditMode(false);
        }
        else {
            setEditMode(true);
        }
    }
    const onSave = () => {
        //Update DB and store
        UpdateDraftReceipeInformation(dispatch, token, receipe._id, index, field, item)
            .then(() => {
                if (editMode) {
                    setEditMode(false);
                }
                else {
                    setEditMode(true);
                }

            })
        //Go back to uneditable mode
        
    }
    const onCancel = () => {
        //Go back to initial state
        setItem(receipe[field]);
        //Go back to uneditable mode
        if (editMode) {
            setEditMode(false);
        }
        else {
            setEditMode(true);
        }
    }
    const handleChanged = async (newValue) => {
        setItem(newValue);
    }
    const handleClickOpenDialogCreateReceipeIngredient = () => {
        setOpenDialogCreateReceipeIngredient(true);
        //console.log("CLICK open !");
    };
    const handleClickCloseDialogCreateReceipeIngredient = () => {
        setOpenDialogCreateReceipeIngredient(false);
        //console.log("CLICK close !");
    };
    /*
    const handleClickOpenDialogCreateReceipeAction = () => {
        setOpenDialogCreateReceipeAction(true);
        //console.log("CLICK open !");
    };
    */
    /*
    const handleClickCloseDialogCreateReceipeAction = () => {
        setOpenDialogCreateReceipeAction(false);
        //console.log("CLICK close !");
    };
    */

    const clickToCreateAction = () => {
        let url = "/receipe/draft/" + receipe.slug + "/action";
        historynavigator.push(url);
        //setOpenDialogCreateReceipeAction(false);
        //console.log("CLICK close !");
    };

    const deleteIngredient = (ingredientId, receipeId) => {

        console.log("CLICK", ingredientId, receipeId, index);
        DeleteIngredientFromReceipe(dispatch, token, ingredientId, receipeId, index);
    };

    const deleteAction = (actionId, receipeId, index) => {

        console.log("CLICK", actionId, receipeId, index);
        DeleteActionFromReceipe(dispatch, token, actionId, receipeId, index);
    };
    

    
    //FIELD OF TITLE TYPE
    if (props.type === "title") {

        return (

            <Container maxWidth='lg' style={{ marginBottom: "60px" }}>
                {editMode ?
                    <div style={{ display: "flex" }}><TextField fullWidth size="small" value={item} onChange={e => { handleChanged(e.target.value) }}>{isLoading ? <CircularProgress/> : <></> }</TextField><IconButton style={{ color: "#2BB413" }} aria-label="edit" component="span" onClick={() => { onSave() }}><SaveOutlinedIcon /></IconButton><IconButton color="error" aria-label="edit" component="span" onClick={() => { onCancel() }}><CancelOutlinedIcon /></IconButton></div> :
                    <div style={{ display: "flex" }}><Typography variant="h4" align="left">{item}</Typography><IconButton style={{ color: "#2BB413" }} aria-label="edit" component="span" onClick={() => { onClickEdit() }}><ModeEditOutlineOutlinedIcon /></IconButton></div>
                }
            </Container>

        );
    }
    if (props.type === "text") {

        return (

            <Container maxWidth='lg' style={{ marginBottom: "60px" }}>
                <div style={{ display: "flex" }}>
                    <Typography variant="h4" align="left">{props.title}</Typography>
                    {editMode ? <></> : <IconButton style={{ color: "#2BB413" }} aria-label="edit" component="span" onClick={() => { onClickEdit() }}><ModeEditOutlineOutlinedIcon /></IconButton>}
                </div>
                {editMode ?
                    <div style={{ display: "flex" }}><TextField fullWidth multiline id="outlined-multiline-static" rows={10} value={item} onChange={e => { handleChanged(e.target.value) }}>{isLoading ? <CircularProgress /> : <></>}</TextField><IconButton style={{ color: "#2BB413" }} aria-label="edit" component="span" onClick={() => { onSave() }}><SaveOutlinedIcon /></IconButton><IconButton color="error" aria-label="edit" component="span" onClick={() => { onCancel() }}><CancelOutlinedIcon /></IconButton></div> :
                    <div style={{ display: "flex" }}><Typography variant="body2" align="justify">{item}</Typography></div>
                }
            </Container>

        );
    }
    if (props.type === "list") {

        return (

            <Container maxWidth='lg' style={{ marginBottom: "60px" }}>
                <div style={{ display: "flex" }}>
                    <Typography variant="h4" align="left">{props.title}</Typography>
                    
                </div>
                <List>

                    {receipe["ingredients"].filter(({ temporary }) => temporary === false ).map((ingredients, index) => (

                        <ListItem alignItems="flex-start" key={index}
                            secondaryAction={
                                <IconButton edge="end" aria-label="delete">
                                    <DeleteIcon onClick={() => { deleteIngredient(ingredients.ingredient._id, receipe._id
                                        )}} />
                                </IconButton>
                        }>
                            <ListItemText primary={ingredients.ingredient.name} secondary={
                                <React.Fragment>
                                    <Typography
                                        sx={{ display: 'inline' }}
                                        component="span"
                                        variant="body2"
                                        color="textPrimary"
                                    >
                                        {ingredients.unitPerMetric} {dict[ingredients.metric]}

                                    </Typography>
                                    
                                </React.Fragment>
                                }
                            />
                            
                            
                        </ListItem>
                    ))}

                    <ListItemButton onClick={() => { handleClickOpenDialogCreateReceipeIngredient() }}>
                        <ListItemIcon>
                            <AddBoxOutlinedIcon />
                        </ListItemIcon>
                        <ListItemText primary="Ajouter un ingrédient" />
                        
                    </ListItemButton>
                    <DialogCreateReceipeIngredient receipeId={receipe._id} open={openDialogCreateReceipeIngredient} onClose={handleClickCloseDialogCreateReceipeIngredient}></DialogCreateReceipeIngredient> 

                </List>
            </Container>

        );
    }
    if (props.type === "actionlist") {

        return (

            <Container maxWidth='lg' style={{ marginBottom: "60px" }}>
                <div style={{ display: "flex" }}>
                    <Typography variant="h4" align="left">{props.title}</Typography>

                </div>
                <List>
                    

                        {myreceipe["process"].map((actions, index) => (
                            <ListItem alignItems="flex-start" key={index} secondaryAction={
                                <IconButton edge="end" aria-label="delete">
                                    <DeleteIcon onClick={() => {
                                        deleteAction(actions._id, receipe._id, index)
                                    }} />
                                </IconButton>
                            }>
                                <ListItemText primary={actions.name} secondary={
                                    <React.Fragment>
                                        <Typography
                                            sx={{ display: 'inline' }}
                                            component="span"
                                            variant="body2"
                                            color="textPrimary"
                                        >
                                            {actions.unitPerMetric} {actions.metric}
                                        </Typography>

                                    </React.Fragment>
                                }
                                />


                            </ListItem>
                        ))}



                    
                   

                    <ListItemButton onClick={() => { clickToCreateAction() }}>
                        <ListItemIcon>
                            <AddBoxOutlinedIcon />
                        </ListItemIcon>
                        <ListItemText primary="Ajouter une action" />

                    </ListItemButton>
                    

                </List>
            </Container>

        );
    }

    

}

export default DraftTextEdit;
