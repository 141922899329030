/*######################################################
Creation Date: 11/07/2021
Author: Jean-Baptiste ROUGET
Updates:
# 11/07/2021: Creation
#
#
######################################################*/
import { combineReducers } from 'redux'
import authentication from './authentication';
import legal from './legal';
import receipe from './receipe';
import ingredient from './ingredient';
import action from './action';
import currentuser from './currentuser';

export default combineReducers({
  authentication: authentication,
  legal: legal,
  receipe: receipe,
  ingredient: ingredient,
  action: action,
  currentuser: currentuser,
});
