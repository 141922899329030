/*######################################################
Creation Date: 11/07/2021
Author: Jean-Baptiste ROUGET
Updates:
# 13/07/2021: Creation
# 01/10/2021: Amélioration de la logique pour ouvrir la page sur la recette active après enregistrement
#
######################################################*/
import React from 'react';
import Button from '@material-ui/core/Button';
import { selectToken } from '../../store/reducers/authentication';
import { useDispatch, useSelector } from 'react-redux';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { CreateReceipeDraft, selectMyActiveDraftReceipe} from '../../store/reducers/receipe';
import {  useHistory } from 'react-router-dom';

function DialogCreateReceipe(props) {
    //VARIABLES
    //const classes = styles();
    const dispatch = useDispatch();
    const { open, onClose } = props;//Define open and clase, we can send function from parent here
    const [currentName, setCurrentName] = React.useState(''); //state for name field
    const token = useSelector(selectToken);
    const myActiveDraftReceipe = useSelector(selectMyActiveDraftReceipe);
    let historynavigator = useHistory();
    //FONCTIONS
    const cancel = () => {
        onClose();
    }

    

React.useEffect(() => {
    //console.log("States changed: ",isEmailValid, isPasswordValid ,isPasswordValid2 );
    //console.log("TOKEN STATE HAS CHANGED: ", token)
    if (myActiveDraftReceipe !== undefined ) {
        console.log("Changing page to ", myActiveDraftReceipe.slug);
        let url = "/receipe/draft/" + myActiveDraftReceipe.slug
        historynavigator.push(url);
        //history.push("/dashboard");
        
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
}, [myActiveDraftReceipe]);

    const saveReceipe = () => {
        //console.log("Saving Receipe");
        //CREER STATE POUR NAME !!!
        console.log("Name: ", currentName);
        CreateReceipeDraft(dispatch, token, currentName);
        onClose();
        //recordingandchangepage();
        //CreateReceipeDraft(dispatch, currentName);

        //onClose();
        //historynavigator.push("/");
        //console.log("Going to Receipe Creation Page");




    }

    const handleNameChanged = (name) => {
        setCurrentName(name);
    }



    return (
        <Dialog open={open} onClose={onClose} aria-labelledby="form-dialog-title">
            <DialogTitle id="form-dialog-title">Créer une nouvelle recette</DialogTitle>
            <DialogContent>
                <DialogContentText>
                    Veuillez entrer ci-dessous un nom de recette explicite, afin de vous permettre facilement de la retrouver. Ce nom doit être simple et clair pour n'importe quel visiteur.
                </DialogContentText>
                <TextField
                    autoFocus
                    margin="dense"
                    id="name"
                    label="Nom de la recette"
                    type="text"
                    fullWidth
                    onChange={e => {handleNameChanged(e.target.value)}}
                    value={currentName}
                />
            </DialogContent>
            <DialogActions>
                <Button onClick={cancel} color="primary">
                    Annuler
                </Button>
                <Button onClick={saveReceipe} color="primary">
                    Continuer
                </Button>
            </DialogActions>
        </Dialog>
);
}


export default DialogCreateReceipe;
