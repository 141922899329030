/*######################################################
Creation Date: 01/01/2021
Author: Jean-Baptiste ROUGET
Definition: This page aims at loging in
Updates:
# 11/01/2021: Creation
# 28/09/2021: Refining code, adding comprehensive comments, decreasing file size
######################################################*/
import React from 'react';
import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';
import styles from '../../theme/style';


const Terms = (props) => {
    const classes = styles();

    return (
        <div className={classes.main}>
            <Container maxWidth="lg" component="main">
                <Typography variant="h3">Termes et conditions</Typography>
                <Typography variant="body2" align="justify">
                    Les présentes conditions générales d'utilisation (dites « CGU ») ont pour objet l'encadrement juridique des modalités de mise à disposition du site et des services
                    par Les Toqués et de définir les conditions d’accès et d’utilisation des services par « l'Utilisateur ».
                </Typography>
                <Typography variant="body2" align="justify" >
                    Les présentes CGU sont accessibles sur le site à la rubrique «Termes et conditions».
                </Typography>
                <Typography variant="body2" align="justify" >
                    Toute inscription ou utilisation du site implique l'acceptation sans aucune réserve ni restriction des présentes CGU par l’utilisateur.
                    Lors de l'inscription sur le site via le Formulaire d’inscription, chaque utilisateur accepte expressément les présentes CGU en cochant la case précédant le texte suivant :
                    « Je reconnais avoir lu, compris et accepté les conditions d'utilisation ».
                </Typography>
                <Typography variant="body2" align="justify" style={{marginBottom:"30px"}}>
                    En cas de non-acceptation des CGU stipulées dans le présent contrat, l'Utilisateur se doit de renoncer à l'accès des services proposés par le site.
                    Les Toqués se réserve le droit de modifier unilatéralement et à tout moment le contenu des présentes CGU.
                </Typography>


                <Typography variant="h5">Article 1 : Les mentions légales</Typography>
                <Typography variant="body2" align="justify">
                    L'édition du site Les Toqués est assurée par la Société SAS Les Toqués en cours de création au capital de 1000 euros, dont le siège social est situé au
                    14 Allée Léopold Sedar Senghor - 69007 Lyon.
                </Typography>
                <Typography variant="body2" align="justify">
                    Numéro de téléphone: Non communiqué<br/>
                </Typography>
                <Typography variant="body2" align="justify">
                    Adresse e-mail : Non communiqué
                </Typography>
                <Typography variant="body2" align="justify" style={{marginBottom:"30px"}}>
                    Le Directeur de la publication est : Jean-Baptiste ROUGET
                </Typography>


                <Typography variant="h5">Article 2 : Accès au site</Typography>
                <Typography variant="body2" align="justify">
                    Le site www.lestoques.com permet à l'Utilisateur un accès aux services suivants :
                </Typography>
                <Typography variant="body2" align="justify">
                    - application communautaire permettant de trouver des informations sur des aliments et recettes, sans garantie de temps et de contenu pour les utilisateurs non payants
                    - accès payant à des services personalisés
                </Typography>
                <Typography variant="body2" align="justify">
                    L’Utilisateur non membre n'a pas accès aux services réservés. Pour cela, il doit s’inscrire en remplissant le formulaire d'inscription.
                </Typography>
                <Typography variant="body2" align="justify">
                    En acceptant de s’inscrire aux services réservés, l’Utilisateur membre s’engage à fournir des informations sincères et exactes concernant ses coordonnées, notamment son adresse email.
                </Typography>
                <Typography variant="body2" align="justify">
                    Pour accéder aux services, l’Utilisateur doit ensuite s'identifier à l'aide de son identifiant et de son mot de passe qui lui seront communiqués après son inscription.
                </Typography>
                <Typography variant="body2" align="justify">
                    Tout Utilisateur membre régulièrement inscrit pourra également solliciter sa désinscription en se rendant à la page dédiée sur son espace personnel.
                    Celle-ci sera effective dans un délai raisonnable.
                </Typography>
                <Typography variant="body2" align="justify" style={{marginBottom:"30px"}}>
                    Tout événement dû à un cas de force majeure ayant pour conséquence un dysfonctionnement du site ou serveur et sous réserve de toute interruption ou modification en cas de maintenance,
                    n'engage pas la responsabilité de Les Toqués. Dans ces cas, l’Utilisateur accepte ainsi ne pas tenir rigueur à l’éditeur de toute interruption ou suspension de service,
                    même sans préavis.
                    L'Utilisateur a la possibilité de contacter le site par messagerie électronique à l’adresse email de l’éditeur communiqué à l’ARTICLE 1.
                </Typography>


                <Typography variant="h5">Article 3 : Collecte des données</Typography>
                <Typography variant="body2" align="justify" style={{marginBottom:"30px"}}>
                    Le site assure à l'Utilisateur une collecte et un traitement d'informations personnelles dans le respect de la vie privée conformément à la loi n°78-17 du 6 janvier 1978 relative à l'informatique, aux fichiers et aux libertés.
                    En vertu de la loi Informatique et Libertés, en date du 6 janvier 1978, l'Utilisateur dispose d'un droit d'accès, de rectification, de suppression et d'opposition de ses données personnelles.
                    L'Utilisateur exerce ce droit :	par mail à l'adresse mail jean-baptiste.rouget@anoev.com
                </Typography>



                <Typography variant="h5">Article 4 : Propriété intellectuelle</Typography>
                <Typography variant="body2" align="justify">
                    Les marques, logos, signes ainsi que tous les contenus du site (textes, images, son…) font l'objet d'une protection par le Code de la propriété intellectuelle
                    et plus particulièrement par le droit d'auteur.
                </Typography>
                <Typography variant="body2" align="justify">
                    L'Utilisateur doit solliciter l'autorisation préalable du site pour toute reproduction, publication, copie des différents contenus.
                    Il s'engage à une utilisation des contenus du site dans un cadre strictement privé, toute utilisation à des fins commerciales et publicitaires est strictement interdite.
                </Typography>
                <Typography variant="body2" align="justify">
                    Toute représentation totale ou partielle de ce site par quelque procédé que ce soit, sans l’autorisation expresse de l’exploitant du site Internet constituerait une contrefaçon sanctionnée par l’article L 335-2 et suivants du Code de la propriété intellectuelle.
                </Typography>
                <Typography variant="body2" align="justify" style={{marginBottom:"30px"}}>
                    Il est rappelé conformément à l’article L122-5 du Code de propriété intellectuelle que l’Utilisateur qui reproduit, copie ou publie le contenu protégé doit citer l’auteur et sa source.
                </Typography>



                <Typography variant="h5">Article 5 : Responsabilité</Typography>
                <Typography variant="body2" align="justify" style={{marginBottom:"30px"}}>
                    Les sources des informations diffusées sur le site www.Sotasty.io sont réputées fiables mais le site ne garantit pas qu’il soit exempt de défauts, d’erreurs ou d’omissions.<br/>
                    Les informations communiquées sont présentées à titre indicatif et général sans valeur contractuelle. Malgré des mises à jour régulières, le site Les Toqués ne peut être tenu responsable
                    de la modification des dispositions administratives et juridiques survenant après la publication. De même, le site ne peut être tenue responsable de l’utilisation et de l’interprétation
                    de l’information contenue dans ce site.<br/>
                    L'Utilisateur s'assure de garder son mot de passe secret. Toute divulgation du mot de passe, quelle que soit sa forme, est interdite. Il assume les risques liés à l'utilisation
                    de son identifiant et mot de passe. Le site décline toute responsabilité.
                    Le site Les Toqués ne peut être tenu pour responsable d’éventuels virus qui pourraient infecter l’ordinateur ou tout matériel informatique de l’Internaute, suite à une utilisation,
                    à l’accès, ou au téléchargement provenant de ce site.<br/>
                    La responsabilité du site ne peut être engagée en cas de force majeure ou du fait imprévisible et insurmontable d'un tiers.
                </Typography>




                <Typography variant="h5">Article 6 : Publication par l’Utilisateur</Typography>
                <Typography variant="body2" align="justify">
                    Le site permet aux membres de publier les contenus suivants :
                    - contenu personnel (avis, notes)<br/>
                    - contenu communautaire (menus, recettes, photos).<br/>
                    Dans ses publications, le membre s’engage à respecter les règles de la Netiquette (règles de bonne conduite de l’internet) et les règles de droit en vigueur.<br/>
                    Le site peut exercer une modération sur les publications et se réserve le droit de refuser leur mise en ligne, sans avoir à s’en justifier auprès du membre.<br/>
                    Le membre reste titulaire de l’intégralité de ses droits de propriété intellectuelle. Mais en publiant une publication sur le site, il cède à la société éditrice le droit non exclusif
                    et gratuit de représenter, reproduire, adapter, modifier, diffuser et distribuer sa publication, directement ou par un tiers autorisé, dans le monde entier, sur tout support
                    (numérique ou physique), pour la durée de la propriété intellectuelle. Le Membre cède notamment le droit d'utiliser sa publication sur internet et sur les réseaux de téléphonie mobile.<br/>
                    La société éditrice s'engage à faire figurer le nom du membre à proximité de chaque utilisation de sa publication.<br/>
                </Typography>

                <Typography variant="body2" align="justify" style={{marginBottom:"30px"}}>
                    Tout contenu mis en ligne par l'Utilisateur est de sa seule responsabilité. L'Utilisateur s'engage à ne pas mettre en ligne de contenus pouvant porter atteinte aux intérêts
                    de tierces personnes. Tout recours en justice engagé par un tiers lésé contre le site sera pris en charge par l'Utilisateur.<br/>
                    Le contenu de l'Utilisateur peut être à tout moment et pour n'importe quelle raison supprimé ou modifié par le site, sans préavis.<br/>
                </Typography>



                <Typography variant="h5">Article 7 : Droit applicable et juridiction compétente</Typography>
                <Typography variant="body2" align="justify">
                    La législation française s'applique au présent contrat. En cas d'absence de résolution amiable d'un litige né entre les parties, les tribunaux français seront seuls
                    compétents pour en connaître. Pour toute question relative à l’application des présentes CGU, vous pouvez joindre l’éditeur aux coordonnées inscrites à l’ARTICLE 1.
                </Typography>
            </Container>
        </div>
    );


}




/*
function mapStateToProps({ authentication, currentUser, pointofsale }) {
  return { authentication, currentUser, pointofsale };

}

export default connect(mapStateToProps) (Header);

*/
export default Terms;
