/*######################################################
Creation Date: 01/02/2021
Author: Jean-Baptiste ROUGET
Updates:
# 01/02/2021: Creation
# 26/09/2021: Document the code
######################################################*/
import React from 'react';
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import Link from '@material-ui/core/Link';
import styles from "../theme/style";
import keys from '../config/keys';

//Copyright information always up to date
function Copyright() {
    return (
        <Typography variant="body2" style={{fontFamily: "Lobster", fontSize:"18px", color: "#2BB413"}} color="textSecondary">
            {'Copyright © '}
            <Link color="inherit" href={ keys.localUrl }>lestoques</Link>{' '}
            {new Date().getFullYear()}
            {'.'}
            {' Tous droits réservés.'}
        </Typography>
    );
}

const Footer = (props) => {
    const classes = styles();
    //const isloggedin = useSelector(selectIsLoggedIn);
    //SI COOKIE EXISTE, ON SE LOG  -> IS LOGGEDIN


    return (
         <Container maxWidth='xl' className={classes.footer} style={{paddingTop:'100px'}}>
              <Container maxWidth='lg' component='main'>
                   <Grid container   spacing={2} alignItems="stretch">
                        <Grid item component={Box} xs={6} sm={6} md={3} lg={3} xl={3} style={{ marginTop: '20px' }}>
                             <Box>
                                   <Typography variant="h5" align="center" style={{textDecoration:'underline'}}>Les Toqués</Typography>
                                   <Typography variant="h6" align="center">Nos valeurs</Typography>
                                   <Typography variant="h6" align="center">Fonctionalités</Typography>
                                   <Typography variant="h6" align="center">Tarifs</Typography>
                                   <Typography variant="h6" align="center">Plan du site</Typography>
                            </Box>
                        </Grid>
                        <Grid item component={Box} xs={6} sm={6} md={3} lg={3} xl={3} style={{ marginTop: '20px' }}>
                            <Box>
                                <Typography variant="h5" align="center" style={{textDecoration:'underline'}}>A propos</Typography>
                                <Typography variant="h6" align="center">Equipe</Typography>
                            </Box>
                        </Grid>
                        <Grid item component={Box} xs={6} sm={6} md={3} lg={3} xl={3} style={{ marginTop: '20px' }}>
                            <Box>
                                <Typography variant="h5" align="center" style={{textDecoration:'underline'}}>Legal</Typography>
                            <Typography variant="h6" align="center"><Link variant="h6"  href="/legal/privacy" >Politique de confidentialité</Link></Typography>
                                <Typography variant="h6" align="center"><Link variant="h6"  href="/legal/terms" >Termes et Conditions</Link></Typography>
                            </Box>
                        </Grid>
                        <Grid item component={Box} xs={6} sm={6} md={3} lg={3} xl={3} style={{ marginTop: '20px'}}>
                            <Box>
                                <Typography variant="h5" align="center" style={{textDecoration:'underline'}}>Contacts</Typography>
                                <Typography variant="h6" align="center">Nous contacter</Typography>
                            </Box>
                        </Grid>
                   </Grid>
              </Container>
              <Container maxWidth="lg" style={{marginTop:'40px', align:'right'}}>
                   <Box align='right' >
                        <Copyright  />
                   </Box>
              </Container>
         </Container>

    );
    
}


export default Footer;
