/*######################################################
Creation Date: 14/07/2021
Author: Jean-Baptiste ROUGET
Updates:
# 29/09/2021: Creation in order to rewrite code a better way
#
#
######################################################*/
import * as React from 'react';
import Typography from '@material-ui/core/Typography';
//import AppStructure from '../../components/AppStructure';
//import styles from '../../theme/style';
import { useSelector } from 'react-redux';
//import { useHistory } from 'react-router-dom';
//import { selectIsLoggedIn } from '../../store/reducers/authentication';
import { selectMyDraftReceipes, selectMyReceipes } from '../../store/reducers/receipe';
import CardReceipe from '../../components/receipe/card';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import receipe_placeholder from '../../assets/images/placeholder-recette.jpg';


const Dashboard = (props) => {
    //const dispatch = useDispatch();
    //const classes = styles();
    //let historynavigator = useHistory();
    const myDraftReceipes = useSelector(selectMyDraftReceipes);
    const myReceipes = useSelector(selectMyReceipes);
    //const loggedin = useSelector(selectIsLoggedIn);
    let draftreceipesexist = false;
    if (myDraftReceipes.length > 0) { draftreceipesexist = true };
    let myreceipesexist = false;
    if (myReceipes.length > 0) { myreceipesexist = true };
    //console.log("Count: ", draftreceipesCount);
    return (
        <React.Fragment>
            
            {draftreceipesexist ? <>
                <Container maxWidth='lg' style={{ marginBottom: "60px" }}>
                    <Typography variant="h5">Mes brouillons de recettes</Typography>
                    <Grid id="values" container spacing={4} alignItems="stretch" >
                        {myDraftReceipes.map((myDraft) => (
                            <Grid item xs={12} sm={12} md={6} lg={4} xl={3} key={myDraft._id} >
                                <CardReceipe draft={myDraft}
                                    image={receipe_placeholder}
                                />
                            </Grid>
                        ))}
                    </Grid>
                </Container>
            </> : <></>}
                
            
            <Container maxWidth='lg' style={{ marginBottom: "40px" }}>
                <Typography variant="h5">Mes recettes</Typography>
                {myreceipesexist ?
                    <>

                    </> :
                    <>
                        <Typography variant="body2" align="justify">Vous n'avez pas encore créé de recettes, mais cela ne saurait attendre ! Promis, ça ne fait pas mal, alors n'hésitez pas.</Typography>
                    </>
                }
            </Container>
            <Container maxWidth='lg' style={{ marginBottom: "40px" }}>
                <Typography variant="h5">Mes recettes favorites</Typography>
                <Typography variant="body2" align="justify">Nous avons plein de recettes qui n'attendent que vous pour être dégustées. Envie de jeter un coup d'oeil ?</Typography>
            </Container>
        </React.Fragment>
    );

}

export default Dashboard;

/*
 * import Divider from '@mui/material/Divider';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import InboxIcon from '@mui/icons-material/MoveToInbox';
import MailIcon from '@mui/icons-material/Mail';
 * */