/*######################################################
Creation Date: 01/02/2021
Author: Jean-Baptiste ROUGET
Updates:
# 01/02/2021: Creation
# 27/09/2021: Refine and comment code
######################################################*/
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import theme from '../../theme/lightTheme';


function CardEmphasis(props) {
  //const classes = styles();
  let orientation = props.direction;


  const matches = useMediaQuery(theme.breakpoints.up('md'));
//console.log("MATCHES: ", matches, " and initial orientation: ", orientation);
  if (orientation === 'left' & matches === false)
  {
    orientation = 'right';
  }
  //let urlimage = 'url("' + props.image + '")'
  //let boxstyle = {
  //  backgroundImage: urlimage,
  //  backgroundSize:"cover"
  //}
  //console.log("ORIENTATION: ", orientation);

  if (orientation === 'left'){
    return (


      <Grid id="values" container spacing={10} alignItems="stretch" style={{marginBottom:"60px"}}>
            <Grid item component={Box} xs={12} sm={12} md={6} lg={6} xl={6}>
                <Box>
                    <Typography variant="h5">{props.title}</Typography>
                    <Typography variant="body2" align="justify">{props.text1}</Typography>
                    <Typography variant="body2" align="justify" style={{marginTop:"18px"}}>{props.text2}</Typography>
                    <Typography variant="body2" align="justify" style={{marginTop:"18px"}}>{props.text3}</Typography>
                    <Box align="right" style={{marginTop:"18px"}}>
                    {props.button}
                    </Box>
                </Box>
            </Grid>
            <Grid item component={Box} xs={12} sm={12} md={6} lg={6} xl={6}>
                <Box >
                    <img src={props.image} style={{ align: 'rigth', maxWidth: '100%'}} alt={props.altimage}/>
                </Box>
            </Grid>
      </Grid>

    );
  }
  else {
    return (
      <Grid container spacing={10} alignItems="stretch" style={{marginBottom:"60px"}}>
            <Grid item component={Box} xs={12} sm={12} md={6} lg={6} xl={6}>

            <Box >
                <img src={props.image} alt={props.altimage} style={{maxWidth: '100%', verticalAlign: 'middle'}}/>
            </Box>

            </Grid>
            <Grid item component={Box} xs={12} sm={12} md={6} lg={6} xl={6}>
                <Box>
                    <Typography variant="h5">{props.title}</Typography>
                    <Typography variant="body2" align="justify">{props.text1}</Typography>
                    <Typography variant="body2" align="justify" style={{marginTop:"18px"}}>{props.text2}</Typography>
                    <Typography variant="body2" align="justify" style={{marginTop:"18px"}}>{props.text3}</Typography>
                    <Box align="right" style={{marginTop:"18px"}}>
                    {props.button}
                    </Box>
                </Box>
            </Grid>
      </Grid>


    );
  }

}

export default CardEmphasis;
