/*######################################################
Creation Date: 11/07/2021
Author: Jean-Baptiste ROUGET
Updates:
# 11/07/2021: Creation
# 28/09/2021: Error handling whil user is not found during account validation process
#
######################################################*/

import { apiUrl } from '../../config/keys';

const initialState = {
  isLoggedIn: false,
  loginError: false,
  loginErrorMessage: undefined,
  loginLoading: false,
  createAccountLoading: false,
  createAccountError: undefined,
  forgottenPasswordCodeLoading: false,
  forgottenPasswordCodeError: false,
  forgottenPasswordEmailSent: false,
  forgottenPasswordLoading: false,
  forgottenPasswordError: undefined,
  validateAccountCode: undefined,
  validateAccount: undefined,
  validateAccountLoading: false,
  token: undefined,
  authChecked: false


};

const CREATE_ACCOUNT_REQUEST = 'CREATE_ACCOUNT_REQUEST';
const CREATE_ACCOUNT_SUCCESS = 'CREATE_ACCOUNT_SUCCESS';
const CREATE_ACCOUNT_FAILURE = 'CREATE_ACCOUNT_FAILURE';

const VALIDATE_ACCOUNT_REQUEST = 'VALIDATE_ACCOUNT_REQUEST';
const VALIDATE_ACCOUNT_SUCCESS = 'VALIDATE_ACCOUNT_SUCCESS';
const VALIDATE_ACCOUNT_FAILURE = 'VALIDATE_ACCOUNT_FAILURE';

const FORGOTTEN_PASSWORD_CODE_REQUEST = 'FORGOTTEN_PASSWORD_CODE_REQUEST';
const FORGOTTEN_PASSWORD_CODE_SUCCESS = 'FORGOTTEN_PASSWORD_CODE_SUCCESS';
const FORGOTTEN_PASSWORD_CODE_FAILURE = 'FORGOTTEN_PASSWORD_CODE_FAILURE';

const FORGOTTEN_PASSWORD_REQUEST = 'FORGOTTEN_PASSWORD_REQUEST';
const FORGOTTEN_PASSWORD_SUCCESS = 'FORGOTTEN_PASSWORD_SUCCESS';
const FORGOTTEN_PASSWORD_FAILURE = 'FORGOTTEN_PASSWORD_FAILURE';

const LOGIN_CUSTOM_REQUEST = 'LOGIN_CUSTOM_REQUEST';
const LOGIN_CUSTOM_SUCCESS = 'LOGIN_CUSTOM_SUCCESS';
const LOGIN_CUSTOM_FAILURE = 'LOGIN_CUSTOM_FAILURE';

const LOGIN_GOOGLE_REQUEST = 'LOGIN_GOOGLE_REQUEST';
const LOGIN_GOOGLE_SUCCESS = 'LOGIN_GOOGLE_SUCCESS';
const LOGIN_GOOGLE_FAILURE = 'LOGIN_GOOGLE_FAILURE';

const LOGOUT = 'LOGOUT_ACTION';
const AUTH_CHECK_DONE = 'AUTH_CHECK_DONE';
const AUTH_CHECK_CANCELED = 'AUTH_CHECK_CANCELED';


export const createAccount = async (dispatch, navigation, firstName, lastName, email, pseudo, password, preferedLanguage, termsChecked, userType, agreedMarketing) => {
  dispatch({ type: CREATE_ACCOUNT_REQUEST });
  try {
    const headers = {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    };

    //console.log("Body container");
    let body = {
      firstName: firstName,
      lastName:lastName,
      email: email,
      pseudonyme: pseudo,
      password: password,
      preferedLanguage: preferedLanguage.split('-')[0],
      termsChecked: termsChecked,
      userType: userType,
      agreedMarketing: agreedMarketing
    };

     await fetch(`${apiUrl}${'/users'}`, {
      method: 'POST',
      headers,
      ...(body && { body: JSON.stringify(body) }),
    })
    .then( (response) => {
                //console.log(" ########## RESULT", response.status);
                //console.log("Fetch should have finished, receiving response or premise ", `${apiUrl}${'/users'}`);
                //console.log("Response: ", response);
                // eslint-disable-next-line
                const data = response.json()
                    .then((datajson)=>{
                        if (!response.ok) {
                            dispatch({ type: CREATE_ACCOUNT_FAILURE, value: datajson.code });
                          }
                          else{
                            //console.log("Response is OK");
                            dispatch({ type: CREATE_ACCOUNT_SUCCESS });
                            navigation.push('/auth/login');
                          }
                    })
            })
            .catch(error => {
              console.log("ERROR createAccount")
                this.setState({ errorMessage: error.toString() });
                //console.error('There was an error!', error);
            });

  } catch (err) {
    dispatch({ type: CREATE_ACCOUNT_FAILURE, value: err });
  }
}

export const validateAccount = async (dispatch, code) => {
    dispatch({ type: VALIDATE_ACCOUNT_REQUEST, value: code });
    try {
      const headers = {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      };
      let body = {
        code: code
      };
      await fetch(`${apiUrl}${'/users/validation'}`, {
        method: 'POST',
        headers,
        ...(body && { body: JSON.stringify(body) }),
      })
      .then( (response) => {
                  //console.log(" ########## RESULT", response.status);
                  //console.log("Fetch should have finished, receiving response or premise ", `${apiUrl}${'/users'}`);
                  //console.log("Response: ", response);
                  // eslint-disable-next-line
                  //console.log("ICI LOG");
                  // eslint-disable-next-line
                  const data = response.json()
                      .then((datajson)=>{
                          if (!response.ok) {
                              //console.log("Response is not OK ", response);

                              dispatch({ type: VALIDATE_ACCOUNT_FAILURE, value: code });
                            }
                            else{
                              //console.log("Response is OK");
                              dispatch({ type: VALIDATE_ACCOUNT_SUCCESS });
                            }
                      })
              })
              .catch(error => {
                dispatch({ type: VALIDATE_ACCOUNT_FAILURE, value: error });
              });

    } catch (err) {
      dispatch({ type: VALIDATE_ACCOUNT_FAILURE, value: err });
    }
}

export const forgottenPasswordCodeRequest = async (dispatch, email) =>{
    dispatch({ type: FORGOTTEN_PASSWORD_CODE_REQUEST });
    try {
        const headers = {
            Accept: 'application/json',
            'Content-Type': 'application/json',
        };
        let body = {
            email: email,
        };
        await fetch(`${apiUrl}${'/users/forgottenpasswordrequest'}`, {
        method: 'POST',
        headers,
        ...(body && { body: JSON.stringify(body) }),
      })
            .then((response) => {
           // eslint-disable-next-line
                  const data = response.json()
                      .then((datajson)=>{
                          if (!response.ok) {
                              //console.log("Response is not OK");
                              dispatch({ type: FORGOTTEN_PASSWORD_CODE_FAILURE, value: datajson.code });
                            }
                            else{
                              //console.log("Response is OK");
                              dispatch({ type: FORGOTTEN_PASSWORD_CODE_SUCCESS });
                              //navigation.navigate('login');
                            }
                      })
              })
              .catch(error => {
                  this.setState({ errorMessage: error.toString() });
              });

    }
    catch (err) {
        dispatch({ type: FORGOTTEN_PASSWORD_CODE_FAILURE, value: err });
    }
}

export const forgottenPasswordUpdate = async (dispatch, navigation, email, password, confirmationkey) => {
  dispatch({ type: FORGOTTEN_PASSWORD_REQUEST });
  try {
    const headers = {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    };
    let body = {
      email: email,
      password: password,
      confirmationkey: confirmationkey,
    };
    await fetch(`${apiUrl}${'/users/forgottenpasswordmodify'}`, {
      method: 'POST',
      headers,
      ...(body && { body: JSON.stringify(body) }),
    })
        .then((response) => {
         // eslint-disable-next-line
                const data = response.json()
                    .then((datajson)=>{
                        if (!response.ok) {
                            dispatch({ type: FORGOTTEN_PASSWORD_FAILURE, value: datajson.code });
                          }
                          else{
                            dispatch({ type: FORGOTTEN_PASSWORD_SUCCESS });
                            navigation.push('/auth/login');
                          }

                    })
            })
            .catch(error => {
                this.setState({ errorMessage: error.toString() });
            });
  } catch (err) {
    dispatch({ type: FORGOTTEN_PASSWORD_FAILURE, value: err });
  }
}




export const LoginCustom = async (dispatch, navigation, email, password) => {
  dispatch({ type: LOGIN_CUSTOM_REQUEST });

  const headers = {
      Accept: 'application/json',
      'Content-Type': 'application/json',
  };

  let body = {
      email: email,
      password: password,
  };
  await fetch(`${apiUrl}${'/users/login'}`, {
      method: 'POST',
      headers,
      ...(body && { body: JSON.stringify(body) }),
  })
      .then((response) => {
       // eslint-disable-next-line
        const data = response.json()
            .then(async (datajson)=>{
                if (response.ok) {
                    dispatch({ type: LOGIN_CUSTOM_SUCCESS, value: datajson.token });
                    navigation.push('/');
                }
                else {
                    dispatch({
                        type: LOGIN_CUSTOM_FAILURE, value: datajson.message });
                }

            })
        })
        .catch(error => {
            this.setState({ errorMessage: error.toString() });
        });
  }

export const LogOut = async (dispatch) => {
        dispatch({ type: LOGOUT });
}


export const LoadToken = async (dispatch, token) => {
  //console.log("LoadTOKEN !!!!: ", token);

  try {
    if (token && token !== undefined && token !== 'undefined') {
      //console.log("Ready to dispatch token");
      dispatch({ type: LOGIN_CUSTOM_SUCCESS, value:token });
      //dispatch({ type: AUTH_CHECK_DONE });
    }
  }
  finally {
    dispatch({ type: AUTH_CHECK_DONE });
    //console.log('Final step of login');
  }
}


function reducer(state = initialState, action) {
  switch (action.type) {
    case CREATE_ACCOUNT_REQUEST:
      return {
        ...state,
        createAccountLoading: true,
        createAccountError: undefined
      }
    case CREATE_ACCOUNT_SUCCESS:
      return {
        ...state,
        createAccountLoading: false,
        checkYourMailMessage: "Votre compte a bien été créé. Veuillez le valider via le mail que nous vous avons envoyé.",
        createAccountError: ""
      }
    case CREATE_ACCOUNT_FAILURE:
      return {
        ...state,
        createAccountLoading: false,
        createAccountError: action.value
      }
    case VALIDATE_ACCOUNT_REQUEST:
        return {
          ...state,
          validateAccountCode: action.value,
          validateAccountLoading: true
        }
    case VALIDATE_ACCOUNT_SUCCESS:
        return {
            ...state,
            validateAccount: true,
            validateAccountLoading: false
        }
    case VALIDATE_ACCOUNT_FAILURE:
        return {
            ...state,
            validateAccount: false,
            validateAccountLoading: false,
            validateAccountCode: action.value
        }
    case FORGOTTEN_PASSWORD_CODE_REQUEST:
        return {
            ...state,
            forgottenPasswordCodeLoading: true,
            forgottenPasswordCodeError: false,
            forgottenPasswordEmailSent: false,
          }
    case FORGOTTEN_PASSWORD_CODE_SUCCESS:
        return {
            ...state,
            forgottenPasswordCodeLoading: false,
            forgottenPasswordCodeError: false,
            forgottenPasswordEmailSent: true,
          }
    case FORGOTTEN_PASSWORD_CODE_FAILURE:
        return {
            ...state,
            forgottenPasswordCodeLoading: false,
            forgottenPasswordCodeError: true,
            forgottenPasswordEmailSent: false,
                }
      case FORGOTTEN_PASSWORD_REQUEST:
        return {
          ...state,
          forgottenPasswordLoading: true,
          forgottenPasswordError: undefined,
          forgottenPasswordEmailSent: false,
        }
        case FORGOTTEN_PASSWORD_SUCCESS:
          return {
            ...state,
            forgottenPasswordLoading: false,
            forgottenPasswordEmailSent: true,
          }
        case FORGOTTEN_PASSWORD_FAILURE:
          return {
            ...state,
            forgottenPasswordLoading: false,
            forgottenPasswordError: action.value
          }
      case LOGIN_CUSTOM_REQUEST:
        return {
          ...state,
          loginLoading: true,
          loginError: false
        }
      case LOGIN_CUSTOM_SUCCESS:
        return {
          ...state,
          loginLoading: false,
          isLoggedIn: true,
          loginError: false,
          loginErrorMessage: undefined,
          token: action.value
        }

      case LOGIN_CUSTOM_FAILURE:
        return {
          ...state,
          loginLoading: false,
          isLoggedIn: false,
          loginError: true,
          loginErrorMessage: action.value
        }
        case LOGIN_GOOGLE_REQUEST:
          return {
            ...state,
            loginLoading: true,
            loginError: undefined
          }
        case LOGIN_GOOGLE_SUCCESS:
          return {
            ...state,
            loginLoading: false,
            isLoggedIn: true,
            token: action.value
          }

        case LOGIN_GOOGLE_FAILURE:
          return {
            ...state,
            loginLoading: false,
            isLoggedIn: false,
            loginError: action.value
          }

        case AUTH_CHECK_DONE:
      return {
        ...state,
        authChecked: true
      }
    case AUTH_CHECK_CANCELED:
      return {
        ...state,
        authChecked: false
      }
      case LOGOUT:
        return {
          ...state,
          isLoggedIn: false,
          token: ""
        }

    default:
      return state
  }
}

export const selectLoginIsLoading = (state) => state.authentication.loginLoading || state.authentication.createAccountLoading;
export const selectIsLoggedIn = (state) => state.authentication.isLoggedIn;
export const selectLoginError = (state) => state.authentication.loginError;
export const selectLoginErrorMessage = (state) => state.authentication.loginErrorMessage;
export const selectCreateAccountError = (state) => state.authentication.createAccountError;
export const selectCreateAccountLoading = (state) => state.authentication.createAccountLoading;
export const selectValidateAccount = (state) => state.authentication.validateAccount;
export const selectValidateAccountCode = (state) => state.authentication.validateAccountCode;
export const selectValidateAccountError = (state) => state.authentication.validateAccountError;
export const selectValidateAccountLoading = (state) => state.authentication.validateAccountLoading;
export const selectToken = (state) => state.authentication.token;
export const selectAuthChecked = (state) => state.authentication.authChecked;
export const selectForgottenPasswordError = (state) => state.authentication.forgottenPasswordError;
export const selectForgottenPasswordLoading = (state) => state.authentication.forgottenPasswordLoading;
export const selectCheckYourMailMessage = (state) => state.authentication.checkYourMailMessage;
export const selectForgottenPasswordCodeLoading = (state) => state.authentication.forgottenPasswordCodeLoading;
export const selectForgottenPasswordCodeError = (state) => state.authentication.forgottenPasswordCodeError;
export const selectForgottenPasswordEmailSent = (state) => state.authentication.forgottenPasswordEmailSent;

export default reducer;
