// keys.js - figure out what set of credentials to return
//console.log("ACCESSING ENV VARIABLE: NODE_ENV : ", process.env.NODE_ENV, "COOKIE : ", process.env.COOKIE_KEY);
if (process.env.NODE_ENV === 'production') {
  //Production keys
  module.exports = require('./prod');
}
else if (process.env.NODE_ENV === 'staging') {
  //Production keys
  module.exports = require('./qa');
}
else {
  //development keys
  module.exports = require('./dev');
}
