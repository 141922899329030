/*######################################################
Creation Date: 11/07/2021
Author: Jean-Baptiste ROUGET
Updates:
# 13/07/2021: Creation "create receipe draft"
# 30/09/2021: Adding logic
# 30/12/2021: Add logic to update draft fields
######################################################*/
import { apiUrl } from '../../config/keys';                                     // URL for APIs

//import store from './store';
//import store from './store';
//import { useNavigation } from '@react-navigation/native';
//import { selectToken } from '../../store/Reducers/authentication';
//const navigation = useNavigation();


const initialState = {
  myReceipes: [],  //List of my own receipes
  myDraftReceipes: [], //List of my own draft receipes
  myActiveDraftReceipe: undefined, //The Receipe draft I just created or started to edit
  myReceipeLoading: false, //Signal this is ongoing / loading
  selectedReceipeInformationLoading: false, //Signal we are requesting specific receipe information
  selectedReceipeInformation: undefined, //Information from a specific receipe
  //idpassreminder: false,
  //cookiesLoaded: false
};



const CREATE_RECEIPE_DRAFT_REQUEST = 'CREATE_RECEIPE_DRAFT_REQUEST';
const CREATE_RECEIPE_DRAFT_SUCCESS = 'CREATE_RECEIPE_DRAFT_SUCCESS';
const CREATE_RECEIPE_DRAFT_FAILURE = 'CREATE_RECEIPE_DRAFT_FAILURE';
const GET_RECEIPE_DRAFT_REQUEST = 'GET_RECEIPE_DRAFT_REQUEST';
const GET_RECEIPE_DRAFT_SUCCESS = 'GET_RECEIPE_DRAFT_SUCCESS';
const GET_RECEIPE_DRAFT_FAILURE = 'GET_RECEIPE_DRAFT_FAILURE';
const UPDATE_RECEIPE_DRAFT_REQUEST = 'UPDATE_RECEIPE_DRAFT_REQUEST';
const UPDATE_RECEIPE_DRAFT_SUCCESS = 'UPDATE_RECEIPE_DRAFT_SUCCESS';
const UPDATE_RECEIPE_DRAFT_FAILURE = 'UPDATE_RECEIPE_DRAFT_FAILURE';
const GET_RECEIPE_INFORMATION_REQUEST = 'GET_RECEIPE_INFORMATION_REQUEST';
const GET_RECEIPE_INFORMATION_SUCCESS = 'GET_RECEIPE_INFORMATION_SUCCESS';
const GET_RECEIPE_INFORMATION_FAILURE = 'GET_RECEIPE_INFORMATION_FAILURE';
const ADD_CUSTOM_INGREDIENT_TO_RECEIPE_REQUEST = 'ADD_CUSTOM_INGREDIENT_TO_RECEIPE_REQUEST';
const ADD_CUSTOM_INGREDIENT_TO_RECEIPE_SUCCESS = 'ADD_CUSTOM_INGREDIENT_TO_RECEIPE_SUCCESS';
const ADD_CUSTOM_INGREDIENT_TO_RECEIPE_FAILURE = 'ADD_CUSTOM_INGREDIENT_TO_RECEIPE_FAILURE';
const DELETE_CUSTOM_INGREDIENT_FROM_RECEIPE_REQUEST = 'DELETE_CUSTOM_INGREDIENT_FROM_RECEIPE_REQUEST';
const DELETE_CUSTOM_INGREDIENT_FROM_RECEIPE_SUCCESS = 'DELETE_CUSTOM_INGREDIENT_FROM_RECEIPE_SUCCESS';
const DELETE_CUSTOM_INGREDIENT_FROM_RECEIPE_FAILURE = 'DELETE_CUSTOM_INGREDIENT_FROM_RECEIPE_FAILURE';
const ADD_CUSTOM_ACTION_TO_RECEIPE_REQUEST = 'ADD_CUSTOM_ACTION_TO_RECEIPE_REQUEST';
const ADD_CUSTOM_ACTION_TO_RECEIPE_SUCCESS = 'ADD_CUSTOM_ACTION_TO_RECEIPE_SUCCESS';
const ADD_CUSTOM_ACTION_TO_RECEIPE_FAILURE = 'ADD_CUSTOM_ACTION_TO_RECEIPE_FAILURE';
const DELETE_CUSTOM_ACTION_FROM_RECEIPE_REQUEST = 'DELETE_CUSTOM_ACTION_FROM_RECEIPE_REQUEST';
const DELETE_CUSTOM_ACTION_FROM_RECEIPE_SUCCESS = 'DELETE_CUSTOM_ACTION_FROM_RECEIPE_SUCCESS';
const DELETE_CUSTOM_ACTION_FROM_RECEIPE_FAILURE = 'DELETE_CUSTOM_ACTION_FROM_RECEIPE_FAILURE';


export const Testfunc = async (dispatch, token, name) => {
  console.log("TEST FUNC: ", name);
  console.log("Ready to save receipe draft: ", name);
  console.log('Token : ', token);
  //const statex = store.getState();
  //const token = selectToken(statex);
  //console.log('Token: ', token);
}

export const CreateReceipeDraft = async (dispatch, token, name) => {
    // Function to create a receipe draft and save it as a draft to allow further edition
    //console.log("TEST FUNC: ", name);
    console.log("Ready to save receipe draft: ", name);
    console.log('Token : ', token);

    // Saves the receipe
    dispatch({ type: CREATE_RECEIPE_DRAFT_REQUEST });
    try {
      const headers = {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      };
      if (token) {       headers.Authorization = `Bearer ${token}`;     }

      //console.log("Body container");
      let body = {
        name: name,
      };

      await fetch(`${apiUrl}${'/receipes'}`, {
        method: 'POST',
        headers,
        ...(body && { body: JSON.stringify(body) }),
      })
      .then( (response) => {
                  //console.log(" ########## RESULT", response.status);
                  //console.log("Fetch should have finished, receiving response or premise ", `${apiUrl}${'/users'}`);
                  //console.log("Response: ", response);
                  // eslint-disable-next-line
                  const data = response.json()
                      .then((datajson)=>{
                          if (!response.ok) {
                              dispatch({ type: CREATE_RECEIPE_DRAFT_FAILURE, value: datajson.code });
                            }
                            else{
                              //console.log("Response is OK");
                              dispatch({ type: CREATE_RECEIPE_DRAFT_SUCCESS, value: datajson });
                            }
                      })
              })
              .catch(error => {
                console.log("ERROR createAccount")
                  this.setState({ errorMessage: error.toString() });
                  //console.error('There was an error!', error);
              });

    } catch (err) {
      dispatch({ type: CREATE_RECEIPE_DRAFT_FAILURE, value: err });
    }

    // Get back the receipe
    // Add the receipe to myReceipeDrafts
}

export const GetMyReceipeDrafts = async (dispatch, token) => {
    // Function to create a receipe draft and save it as a draft to allow further edition
    //console.log("TEST FUNC: ", name);
    console.log("Ready to get receipe draft list: ");
    console.log('Token : ', token);

    // Saves the receipe
    dispatch({ type: GET_RECEIPE_DRAFT_REQUEST });
    try {
      const headers = {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      };
      if (token) {       headers.Authorization = `Bearer ${token}`;     }

      //console.log("Body container");

      await fetch(`${apiUrl}${'/receipes/draft'}`, {
        method: 'GET',
        headers,
      })
      .then( (response) => {
                  //console.log(" ########## RESULT", response.status);
                  //console.log("Fetch should have finished, receiving response or premise ", `${apiUrl}${'/users'}`);
                  //console.log("Response: ", response);
                  // eslint-disable-next-line
                   const data = response.json()
                      .then((data)=>{
                          if (!response.ok) {
                              console.log("Response is not OK");
                              dispatch({ type: GET_RECEIPE_DRAFT_FAILURE, value: data.code });
                            }
                            else{
                              console.log("Response is OK");
                              dispatch({ type: GET_RECEIPE_DRAFT_SUCCESS, value: data });
                            }
                      })
              })
              .catch(error => {
                console.log("ERROR createAccount")
                  this.setState({ errorMessage: error.toString() });
                  //console.error('There was an error!', error);
              });

    } catch (err) {
      dispatch({ type: GET_RECEIPE_DRAFT_FAILURE, value: err });
    }

    // Get back the receipe
    // Add the receipe to myReceipeDrafts
}

export const GetReceipeInformation = async (dispatch, token, receipe_slug) => {
    // Function to create a receipe draft and save it as a draft to allow further edition
    //console.log("TEST FUNC: ", name);
    console.log("Ready to get receipe information for slug: ", receipe_slug);
    //console.log('Token : ', token);
    let body = {
        receipe_slug: receipe_slug,
    };
    // Saves the receipe
    dispatch({ type: GET_RECEIPE_INFORMATION_REQUEST });
    try {
        const headers = {
            Accept: 'application/json',
            'Content-Type': 'application/json',
        };
        if (token) { headers.Authorization = `Bearer ${token}`; }

        //console.log("Body container");

        await fetch(`${apiUrl}${'/receipes/information'}`, {
            method: 'POST',
            headers,
            ...(body && { body: JSON.stringify(body) }),
        })
            .then((response) => {
                //console.log(" ########## RESULT", response.status);
                //console.log("Fetch should have finished, receiving response or premise ", `${apiUrl}${'/users'}`);
                //console.log("Response: ", response);
                // eslint-disable-next-line
                const data = response.json()
                    .then((data) => {
                        if (!response.ok) {
                            dispatch({ type: GET_RECEIPE_INFORMATION_FAILURE, value: data.code });
                        }
                        else {
                            //console.log("Response is OK");
                            dispatch({ type: GET_RECEIPE_INFORMATION_SUCCESS, value: data });
                        }
                    })
            })
            .catch(error => {
                console.log("ERROR RECEIVING RECEIPE INFORMATION ")
                this.setState({ errorMessage: error.toString() });
                //console.error('There was an error!', error);
            });

    } catch (err) {
        dispatch({ type: GET_RECEIPE_INFORMATION_FAILURE, value: err });
    }

    // Get back the receipe
    // Add the receipe to myReceipeDrafts
}

export const UpdateDraftReceipeInformation = async (dispatch, token, receipe_id, index, receipe_field_name, receipe_field_new_value) => {
    // Function to create a receipe draft and save it as a draft to allow further edition
    //console.log("TEST FUNC: ", name);
    console.log("Ready toupdate receipe information for id: ", receipe_id);
    //console.log('Token : ', token);
    let body = {
        receipe_id: receipe_id,
        receipe_field_name: receipe_field_name,
        receipe_field_new_value: receipe_field_new_value
    };
    
    dispatch({ type: UPDATE_RECEIPE_DRAFT_REQUEST });
    try {
        const headers = {
            Accept: 'application/json',
            'Content-Type': 'application/json',
        };
        if (token) { headers.Authorization = `Bearer ${token}`; }

        //console.log("Body container");

        await fetch(`${apiUrl}${'/receipes/draft'}`, {
            method: 'POST',
            headers,
            ...(body && { body: JSON.stringify(body) }),
        })
            .then((response) => {
                //console.log(" ########## RESULT", response.status);
                //console.log("Fetch should have finished, receiving response or premise ", `${apiUrl}${'/users'}`);
                //console.log("Response: ", response);
                // eslint-disable-next-line
                const data = response.json()
                    .then((data) => {
                        if (!response.ok) {
                            dispatch({ type: UPDATE_RECEIPE_DRAFT_FAILURE, value: data.code });
                        }
                        else {
                            //console.log("Response is OK");
                            dispatch({ type: UPDATE_RECEIPE_DRAFT_SUCCESS, value: data, index: index });
                        }
                    })
            })
            .catch(error => {
                console.log("ERROR RECEIVING RECEIPE INFORMATION ")
                this.setState({ errorMessage: error.toString() });
                //console.error('There was an error!', error);
            });

    } catch (err) {
        dispatch({ type: GET_RECEIPE_INFORMATION_FAILURE, value: err });
    }

    // Get back the receipe
    // Add the receipe to myReceipeDrafts
}

export const AddIngredientToReceipe = async (dispatch, token, receipeId, reference_ingredient, quantity, quantityType, index) => {
    // Function to create a receipe draft and save it as a draft to allow further edition
    //console.log("TEST FUNC: ", name);
    console.log("Ready to add ingredient to receipe: ", receipeId);
    console.log("reference_ingredient: ", reference_ingredient);
    console.log("quantityType: ", quantityType);
    console.log("quantity: ", quantity);
    console.log("receipeId: ", receipeId);
    //console.log('Token : ', token);
    let body = {
        receipeId: receipeId,
        reference_ingredient: reference_ingredient,
        quantity: quantity,
        quantityType: quantityType
    };

    dispatch({ type: ADD_CUSTOM_INGREDIENT_TO_RECEIPE_REQUEST });
    try {
        const headers = {
            Accept: 'application/json',
            'Content-Type': 'application/json',
        };
        if (token) { headers.Authorization = `Bearer ${token}`; }

        //console.log("Body container");

        await fetch(`${apiUrl}${'/receipes/ingredients'}`, {
            method: 'POST',
            headers,
            ...(body && { body: JSON.stringify(body) }),
        })
            .then((response) => {
                //console.log(" ########## RESULT", response.status);
                //console.log("Fetch should have finished, receiving response or premise ", `${apiUrl}${'/users'}`);
                //console.log("Response: ", response);
                // eslint-disable-next-line
                const data = response.json()
                    .then((data) => {
                        if (!response.ok) {
                            dispatch({ type: ADD_CUSTOM_INGREDIENT_TO_RECEIPE_FAILURE, value: data.code });
                        }
                        else {
                            console.log("Response is OK");
                            console.log("Data receipe updated received: ", data);
                            dispatch({ type: ADD_CUSTOM_INGREDIENT_TO_RECEIPE_SUCCESS, value: data, index: index });
                        }
                    })
            })
            .catch(error => {
                console.log("ERROR RECEIVING RECEIPE INFORMATION ")
                this.setState({ errorMessage: error.toString() });
                //console.error('There was an error!', error);
            });

    } catch (err) {
        dispatch({ type: ADD_CUSTOM_INGREDIENT_TO_RECEIPE_FAILURE, value: err });
    }

    // Get back the receipe
    // Add the receipe to myReceipeDrafts
}

export const DeleteIngredientFromReceipe = async (dispatch, token, ingredientId, receipeId, index) => {
    // Function to create a receipe draft and save it as a draft to allow further edition
    //console.log("TEST FUNC: ", name);
    //console.log("Ready to add ingredient to receipe: ", receipeId);
    //console.log("reference_ingredient: ", reference_ingredient);
    //console.log("quantityType: ", quantityType);
    //console.log("quantity: ", quantity);
    //console.log("receipeId: ", receipeId);
    //console.log('Token : ', token);
    let body = {
        reference_receipedraft: receipeId,
        reference_ingredient: ingredientId,
    };

    dispatch({ type: DELETE_CUSTOM_INGREDIENT_FROM_RECEIPE_REQUEST });
    try {
        const headers = {
            Accept: 'application/json',
            'Content-Type': 'application/json',
        };
        if (token) { headers.Authorization = `Bearer ${token}`; }

        //console.log("Body container");

        await fetch(`${apiUrl}${'/receipes/ingredients/delete'}`, {
            method: 'POST',
            headers,
            ...(body && { body: JSON.stringify(body) }),
        })
            .then((response) => {
                //console.log(" ########## RESULT", response.status);
                //console.log("Fetch should have finished, receiving response or premise ", `${apiUrl}${'/users'}`);
                //console.log("Response: ", response);
                // eslint-disable-next-line
                const data = response.json()
                    .then((data) => {
                        if (!response.ok) {
                            dispatch({ type: DELETE_CUSTOM_INGREDIENT_FROM_RECEIPE_FAILURE, value: data.code });
                        }
                        else {
                            console.log("Response is OK");
                            console.log("Data receipe updated received: ", data);
                            dispatch({ type: DELETE_CUSTOM_INGREDIENT_FROM_RECEIPE_SUCCESS, value: data, index: index });
                        }
                    })
            })
            .catch(error => {
                console.log("ERROR RECEIVING RECEIPE INFORMATION ")
                this.setState({ errorMessage: error.toString() });
                //console.error('There was an error!', error);
            });

    } catch (err) {
        dispatch({ type: DELETE_CUSTOM_INGREDIENT_FROM_RECEIPE_FAILURE, value: err });
    }

    // Get back the receipe
    // Add the receipe to myReceipeDrafts
}

export const DeleteActionFromReceipe = async (dispatch, token, actionId, receipeId, index) => {
    // Function to create a receipe draft and save it as a draft to allow further edition
    //console.log("TEST FUNC: ", name);
    //console.log("Ready to add ingredient to receipe: ", receipeId);
    //console.log("reference_ingredient: ", reference_ingredient);
    //console.log("quantityType: ", quantityType);
    //console.log("quantity: ", quantity);
    //console.log("receipeId: ", receipeId);
    //console.log('Token : ', token);
    let body = {
        reference_receipedraft: receipeId,
        reference_action: actionId,
    };

    dispatch({ type: DELETE_CUSTOM_ACTION_FROM_RECEIPE_REQUEST });
    try {
        const headers = {
            Accept: 'application/json',
            'Content-Type': 'application/json',
        };
        if (token) { headers.Authorization = `Bearer ${token}`; }

        //console.log("Body container");

        await fetch(`${apiUrl}${'/receipes/actions/delete'}`, {
            method: 'POST',
            headers,
            ...(body && { body: JSON.stringify(body) }),
        })
            .then((response) => {
                //console.log(" ########## RESULT", response.status);
                //console.log("Fetch should have finished, receiving response or premise ", `${apiUrl}${'/users'}`);
                //console.log("Response: ", response);
                // eslint-disable-next-line
                const data = response.json()
                    .then((data) => {
                        if (!response.ok) {
                            dispatch({ type: DELETE_CUSTOM_ACTION_FROM_RECEIPE_FAILURE, value: data.code });
                        }
                        else {
                            console.log("Response is OK");
                            console.log("Data receipe updated received: ", data);
                            dispatch({ type: DELETE_CUSTOM_ACTION_FROM_RECEIPE_SUCCESS, value: data, index: index });
                        }
                    })
            })
            .catch(error => {
                console.log("ERROR RECEIVING RECEIPE INFORMATION ")
                this.setState({ errorMessage: error.toString() });
                //console.error('There was an error!', error);
            });

    } catch (err) {
        dispatch({ type: DELETE_CUSTOM_ACTION_FROM_RECEIPE_FAILURE, value: err });
    }

    // Get back the receipe
    // Add the receipe to myReceipeDrafts
}


export const AddActionToReceipe = async (dispatch, token, reference_receipe, reference_action, temperature, duration, ingredients, index) => {
    // Function to create a receipe draft and save it as a draft to allow further edition
    //console.log("TEST FUNC: ", name);
    console.log("Ready to add action to receipe: ", reference_receipe);
    console.log("reference_action: ", reference_action);
    console.log("temperature: ", temperature);
    console.log("duration: ", duration);
    console.log("ingredients: ", ingredients);
    console.log("index: ", index);
    //console.log('Token : ', token);
    let body = {
        reference_receipe: reference_receipe,
        reference_action: reference_action,
        temperature: temperature,
        ingredients: ingredients,
        duration: duration,

    };

    dispatch({ type: ADD_CUSTOM_ACTION_TO_RECEIPE_REQUEST });
    try {
        const headers = {
            Accept: 'application/json',
            'Content-Type': 'application/json',
        };
        if (token) { headers.Authorization = `Bearer ${token}`; }

        //console.log("Body container");

        await fetch(`${apiUrl}${'/receipes/actions'}`, {
            method: 'POST',
            headers,
            ...(body && { body: JSON.stringify(body) }),
        })
            .then((response) => {
                //console.log(" ########## RESULT", response.status);
                //console.log("Fetch should have finished, receiving response or premise ", `${apiUrl}${'/users'}`);
                console.log("Response: ", response);
                // eslint-disable-next-line
                const data = response.json()
                    .then((data) => {
                        if (!response.ok) {
                            dispatch({ type: ADD_CUSTOM_ACTION_TO_RECEIPE_FAILURE, value: data.code });
                        }
                        else {
                            console.log("Response is OK");
                            console.log("Data receipe updated received: ", data);
                            dispatch({ type: ADD_CUSTOM_ACTION_TO_RECEIPE_SUCCESS, value: data, index: index });
                        }
                    })
            })
            .catch(error => {
                console.log("ERROR RECEIVING RECEIPE INFORMATION ")
                this.setState({ errorMessage: error.toString() });
                //console.error('There was an error!', error);
            });

    } catch (err) {
        dispatch({ type: ADD_CUSTOM_ACTION_TO_RECEIPE_FAILURE, value: err });
    }

    // Get back the receipe
    // Add the receipe to myReceipeDrafts
}




function reducer(state = initialState, action) {
  switch (action.type) {
    case CREATE_RECEIPE_DRAFT_REQUEST:
      return {
        ...state,
        myReceipeLoading: true,
      }
    case CREATE_RECEIPE_DRAFT_SUCCESS:
      return {
        ...state,
        myDraftReceipes: [...state.myDraftReceipes, action.value],
        myActiveDraftReceipe: action.value
      }
    case CREATE_RECEIPE_DRAFT_FAILURE:
      return {
          ...state,
          myActiveDraftReceipe:undefined

      }
    case GET_RECEIPE_INFORMATION_REQUEST:
      return {
        ...state,
          selectedReceipeInformationLoading: true,
          selectedReceipeInformation: undefined
      }
    case GET_RECEIPE_INFORMATION_SUCCESS:
      return {
          ...state,
        selectedReceipeInformationLoading: false,
        selectedReceipeInformation: action.value,
      }
    case GET_RECEIPE_INFORMATION_FAILURE:
      return {
        ...state,
          selectedReceipeInformationLoading: false,
          selectedReceipeInformation: undefined
          }
     case GET_RECEIPE_DRAFT_REQUEST:
      return {
        ...state,
        myReceipeLoading: true,
      }
      case GET_RECEIPE_DRAFT_SUCCESS:
          return {
              ...state,
              myDraftReceipes: action.value,
              myReceipeLoading: false,
          }
      case GET_RECEIPE_DRAFT_FAILURE:
          return {
              ...state,
              myReceipeLoading: false,

          }
      case UPDATE_RECEIPE_DRAFT_REQUEST:
          return {
              ...state,
              myReceipeLoading: true,
          }
      case UPDATE_RECEIPE_DRAFT_SUCCESS:
          console.log("ACTION : ", action);
          return {
              ...state,
              myDraftReceipes: state.myDraftReceipes.map(
                  (content, i) => i === action.index ? action.value
                      : content
              ),
             
              //myDraftReceipes[action.index] = action.value,
              myReceipeLoading: false,
          }
      case UPDATE_RECEIPE_DRAFT_FAILURE:
          return {
              ...state,
              myReceipeLoading: false,

          }
      case ADD_CUSTOM_INGREDIENT_TO_RECEIPE_REQUEST:
          return {
              ...state,
              
          }
      case ADD_CUSTOM_INGREDIENT_TO_RECEIPE_SUCCESS:
          return {
              ...state,
              myDraftReceipes: state.myDraftReceipes.map(
                  (content, i) => i === action.index ? action.value
                      : content
              ),
              myActiveDraftReceipe: action.value,
              
          }
      case ADD_CUSTOM_INGREDIENT_TO_RECEIPE_FAILURE:
          return {
              ...state,
              

          }
      case ADD_CUSTOM_ACTION_TO_RECEIPE_REQUEST:
          return {
              ...state,

          }
      case ADD_CUSTOM_ACTION_TO_RECEIPE_SUCCESS:
          return {
              ...state,
              myDraftReceipes: state.myDraftReceipes.map(
                  (content, i) => i === action.index ? action.value
                      : content
              ),
              myActiveDraftReceipe: action.value,

          }
      case ADD_CUSTOM_ACTION_TO_RECEIPE_FAILURE:
          return {
              ...state,


          }
      case DELETE_CUSTOM_INGREDIENT_FROM_RECEIPE_REQUEST:
          return {
              ...state,

          }
      case DELETE_CUSTOM_INGREDIENT_FROM_RECEIPE_SUCCESS:
          return {
              ...state,
              myDraftReceipes: state.myDraftReceipes.map(
                  (content, i) => i === action.index ? action.value
                      : content
              ),
              myActiveDraftReceipe: action.value,

          }
      case DELETE_CUSTOM_INGREDIENT_FROM_RECEIPE_FAILURE:
          return {
              ...state,


          }
      case DELETE_CUSTOM_ACTION_FROM_RECEIPE_REQUEST:
          return {
              ...state,

          }
      case DELETE_CUSTOM_ACTION_FROM_RECEIPE_SUCCESS:
          return {
              ...state,
              myDraftReceipes: state.myDraftReceipes.map(
                  (content, i) => i === action.index ? action.value
                      : content
              ),
              myActiveDraftReceipe: action.value,

          }
      case DELETE_CUSTOM_ACTION_FROM_RECEIPE_FAILURE:
          return {
              ...state,


          }

         
          
    default:
      return state
  }
}

export const selectMyDraftReceipes = (state) => state.receipe.myDraftReceipes;
export const selectMyActiveDraftReceipe = (state) => state.receipe.myActiveDraftReceipe;
export const selectMyReceipes = (state) => state.receipe.myReceipes;
export const selectSelectedReceipeInformationLoading = (state) => state.receipe.selectedReceipeInformationLoading;
export const selectSelectedReceipeInformation = (state) => state.receipe.selectedReceipeInformation;
export const selectMyReceipeLoading = (state) => state.receipe.myReceipeLoading;



export default reducer;
