/*######################################################
Creation Date: 01/01/2021
Author: Jean-Baptiste ROUGET
Definition: This page is the homepage component of the website, meaning that it is shown as the root as landing page. It appears below the menu bar
Updates:
# 11/01/2021: Creation
# 26/08/2021: Refining code, adding comprehensive comments, decreasing file size
# 26/09/2021: Reducing code and handling simple format layer
######################################################*/
import React from 'react';                                              //Allow use of React
import Values from '../components/brand/Values';                        //Import component
import Functionalities from '../components/brand/Functionalities';      //Import component
import Container from '@material-ui/core/Container';                    //Material UI basic container
import styles from '../theme/style';                                    //Theming


const Home = (props) => {
    //Definitions
    const classes = styles();
    

    //If login state is true, we show the Dashboard app 
   
        return (
            <Container component='main' className={classes.containerXL}>
                <Container maxWidth="lg" component="main" className={classes.brandValues}>
                    <Values />
                </Container>
                <Container maxWidth="lg" component="main">
                    <Functionalities />
                </Container>
            </Container>
        );
  




 }



export default Home;
/* IF NEED BE IN ORDER TO SHOW STATE IN PROPS - IN PLACE OF LINE ABOVE
function mapStateToProps({ authentication, currentUser }) {
    return { authentication, currentUser }
}
export default connect(mapStateToProps) (Header);

*/

