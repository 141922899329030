/*######################################################
Creation Date: 01/02/2021
Author: Jean-Baptiste ROUGET
Updates:
# 01/02/2021: Creation
# 12/10/2021: Code review. 
######################################################*/
import { apiUrl } from '../../config/keys';



const initialState = {
    userDetailError:"",
    email:"",
    pseudonyme:"",
    userType:"",
    preferedLanguage:"",
    accountStatus:"",
    accountCreationDateTime:""
  }

  const GET_USER_PROFILE_REQUEST = 'GET_USER_PROFILE_REQUEST';
  const GET_USER_PROFILE_SUCCESS = 'GET_USER_PROFILE_SUCCESS';
  const GET_USER_PROFILE_FAILURE = 'GET_USER_PROFILE_FAILURE';


  export const getUserInfo = async (dispatch, token) => {
    //const state = store.getState();
    //const token = selectToken(state);
    dispatch({ type: GET_USER_PROFILE_REQUEST });
      console.log("START RETREIVING USER INFO");
      //const state = store.getState();

      //console.log("Token found on the device: ", token);
      const headers = {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      };
      if (token) {       headers.Authorization = `Bearer ${token}`;     }
      //console.log("Body container");
      
      console.log("HERE IT STARTS");
     await fetch(`${apiUrl}${'/users'}`, {
        method: 'GET',
        headers,

      })
      .then( (response) => {
                  console.log("Fetch should have finished to gather user info, receiving response or premise");
          console.log("Response: ", response);
          if (response.ok) {

              //console.log("Data token: ", datajson.token);
              //await deviceStorage.saveItem(STORAGE_KEY, datajson.token);
              //console.log("Adding token to device");
              // TODO: remove
              //const action = { type: "ADDTOKEN", value: datajson.token }
              //dispatch(action)
              console.log("Where we are");
              dispatch({ type: GET_USER_PROFILE_SUCCESS, value: response });
              //navigation.navigate('login');

          }
          else {
              console.log("Response is not OK");
              //console.log("Should get json data");
              //console.log("Data json: ", datajson);
              //return datajson
              dispatch({ type: GET_USER_PROFILE_FAILURE, value: response.status });


          }
                  // eslint-disable-next-line
                  
              })
         .catch(error => {
             console.log("There was an error there");
                  this.setState({ errorMessage: error.toString() });
                  dispatch({ type: GET_USER_PROFILE_FAILURE, value: "error catched" });
                  //console.error('There was an error!', error);
              });








  }




  function reducer(state = initialState, action) {
      switch (action.type) {
        case GET_USER_PROFILE_REQUEST:
          return {
            ...state

          }
        case GET_USER_PROFILE_SUCCESS:
          return {
            ...state,
            email:action.value.email,
            pseudonyme:action.value.pseudonyme,
            userType:action.value.userType,
            preferedLanguage:action.value.preferedLanguage,
            accountStatus:action.value.accountStatus,
            accountCreationDateTime: action.value.accountCreationDateTime,
            userDetailError: ""
          }
        case GET_USER_PROFILE_FAILURE:
          return {
            ...state,
            userDetailError: action.value
          }
        default:
          return state
      }
    }

    export const selectUserEmail = (state) => state.currentuser.email;
    export const selectUserPseudo = (state) => state.currentuser.pseudonyme;
    export const selectUserType = (state) => state.currentuser.userType;
    export const selectUserPreferedLanguage = (state) => state.currentUser.preferedLanguage;
    export const selectUserAccountStatus = (state) => state.currentuser.accountStatus;
    export const selectUserAccountCreationDate = (state) => state.currentuser.accountCreationDateTime;
    export const selectUserDetailError = (state) => state.currentuser.userDetailError;
    export default reducer;
