import { apiUrl } from '../../config/keys';
//import { useDispatch, useSelector } from 'react-redux';
import { selectToken } from './authentication';
//import store  from '../configureStore';
import store from './store';

const initialState = {
    getUserDetailError:"",
    email:"",
    pseudonyme:"",
    userType:"",
    preferedLanguage:"",
    accountStatus:"",
    accountCreationDateTime:"",
    errorMessage:""
  }

  const GET_USER_PROFILE_REQUEST = 'GET_USER_PROFILE_REQUEST';
  const GET_USER_PROFILE_SUCCESS = 'GET_USER_PROFILE_SUCCESS';
  const GET_USER_PROFILE_FAILURE = 'GET_USER_PROFILE_FAILURE';

  export const getUserInfo = async (dispatch) => {
    const state = store.getState();
    const token = selectToken(state);
    //const token =
    dispatch({ type: GET_USER_PROFILE_REQUEST });
      console.log("START RETREIVING USER INFO");
      //const state = store.getState();

      console.log("Token found on the device user: ", token);
      const headers = {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      };
      if (token) {       headers.Authorization = `Bearer ${token}`;     }
      //console.log("Body container");

      console.log("HERE IT STARTS");
      await fetch(`${apiUrl}${'/users'}`, {
        method: 'GET',
        headers,

      })
      .then( (response) => {
                  console.log("Fetch should have finished, receiving response or premise");
                  //console.log("Response: ", response);
                  response.json()
                      .then(async (datajson)=>{
                          if (response.ok) {

                              console.log("Data token: ", datajson);
                              //await deviceStorage.saveItem(STORAGE_KEY, datajson.token);
                              //console.log("Adding token to device");
                              // TODO: remove
                              //const action = { type: "ADDTOKEN", value: datajson.token }
                              //dispatch(action)
                              //console.log("End of adding token to device");
                              dispatch({ type: GET_USER_PROFILE_SUCCESS, value: datajson });
                              //navigation.navigate('login');

                            }
                            else{
                              console.log("Response is not OK");
                              //console.log("Should get json data");
                              //console.log("Data json: ", datajson);
                              //return datajson
                              dispatch({ type: GET_USER_PROFILE_FAILURE, value: datajson.code });


                            }

                      })
              })
              .catch(error => {
                  this.setState({ errorMessage: error.toString() });
                  //console.error('There was an error!', error);
              });








  }



  function reducer(state = initialState, action) {
      switch (action.type) {
        case GET_USER_PROFILE_REQUEST:
          return {
            ...state

          }
        case GET_USER_PROFILE_SUCCESS:
          return {
            ...state,
            email:action.value.email,
            pseudonyme:action.value.pseudonyme,
            userType:action.value.userType,
            preferedLanguage:action.value.preferedLanguage,
            accountStatus:action.value.accountStatus,
            accountCreationDateTime:action.value.accountCreationDateTime
          }
        case GET_USER_PROFILE_FAILURE:
          return {
            ...state,
            getUserDetailError: action.value
          }
        default:
          return state
      }
    }


export const selectUserEmail = (state) => state.currentUser.email;
export const selectUserPseudo = (state) => state.currentUser.pseudonyme;
export const selectUserType = (state) => state.currentUser.userType;
export const selectUserPreferedLanguage = (state) => state.currentUser.preferedLanguage;
export const selectUserAccountStatus = (state) => state.currentUser.accountStatus;
export const selectUserAccountCreationDate = (state) => state.currentUser.accountCreationDateTime;
export const selectUserDetailError = (state) => state.currentUsergetUserDetailError;
export default reducer;
