import React from 'react';
//import Button from '@material-ui/core/Button';
//import Values from '../components/brand/Values';
//import Functionalities from '../components/brand/Functionalities';
import Container from '@material-ui/core/Container';
import VersionInformation from '../components/library/VersionInformation';
import styles from '../theme/style';

const Version = (props) => {
const classes = styles();


  //const dispatch = useDispatch();
  //class App extends Component {


    //componentDidMount(){
      //this.props.fetchUser();
    //}

    //SI COOKIE EXISTE, ON SE LOG  -> IS LOGGEDIN


      return (
        <Container className={classes.main}>

        <Container maxWidth="lg" component="main" style={{marginTop: '60px'}}>

            <VersionInformation/>

        </Container>
        <Container style={{padding:'50px'}}/>


        </Container>
      );


    }




/*
function mapStateToProps({ authentication, currentUser, pointofsale }) {
  return { authentication, currentUser, pointofsale };

}

export default connect(mapStateToProps) (Header);

*/
export default Version;
