/*######################################################
Creation Date: 14/07/2021
Author: Jean-Baptiste ROUGET
Updates:
# 29/09/2021: Creation in order to rewrite code a better way
#
#
######################################################*/
import React from 'react';
import { useState, useEffect } from 'react';
import Box from '@mui/material/Box';
import Container from '@material-ui/core/Container';
import Drawer from '@mui/material/Drawer';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import styles from "../theme/style";
import { useDispatch, useSelector } from 'react-redux';
import { selectToken, LogOut } from '../store/reducers/authentication';
import { getUserInfo, selectUserEmail, selectUserDetailError } from '../store/reducers/currentuser';
//import { selectCookieSetup, selectAuthCookie, selectIdpassreminder } from '../store/reducers/legal';
import { selectMyDraftReceipes, GetMyReceipeDrafts } from '../store/reducers/receipe';
import { GetIngredientList, selectIngredients } from '../store/reducers/ingredient';
import { GetActionList, selectActions } from '../store/reducers/action';
//import { useCookies } from 'react-cookie';
import { useHistory } from 'react-router-dom';
import Link from '@material-ui/core/Link';
import Brand from '../components/brand/Brand';
import Button from '@material-ui/core/Button';
import HomeIcon from '@material-ui/icons/Home';
import LibraryAddIcon from '@material-ui/icons/LibraryAdd';
import DialogCreateReceipe from './dialog/CreateReceipe';

const drawerWidth = 240;
//Getting window dimensions
function getWindowDimensions() {
    const { innerWidth: width, innerHeight: height } = window;
    return {
        width,
        height
    };
}

function useWindowDimensions() {
    const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());

    useEffect(() => {
        function handleResize() {
            setWindowDimensions(getWindowDimensions());
        }

        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    return windowDimensions;
}




//const { heightViewport, widthViewport } = useWindowDimensions();

function AppStructure(props) {
    const classes = styles();
    let historynavigator = useHistory();                                                //Allow page change without re-rendering
    const token = useSelector(selectToken);

    const userEmail = useSelector(selectUserEmail);
    const userDetailError = useSelector(selectUserDetailError);
    const ingredients = useSelector(selectIngredients);
    const actions = useSelector(selectActions);


    const dispatch = useDispatch();
    const mydraftreceipe = useSelector(selectMyDraftReceipes);                          //State selectMyActiveDraftReceipe
    const [openDialogCreateReceipe, setOpenDialogCreateReceipe] = React.useState(false);
    // eslint-disable-next-line
    const { height, width } = useWindowDimensions();
    let consolidatedwidthpx = (width - drawerWidth) + 'px';
    //console.log("HEIGHT: ", height, " WIDTH: ", width, " WIDHTPX: ", consolidatedwidthpx);
    
    
    const handleClickOpenDialogCreateReceipe = () => {
        setOpenDialogCreateReceipe(true);
        //console.log("CLICK open !");
    };
    const handleClickCloseDialogCreateReceipe = () => {
        setOpenDialogCreateReceipe(false);
        //console.log("CLICK close !");
    };

    //FUNCTIONS
    //Click on Logout button to deconnect user and remove JWT token
    const HandleLogout = async () => {
        
        //await removeCookie("token");
        historynavigator.push("/");
        await LogOut(dispatch)
        
    }


    const LoadStartupData = async () => {
        //LOADING USER DETAILS IF TOKEN IS FOUND
        if (token && userEmail === "" && userDetailError === "") {
            //console.log("Loading user information from auth");
            await getUserInfo(dispatch, token);
            //console.log("User information loaded, can go to next step");
            //LOADING MY DRAFT RECEIPES IF LOGGEDIN
            //console.log("C EST ICI QU ON CHARGE LES INFOS");
            if (mydraftreceipe.length === 0) {
                //console.log("User information loaded, can go to next step");
                //console.log("Loading Draft Receipes");
                await GetMyReceipeDrafts(dispatch, token);

                if (ingredients.length === 0) {
                    //We do not know the existing ingredients
                    //We have to download them
                    await GetIngredientList(dispatch);
                }
                if (actions.length === 0) {
                    //We do not know the existing ingredients
                    //We have to download them
                    await GetActionList(dispatch);
                }


            }

        }
        else if (userDetailError !== "") {
            //HandleLogout();
            //setCookie('token', undefined, { path: "/", maxAge: 60 * 60 * 24 * 365, });
        }

    }


    
    //ReadLegalCookie(dispatch, cookieSetup, cookieAuthCookie, cookieIdpassreminder);
    //console.log("Cookie setup state: ", cookieSetup);
    //console.log("cookieAuthCookie state : ", cookieAuthCookie);
    /*
    //READ COOKIES AND ADAPT TO COOKIES
    if (cookieslegal.AuthCookie !== undefined && cookieslegal.AuthCookie !== "") { //If auth cookies is existing
        if (!cookieSetup) { //If cookie are not loaded in state - load them in state
            //console.log("Cookie exists - If state does not exist, update state");
            ReadLegalCookie(dispatch, cookieSetup, cookieAuthCookie, cookieIdpassreminder);
        }
    }
    */
    

    //Load startup data
    LoadStartupData();

    //ReadLegalCookie(dispatch, cookieSetup, cookieAuthCookie,cookieIdpassreminder); //Read legal cookies
    //LOADING OAUTH TOKEN
    /*
    if (cookies.token !== undefined && cookies.token !== "" && isLoggedin !== undefined) {
        if (cookies.token && token === undefined && isLoggedin === false) {
            //console.log("Cookie token found and token state is undefined");
            LoadToken(dispatch, cookies.token);
        }
    }
    */



    

    return (
        <Container className={classes.main}>
            <Container component='main' className={classes.containerXL}>
                <AppBar style={{ backgroundColor: 'white' }} className={classes.appBar} position="fixed" sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }}>
                    <Toolbar>
                        <Box align='left'>
                            <Link href="/"><Brand /></Link>
                        </Box>
                        <Box flexGrow={1}>
                            <Link variant="h6" color="textPrimary" href="/" className={classes.toolbarTitle}>Les Toqués</Link>
                        </Box>

                        <Box>
                            <Button onClick={() => { HandleLogout(); }} color="primary" variant="outlined" className={classes.link} style={{ maxHeight: "32px" }}>Me déconnecter</Button>
                        </Box>
                    </Toolbar>
                </AppBar>
                <Box sx={{ display: 'flex-grow:0' }}>
                    <Box sx={{ display: 'flex' }}>
                        <Drawer
                            variant="permanent"
                            sx={{
                                width: drawerWidth,
                                flexShrink: 0,
                                [`& .MuiDrawer-paper`]: { width: drawerWidth, boxSizing: 'border-box' },
                            }}
                        >
                            <Box sx={{ overflow: 'auto' }}>
                                <List style={{ marginTop: '55px' }}>
                                    <List>
                                        <ListItem button key="home">
                                            <ListItemIcon> <HomeIcon color="primary" /></ListItemIcon>
                                            <ListItemText primary={<Link variant="button" color="textPrimary" href="/" className={classes.toolbarTitle}>Accueil</Link>} />
                                        </ListItem>
                                        <ListItem button key="add_receipe">
                                            <ListItemIcon> <LibraryAddIcon color="primary" /></ListItemIcon>
                                            <ListItemText className={classes.toolbarTitle} onClick={handleClickOpenDialogCreateReceipe} primary={<Link variant="button" color="textPrimary" className={classes.toolbarTitle}>Créer recette</Link>} />
                                        </ListItem>
                                    </List>
                                    <DialogCreateReceipe open={openDialogCreateReceipe} onClose={handleClickCloseDialogCreateReceipe}></DialogCreateReceipe>  
                                </List>
                            </Box>
                        </Drawer>
                    </Box>
                    <Container maxWidth="lg" style={{ position: 'absolute', left: '240px', marginTop: '25px', width: consolidatedwidthpx }}>                
                        {props.children}
                    </Container>
                </Box>
            </Container>
        </Container>
    );

}

export default AppStructure;

/*
 * //SI9 BESOIN DE DIVIDER
 * <List>
                        {['All mail', 'Trash', 'Spam'].map((text, index) => (
                            <ListItem button key={text}>
                                <ListItemIcon>
                                    {index % 2 === 0 ? <InboxIcon /> : <MailIcon />}
                                </ListItemIcon>
                                <ListItemText primary={text} />
                            </ListItem>
                        ))}
                    </List>
*/

/*
 * {['Inbox', 'Starred', 'Send email', 'Drafts'].map((text, index) => (
                            <ListItem button key={text}>
                                <ListItemIcon>
                                    {index % 2 === 0 ? <InboxIcon /> : <MailIcon />}
                                </ListItemIcon>
                                <ListItemText primary={text} />
                            </ListItem>
                        ))}
                        <Divider />
 * */