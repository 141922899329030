/*######################################################
Creation Date: 02/03/2022
Author: Jean-Baptiste ROUGET
Updates:
# 02/03/2021: Creation
# 
######################################################*/
import React from 'react';
import Grid from '@material-ui/core/Grid';
//import styles from "../../theme/style";
import OutlinedInput from '@mui/material/OutlinedInput';
import InputAdornment from '@mui/material/InputAdornment';


function AdjustTemperatureActionUse(props) {
    //const classes = styles();
    const [valeur, setValeur] = React.useState('');
    //console.log("MATCHES: ", matches, " and initial orientation: ", orientation);

    //let urlimage = 'url("' + props.image + '")'
    //console.log("ORIENTATION: ", orientation);
    const handleChange = (event) => {
        console.log(event.target);
        
            if (event.target.value === "") {
                setValeur(0);
                props.updateaction(0);
            }
            else {
                setValeur(event.target.value);
                props.updateaction(event.target.value);
            }
            
     
        
    };

    return (


        <Grid id="actions_types" container spacing={2} alignItems="stretch" >
            <Grid item xs={4} sm={3} md={3} lg={3} xl={3}>
                
                <OutlinedInput
                    id="outlined-adornment-temperature"
                    value={valeur}
                    onChange={e => { handleChange(e) }}
                    endAdornment={<InputAdornment position="end">�C</InputAdornment>}

                />
            </Grid>
           

        </Grid>

    );

}

export default AdjustTemperatureActionUse;
