/*######################################################
Creation Date: 30/12/2021
Author: Jean-Baptiste ROUGET
Updates:
# 30/12/2021: Creation
# 
#
######################################################*/
import React from 'react';
import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';
import Button from '@material-ui/core/Button';
import { selectToken } from '../../store/reducers/authentication';
import { useDispatch, useSelector } from 'react-redux';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import Grid from '@material-ui/core/Grid';
import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
//import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import ListSubheader from '@mui/material/ListSubheader';
import { selectMyDraftReceipes, AddIngredientToReceipe } from '../../store/reducers/receipe';
import { selectIngredientTypes, selectIngredients, CreateIngredient, selectCreateIngredientError, DiscardIngredientCreationError } from '../../store/reducers/ingredient';
//import {  useHistory } from 'react-router-dom';

function DialogCreateReceipeIngredient(props) {
    //VARIABLES
    //const classes = styles();
    const dispatch = useDispatch();
    const { open, onClose } = props;//Define open and clase, we can send function from parent here
    const [actionMode, setActionMode] = React.useState('select'); //select mode or create mode
    const [currentName, setCurrentName] = React.useState(''); //state for name field
    //const [currentNombre, setCurrentNombre] = React.useState(''); //state for name field
    const ingredientTypes = useSelector(selectIngredientTypes);
    const ingredients = useSelector(selectIngredients);
    // eslint-disable-next-line
    const [currentType, setCurrentType] = React.useState(""); //state for name field
    const [currentQuantity, setCurrentQuantity] = React.useState(''); //state for quantity
    const [currentQuantityType, setCurrentQuantityType] = React.useState("unit"); //state for quantity
    const [selectedIndex, setSelectedIndex] = React.useState(0);
    const [selectedIngredientIndex, setSelectedIngredientIndex] = React.useState();
    const [selectedIngredientId, setSelectedIngredientId] = React.useState();

    const token = useSelector(selectToken);
    //const myActiveDraftReceipe = useSelector(selectMyActiveDraftReceipe);
    const myDraftReceipes = useSelector(selectMyDraftReceipes);
    const errorDuringIngredientCreation = useSelector(selectCreateIngredientError);
    //console.log("Y A T IL ERREUR PENDANT CREATION INGREDIENT : ", errorDuringIngredientCreation);
    const [readyToCreateIngredient, setReadyToCreateIngredient] = React.useState(false); //state for name field
    // eslint-disable-next-line
    const [readyToAddIngredient, setReadyToAddIngredient] = React.useState(false); //state for name field
    console.log("ID RECEIPE DU DIALOG : ", props.receipeId);
    let indexDraftReceipe = myDraftReceipes.findIndex(el => el._id === props.receipeId);
    //let indexDraftReceipe = 0;
    console.log("Index : ", indexDraftReceipe);
    //let historynavigator = useHistory();
    //FONCTIONS
    const cancel = () => {
        //setSelectedIndex();
        //setSelectedIngredientIndex();
        setReadyToCreateIngredient(false);
        onClose();
    }
    const returnToSelection = () => {
        setActionMode('select');
    }
    

    const handleNameChanged = (name) => {
        setCurrentName(name);
    }
    /*
    const handleTypeChanged = (type) => {
        setCurrentType(type);
    }
    */
    const handleQuantityChanged = (nombre) => {
        setCurrentQuantity(nombre);
    }
    const handleListItemClick = (event, index) => {
        
        setSelectedIndex(index);
       

    };
    const handleListIngredientClick = (event, index, id) => {
        //Hide the selection panels and show the system to update the counts
        setSelectedIngredientIndex(index);
        setSelectedIngredientId(id);
        DiscardIngredientCreationError(dispatch);
        setReadyToCreateIngredient(true);

    };
    const onChangeQuantityType = (event) => {
        setCurrentQuantityType(event.target.value);
    };
    

    const createNewReceipeIngredient = () => {
        
        if (currentQuantity !== undefined && currentQuantity !== 0) {
            if (currentQuantityType !== undefined) {
                console.log("Créer mon ingrédient: ", currentQuantity, " ", currentQuantityType, " of ingredient with ID : ", selectedIngredientId);

                AddIngredientToReceipe(dispatch, token, props.receipeId, selectedIngredientId, currentQuantity, currentQuantityType, indexDraftReceipe);
                setReadyToCreateIngredient(false);
                onClose();

                setCurrentQuantity("");
                setCurrentQuantityType("");
                //indexDraftReceipe




            }
        }
    }
    const addNewIngredient = () => {

        if (currentName !== undefined) {
            //selectedIndex = index of the types which is selected
            console.log("Ingredient type selected = ", ingredientTypes[selectedIndex].name);
            CreateIngredient(dispatch, token, currentName, ingredientTypes[selectedIndex].name); 
            returnToSelection();
        }
    }



    if (actionMode === "select") {
        if (readyToCreateIngredient) {
            return (
                <Dialog key='dialogcreateingredient' disableEnforceFocus open = { open } onClose = { onClose } aria-labelledby= "form-dialog-title" >
                        <DialogTitle id="form-dialog-title">Ajoutez un ingrédient à votre recette</DialogTitle>
                        <DialogContent>

                            {ingredients.filter(({ _id }) => _id === selectedIngredientId).map((ingredient) => ( 
                                <DialogContentText key='titelrefingredientname'>
                                    Veuillez indiquer la quantité nécessaire pour l'ingrédient:  {ingredient.name} 
                                </DialogContentText>
                            ))}
                            <Grid id="ingredients_types" container spacing={2} alignItems="stretch" >
                                <Grid item xs={12} sm={12} md={6} lg={6} xl={6} >
                                    <TextField
                                        margin="dense"
                                        id="quantity"
                                        label="Quantité"
                                        type="text"
                                        fullWidth
                                        onChange={e => { handleQuantityChanged(e.target.value) }}
                                        value={currentQuantity}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={12} md={6} lg={6} xl={6} >
                                    <FormControl variant="standard" sx={{ m: 1, minWidth: 120 }}>
                                        <InputLabel id="demo-simple-select-standard-label">Unités</InputLabel>
                                        <Select
                                            labelId="demo-simple-select-standard-label"
                                            id="demo-simple-select-standard"
                                            value={currentQuantityType}
                                            onChange={onChangeQuantityType}
                                            label="Age"
                                    >     
                                            <MenuItem key='1' value="unit">unité</MenuItem>
                                            <MenuItem key='2' value="g">g</MenuItem>
                                            <MenuItem key='3' value="kg">kg</MenuItem>
                                            <MenuItem key='4' value="cL">cL</MenuItem>
                                            <MenuItem key='5' value="dL">dL</MenuItem>
                                            <MenuItem key='6' value="L">L</MenuItem>
                                            <MenuItem key='7' value="pinch">pincée</MenuItem>
                                            <MenuItem key='8' value="table_spoon">cuillère à soupe</MenuItem>
                                            <MenuItem key='9' value="tea_spoon">cuillère à café</MenuItem>
                                            <MenuItem key='10' value="slice">tranche</MenuItem>
                                            
                                        </Select>
                                    </FormControl>
                                </Grid>
                            </Grid>
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={cancel} color="secondary">
                                Annuler
                            </Button>
                            <Button onClick={() => { createNewReceipeIngredient() }} color="primary">
                                Enregistrer
                            </Button>
                        </DialogActions>
                   
                </Dialog>
                
                
                );
        }
        else {
            return (
                <Dialog open={open} onClose={onClose} aria-labelledby="form-dialog-title" >
                    <DialogTitle id="form-dialog-title">Ajoutez un ingrédient à votre recette</DialogTitle>
                    <DialogContent>
                        <DialogContentText>
                            Veuillez sélectioner votre ingrédient dans la liste selon le type d'ingrédients.
                            </DialogContentText>
                        {errorDuringIngredientCreation ? <Alert severity="error" action={<Button onClick={(event) => DiscardIngredientCreationError(dispatch)}>Effacer</Button>}><AlertTitle>Erreur</AlertTitle>Votre ingrédient n'a pas été créé car est déjà existant. <strong>Veuillez utiliser l'ingrédient existant</strong> </Alert> : <></>}
                        <Grid id="ingredients_types" container spacing={2} alignItems="stretch" >
                            <Grid item xs={12} sm={12} md={6} lg={6} xl={6} >
                                <List component="nav" aria-label="ingredient type list" subheader={<ListSubheader>Type</ListSubheader>}>
                                    {ingredientTypes.map((types, index) => (
                                        <ListItemButton key={types.name + types.index}
                                            selected={selectedIndex === index}
                                            onClick={(event) => handleListItemClick(event, index)}
                                        >
                                            <ListItemText primary={types.description} />
                                        </ListItemButton>
                                    ))}
                                </List>
                            </Grid>
                            <Grid item xs={12} sm={12} md={6} lg={6} xl={6} >
                                <List component="nav" aria-label="ingredient name list" subheader={<ListSubheader>Ingredient</ListSubheader>}>
                                    {ingredients.filter(({ type }) => type === ingredientTypes[selectedIndex].name).map((ingredient, index) => (
                                        <ListItemButton key={ingredient._id}
                                            selected={selectedIngredientIndex === index}
                                            onClick={(event) => handleListIngredientClick(event, index, ingredient._id)}
                                        >
                                            <ListItemText primary={ingredient.name} />
                                        </ListItemButton>
                                    ))}
                                    <ListItemButton key='but'>
                                        <Button onClick={() => {  setActionMode("create") }} color="primary">
                                            Ajouter ingrédient
                                        </Button>
                                    </ListItemButton>
                                </List>
                            </Grid>
                        </Grid>
                        
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={cancel} color="secondary">
                            Annuler
                            </Button>
                        {readyToAddIngredient ?
                            <Button onClick={console.log("click")} color="primary">
                                Continuer
                                </Button> : <></>}
                    </DialogActions>
                </Dialog>
                
                );

        }
        
        
    }
    else {
        //Mode: create a new ingredient
        return (
            <Dialog open={open} onClose={onClose} aria-labelledby="form-dialog-title">

                <DialogTitle id="form-dialog-title">Ajoutez un ingrédient à votre recette</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Veuillez indiquer le nom de votre ingrédient. Par exemple : "Jambon de pays", "Sel", "filet de flétan"
                </DialogContentText>
                    
                    <TextField
                        margin="dense"
                        id="name"
                        label="Nom de l'ingrédient"
                        type="text"
                        fullWidth
                        onChange={e => { handleNameChanged(e.target.value) }}
                        value={currentName}
                    />

                </DialogContent>
                <DialogActions>
                    <Button onClick={returnToSelection} color="secondary">
                        Retour à selection des ingrédients
                </Button>
                    <Button onClick={() => { addNewIngredient(); }} color="primary">
                        Créer un ingrédient manquant
                </Button>
                </DialogActions>
            </Dialog>
        );

    }

    
}


export default DialogCreateReceipeIngredient;
