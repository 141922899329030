/*######################################################
Creation Date: 14/07/2021
Author: Jean-Baptiste ROUGET
Updates:
# 13/11/2021: Creation
# 30/12/2021: Creation of the logic show / edit, and associated APIs handling
#
######################################################*/
import React from 'react';
//import AppStructure from '../../components/AppStructure';
import DraftTextEdit from '../../components/receipe/draftTextEdit';
import Container from '@material-ui/core/Container';
//import CardReceipe from '../../components/receipe/card';
//import Typography from '@material-ui/core/Typography';
//import TextField from '@mui/material/TextField';
//import IconButton from '@mui/material/IconButton';
//import ModeEditOutlineOutlinedIcon from '@mui/icons-material/ModeEditOutlineOutlined';
//import SaveOutlinedIcon from '@mui/icons-material/SaveOutlined';
//import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';
import { useSelector } from 'react-redux';
import { selectMyDraftReceipes } from '../../store/reducers/receipe';
import { selectTempIngredientMap } from '../../store/reducers/action';
//import Button from '@material-ui/core/Button';
//import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
//import Link from '@material-ui/core/Link';
//import StarOutlined from '@material-ui/icons/StarOutlined';
//import StarHalfOutlined from '@material-ui/icons/StarHalfOutlined';
//import StarBorderOutlined from '@material-ui/icons/StarBorderOutlined';
//import receipe_placeholder from '../../assets/images/placeholder-recette.jpg';
//import useMediaQuery from '@material-ui/core/useMediaQuery';
//import theme from '../../theme/lightTheme';
//import styles from "../../theme/style";


function EditDraft(props) {
    //const classes = styles();
    const stateRemainingIngredients = useSelector(selectTempIngredientMap);
    const myDraftReceipes = useSelector(selectMyDraftReceipes);
    //Find index of the receipe in the state
    let index = myDraftReceipes.findIndex(el => el.slug === props.match.params.receipe_slug);
    //Get the receipe draft object
    let myobject = myDraftReceipes.find(el => el.slug === props.match.params.receipe_slug);

    React.useEffect(() => {
        //console.log("States changed: ",isEmailValid, isPasswordValid ,isPasswordValid2 );
        //console.log("TOKEN STATE HAS CHANGED: ", token)
        //remainingIngredients = stateRemainingIngredients;
        console.log("remainingIngredients are now updated with the latest version EDIT DRAFT");

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [stateRemainingIngredients]);
    /*
    React.useEffect(() => {
        //console.log("Component did mount - Set up tasks after mount");
        
        //console.log("Mode set to ", editMode);
        //console.log("Receipe slug: ", props.match.params.receipe_slug);
        //checkButtonInactivity();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }
    );
    */
    //Find the right receipe in the state
    //find the slug from url
    //console.log(props.match.params.receipe_slug);
    //find the draft in state
   
    //console.log(index);
    //console.log("draft list: ", myDraftReceipes);
    //console.log(myDraftReceipes[index]);
    //console.log("myObject : ", myobject);
   
    //let myjson = JSON.parse(myDraftReceipes);
    //console.log(myDraftReceipes[index][0]);
    //console.log(myobject);
    //let myjson = JSON.stringify(myobject);
    //console.log(myjson);
    //console.log(myobject['name']);
    //Object.keys(myDraftReceipes[index]);
    //let tempDraftInfo = myDraftReceipes[index];
    //console.log(typeof myDraftReceipes[index]);
    //console.log(myDraftReceipes[index].keys('name'));

    //<Typography>{myobject['creationDateTime']}</Typography>
    //console.log("Keys : ", myobject.keys());
    //<Typography>{'creationDateTime' in myobject}</Typography>
    if (myobject === undefined) {
        //console.log("OBJET UNDEFINED"); WE NEED TO WAIT UNTIL STATE IS UP TO DATE -> 
        return (




            < Container maxWidth='lg' style={{ marginBottom: "60px" }}><></></Container>
        )
    }

    return (

      


        < Container maxWidth='lg' style={{ marginBottom: "60px" }}>
            <Grid id="draft_receipe_information" container spacing={2} alignItems="stretch" style={{ marginBottom: "60px" }}>
                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                    <DraftTextEdit receipe_draft={myobject} index={index} field="name" type="title" />
                </Grid>
                <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                    <DraftTextEdit receipe_draft={myobject} index={index} title="Description" field="description" type="text" />
                </Grid>
                <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                    <DraftTextEdit receipe_draft={myobject} index={index} title="Nombre de personnes" field="people_per_plate" type="text" />
                </Grid>
                <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                    <DraftTextEdit receipe_draft={myobject} index={index} title="Ingrédients" field="ingredients" type="list" />
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                    <DraftTextEdit receipe_draft={myobject} index={index} title="Préparation" field="actions" type="actionlist" />
                </Grid>
            


           
             
            </Grid>
        </Container>






            


        
       
    );

}

export default EditDraft;
