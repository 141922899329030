import React from 'react';
import Button from '@material-ui/core/Button';
import Alert from '@material-ui/lab/Alert';
import TextField from '@material-ui/core/TextField';
import CircularProgress from '@material-ui/core/CircularProgress';
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';
import styles from '../../theme/style';
import { useDispatch, useSelector } from 'react-redux';
import { useCookies } from "react-cookie";
import { selectForgottenPasswordError, forgottenPasswordUpdate,  selectIsLoggedIn, selectToken, forgottenPasswordCodeRequest, selectForgottenPasswordCodeError, selectForgottenPasswordCodeLoading, selectForgottenPasswordEmailSent, selectForgottenPasswordLoading } from '../../store/reducers/authentication';
import {  useHistory } from 'react-router-dom';

const ForgottenPassword = (props) => {
    const dispatch = useDispatch();
    const [isSaveButtonInactive, setSaveButtonActivity] = React.useState(true);
    const [isPasswordValid, setPasswordValidity] = React.useState(true);
    const [isPasswordValid2, setPasswordValidity2] = React.useState(true);
    // eslint-disable-next-line
    const [cookies, setCookie] = useCookies(["user"]);
    const [currentCode, setCurrentCode] = React.useState('');
    const isCodeLoading = useSelector(selectForgottenPasswordCodeLoading);
    const isCodeError = useSelector(selectForgottenPasswordCodeError);
    const isForgottenPasswordModifyError = useSelector(selectForgottenPasswordError);
    const isForgottenPasswordLoading  = useSelector(selectForgottenPasswordLoading);
    const isMailSent = useSelector(selectForgottenPasswordEmailSent);
    // eslint-disable-next-line
    const [isEmailValid, setEmailValidity] = React.useState(true);
    const [currentEmail, setCurrentEmail] = React.useState('');
    let historynavigator = useHistory();
    const checkButtonInactivity = () => {
        //console.log("ITEM: ", isPasswordValid, isEmailValid);
        if (isPasswordValid && isPasswordValid2 ){
            //console.log("bouton valid: ", termsChecked, isPasswordValid, isPasswordValid2, isEmailValid );
            setSaveButtonActivity(false);
        }
        else {
            //console.log("bouton invalid: ", termsChecked, isPasswordValid, isPasswordValid2, isEmailValid);
            setSaveButtonActivity(true);
        }
    }

    React.useEffect(() => {
        //console.log("States changed: ",isEmailValid, isPasswordValid ,isPasswordValid2 );
        checkButtonInactivity();
        // eslint-disable-next-line react-hooks/exhaustive-deps
        }, [isPasswordValid, isPasswordValid2]
    );

    const handleEmailChanged = async (newValue) => {
        //CHECK VALIDITY EMAIL - TO BE DONE
        // eslint-disable-next-line
        if (/^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/.test(newValue) === false) {
            //console.log("Email invalid: ", newValue);
            setEmailValidity(false);
        }
        else {
            console.log("Email valid: ", newValue);
            setEmailValidity(true);
        }
        setCurrentEmail(newValue);
        //checkButtonInactivity();
    }

    const [currentPassword, setCurrentPassword] = React.useState('');

    const [passwordValidationError, setPasswordValidationError] = React.useState('Doit contenir au moins 8 caractères, une majuscule, une minuscule, un chiffre et un caractère spécial')
    const handlePasswordChanged = (newValue) => {
        if (/^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[a-zA-Z])(?=.*[&@$*=+?/!%_\-#]).{8,}$/.test(newValue) === false) {
            //console.log("Password is not valid: ", newValue);
            setPasswordValidity(false);
            if (newValue.length < 8){
                setPasswordValidationError('Doit contenir au moins 8 caractères');
                setPasswordValidity(false);

            }
            else if (/^(?=.*[a-z])(?=.*[A-Z])(?=.*[a-zA-Z]).{8,}$/.test(newValue) === false){
                //(/^(?=.*\d)(?=.*[&@$*=+?/!%_\-#])$/.test(newValue) === false)
                setPasswordValidationError('Doit contenir au moins une majuscule et une minuscule')
                setPasswordValidity(false);
            }
            else if (/^(?=.*[&@$*=+?/!%_\-#]).{8,}$/.test(newValue) === false) {
                setPasswordValidationError('Doit contenir au moins un caractère spécial ( & @ $ * = + ? ! % _ - #)');
                setPasswordValidity(false);

            }
            else if (/^(?=.*\d).{8,}$/.test(newValue) === false) {
                setPasswordValidationError('Doit contenir au moins un chiffre');
                setPasswordValidity(false);
            }
        }
        else {
            //console.log("Password is valid: ", newValue)
            setPasswordValidationError('Le mot de passe est valide');
            setPasswordValidity(true);
        }
        setCurrentPassword(newValue);
        //checkButtonInactivity();
    }

    //PASSWORD2
    const [currentPassword2, setCurrentPassword2] = React.useState('');

    const [passwordValidationError2, setPasswordValidationError2] = React.useState('Doit contenir au moins 8 caractères, une majuscule, une minuscule, un chiffre et un caractère spécial')
    const handlePasswordChanged2 = (newValue) => {
        if (/^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[a-zA-Z])(?=.*[&@$*=+?/!%_\-#]).{8,}$/.test(newValue) === false) {
            //console.log("Password is not valid: ", newValue);
            setPasswordValidity2(false);
            if (newValue.length < 8){
                setPasswordValidationError2('Doit contenir au moins 8 caractères');
                setPasswordValidity2(false);
            }
            else if (/^(?=.*[a-z])(?=.*[A-Z])(?=.*[a-zA-Z]).{8,}$/.test(newValue) === false){
                //(/^(?=.*\d)(?=.*[&@$*=+?/!%_\-#])$/.test(newValue) === false)
                setPasswordValidationError2('Doit contenir au moins une majuscule et une minuscule')
                setPasswordValidity2(false);
            }
            else if (/^(?=.*[&@$*=+?/!%_\-#]).{8,}$/.test(newValue) === false) {
                setPasswordValidationError2('Doit contenir au moins un caractère spécial ( & @ $ * = + ? ! % _ - #)');
                setPasswordValidity2(false);
            }
            else if (/^(?=.*\d).{8,}$/.test(newValue) === false) {
                setPasswordValidationError2('Doit contenir au moins un chiffre');
                setPasswordValidity2(false);
            }
        }
        else if (currentPassword !== newValue){
            setPasswordValidationError2('Les mots de passe ne sont pas identiques');
            setPasswordValidity2(false);
        }
        else {
            //console.log("Password is valid: ", newValue)
            setPasswordValidationError2('Le mot de passe est valide');
            setPasswordValidity2(true);
        }
        setCurrentPassword2(newValue);
        //checkButtonInactivity();
    }



    const handleCodeChanged = async (newValue) => {
        setCurrentCode(newValue);
    }



    const isLoggedin = useSelector(selectIsLoggedIn);
    const token = useSelector(selectToken);

    //const [cookies, setCookie] = useCookies(["user"]);

    React.useEffect(() => {
        //console.log("States changed: ",isEmailValid, isPasswordValid ,isPasswordValid2 );
        //console.log("TOKEN STATE HAS CHANGED: ", token)
        if (token !== "" && token !== undefined && isLoggedin===true){
            setCookie("token", token, {
                path: "/",
                maxAge: 60*60*24*30,
            });
            //history.push("/dashboard");
            historynavigator.push("/");
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [token, isLoggedin]);


    const HandleSaveNewPassword = () => {
        //console.log("CLICK SUR Enregistrer le mot de passe");
        //LoginCustom(dispatch, currentEmail, currentPassword, "sotasty web");
        forgottenPasswordUpdate(dispatch, historynavigator, currentEmail, currentPassword, currentCode);
    };

    const HandleRequestCode = () => {
        //console.log("CLICK SUR Envoyer le code");
        forgottenPasswordCodeRequest(dispatch, currentEmail)
    };

    // eslint-disable-next-line
    const refreshTokenSetup = async (res) => {
        // Timing to renew access token
        let refreshTiming = (res.tokenObj.expires_in || 3600 - 5 * 60) * 1000;

        const refreshToken = async () => {
            const newAuthRes = await res.reloadAuthResponse();
            refreshTiming = (newAuthRes.expires_in || 3600 - 5 * 60) * 1000;
            //console.log('newAuthRes:', newAuthRes);
            setTimeout(refreshToken, refreshTiming);
        };

        // Setup first refresh timer
        setTimeout(refreshToken, refreshTiming);
    };


    const classes = styles();
    //const isCodeLoading = useSelector(forgottenPasswordCodeLoading);
    //const isCodeError = useSelector(forgottenPasswordCodeError);

    if (isMailSent === false){
        return(
            <div className={classes.main} >
                <Container component="main" maxWidth="xs" className={classes.main} style={{paddingTop:'60px'}}>
                    <Container style={{textAlign:'center'}}>
                        <Typography component="h1" variant="h5">Reinitialisation du mot de passe</Typography>
                        {isCodeError ? <Alert severity="error">Une erreur est survenue. Veuillez vérifier votre email.</Alert>:<></>}
                        {isForgottenPasswordModifyError ? <Alert severity="error">Une erreur est survenue. Veuillez recommencer</Alert>:<></>}

                        <TextField
                            variant="outlined"
                            margin="normal"
                            required
                            fullWidth
                            id="email"
                            label="Email"
                            name="email"
                            autoComplete="Email"
                            onChange={e => {console.log("TEXT: ", e);handleEmailChanged(e.target.value)}}
                            value={currentEmail}
                            autoFocus
                        />
                        <Button
                            type="submit"
                            fullWidth
                            variant="outlined"
                            color="primary" 
                            className={classes.link} style={{maxHeight:"32px", marginBottom:'20px'}}
                            onClick={()=>{HandleRequestCode()}}
                        >
                        {isCodeLoading && <CircularProgress />}Recevoir mon code
                      </Button>
                  </Container>
              </Container>
          </div>

        );



    }
    else {
        return(
            <div className={classes.main} >
                <Container component="main" maxWidth="xs" className={classes.main} style={{paddingTop:'60px'}}>
                    <Container style={{textAlign:'center'}}>
                        <Typography component="h1" variant="h5">Reinitialisation du mot de passe</Typography>
                    </Container>
                    <Alert severity="success">
                        Un email contenant un <b>code unique</b> de réinitialisation de votre mot de passe vient de vous être envoyé. Ce code ne sera actif que durant <b>10 minutes</b>. Veuillez remplir le
                        formulaire ci-dessous pour modifier votre mot de passe.
                    </Alert>
                    <TextField
                        variant="outlined"
                        margin="normal"
                        required
                        fullWidth
                        id="confirmationcode"
                        label="Confirmation code"
                        name="code"
                        autoComplete="Confirmation code"
                        onChange={e => {handleCodeChanged(e.target.value)}}
                        value={currentCode}
                        autoFocus
                    />
                    <TextField
                        variant="outlined"
                        required
                        fullWidth
                        name="password"
                        label="Mot de passe"
                        value={currentPassword}
                        onChange={e => {handlePasswordChanged(e.target.value)}}
                        helperText={passwordValidationError}
                        error={isPasswordValid ? false:true}
                        type="password"
                        id="password"
                        autoComplete="current-password"
                    />
                    <TextField
                        variant="outlined"
                        required
                        fullWidth
                        name="password2"
                        label="Confirmez le mot de passe"
                        value={currentPassword2}
                        onChange={e => {handlePasswordChanged2(e.target.value)}}
                        helperText={passwordValidationError2}
                        error={isPasswordValid2 ? false:true}
                        type="password"
                        id="password2"
                        autoComplete="current-password2"
                    />
                    <Button
                        type="submit"
                        fullWidth
                        variant="outlined"
                        color="primary" 
                        className={classes.link} style={{maxHeight:"32px", marginBottom:'20px'}}
                        onClick={()=>{HandleSaveNewPassword()}}
                        disabled={isSaveButtonInactive}
                    >{isForgottenPasswordLoading && <CircularProgress />}Enregistrer le nouveau mot de passe</Button>
                </Container>
            </div>
        )
    }
}

export default ForgottenPassword;
/*
function mapStateToProps({ authentication }) {
  return { authentication };

}

export default connect(mapStateToProps) (LogIn);
*/
