/*######################################################
Creation Date: 01/01/2021
Author: Jean-Baptiste ROUGET
Definition: Page to view a receipe
Updates:
# 11/01/2021: Creation
# 26/08/2021: Refining code, adding comprehensive comments, decreasing file size
#
######################################################*/
import React from 'react';
import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';
//import styles from '../../theme/style';
import { useDispatch, useSelector } from 'react-redux';
import List from '@mui/material/List';
//import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import ListSubheader from '@mui/material/ListSubheader';
import ListItemButton from '@mui/material/ListItemButton';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
//import FormHelperText from '@mui/material/FormHelperText';
//import FormControl from '@mui/material/FormControl';
import Switch from '@mui/material/Switch';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
//import Chip from '@mui/material/Chip';
//import Stack from '@mui/material/Stack';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
//import {  useHistory } from 'react-router-dom';
//import { selectIsLoggedIn, } from '../../store/reducers/authentication';
import { selectMyDraftReceipes, AddActionToReceipe } from '../../store/reducers/receipe';
//import CardReceipe from '../../components/receipe/card';
//import AppStructure from '../../components/AppStructure';
import { selectActionTypes, selectActions, CreateAction, selectCreateActionError, DiscardActionCreationError } from '../../store/reducers/action';
import { selectToken } from '../../store/reducers/authentication';
import { useHistory } from 'react-router-dom';
import AdjustIngredientActionUse from '../../components/library/AdjustIngredientActionUse';
import AdjustTemperatureActionUse from '../../components/library/AdjustTemperatureActionUse';
import AdjustDurationActionUse from '../../components/library/AdjustDurationActionUse';
//import OutlinedInput from '@mui/material/OutlinedInput';
//import InputAdornment from '@mui/material/InputAdornment';

const CreateActionInReceipe = (props) => {
    //if (!props.ingredient) { console.log("Pas de props") };
    //const classes = styles();
    const dispatch = useDispatch();
    let historynavigator = useHistory();
    const [open, setOpen] = React.useState(false);
    const [possibleToCreateAction, setPossibleToCreateAction] = React.useState(false);
    //let historynavigator = useHistory();
    const myDraftReceipes = useSelector(selectMyDraftReceipes);
    //Find index of the receipe in the state
    //Si slug fini par /action; l enlever
    let receipe_slug_var = props.match.params.receipe_slug;
    const errorDuringActionCreation = useSelector(selectCreateActionError);


    let index = myDraftReceipes.findIndex(el => el.slug === receipe_slug_var);
    //Get the receipe draft object
    let myreceipe = myDraftReceipes.find(el => el.slug === receipe_slug_var);
    const token = useSelector(selectToken);
    //const loggedin =  useSelector(selectIsLoggedIn);
    //const stateRemainingIngredients = useSelector(selectTempIngredientMap);
    const [actionMode, setActionMode] = React.useState('select'); //select mode or create mode
    const actionTypes = useSelector(selectActionTypes);
    const actions = useSelector(selectActions);
    const [selectedIndex, setSelectedIndex] = React.useState(0);
    const [selectedActionIndex, setSelectedActionIndex] = React.useState();
    // eslint-disable-next-line
    const [selectedActionId, setSelectedActionId] = React.useState();
    // eslint-disable-next-line
    const [readyToCreateAction, setReadyToCreateAction] = React.useState(false); //state for name field
    // eslint-disable-next-line
    const [readyToAddIngredient, setReadyToAddIngredient] = React.useState(false); //state for name field
    const [ingredient_needed, setIngredient_needed] = React.useState(false);
    const [temperature_needed, setTemperature_needed] = React.useState(false);
    const [duration_needed, setDuration_needed] = React.useState(false); //state for quantity
    const [currentName, setCurrentName] = React.useState(''); //state for name field
    //const [temperature, setTemperature] = React.useState(''); //state for name field

    //DEFINITION DES INGREDIENTS RESTANTS INUTILISES
    let remainingIngredients = [];
    let temperature = "";
    let duration = "";
    const calculRemainingIngredients = () => {
        remainingIngredients = [];//We empty it
        console.log("Calcul Remaining ingredients");
        //Look for each ingredient
        try {
            myreceipe.ingredients.forEach(function (value) {
                console.log("Value index: ", value);
                let temp_quantity = value.unitPerMetric;
                //Look for each action if the ingredient is linked
                myreceipe.process.forEach(function (valueprocess) {
                    //console.log("Valueprocess: ", valueprocess);
                    valueprocess.ingredient.forEach(function (theingred) {
                        //console.log("theingred : ", theingred.ingredient);
                        //console.log("value._id : ", value._id);
                        if (theingred.ingredient === value._id) {
                            //console.log("MATCH : ", temp_quantity, " | ", theingred.quantity_count);
                            temp_quantity = temp_quantity - theingred.quantity_count;
                        }
                    });
                    



                });
                if (temp_quantity != 0) {
                    //Add to the list only ingredients with available quantity different than 0
                    console.log(" !!! VALUE !!! ", value);
                    if (value.temporary === true) {
                        remainingIngredients.push({ id: value._id, name: value.tempname, quantity: temp_quantity, metric: value.metric, selected: 0 });

                    }
                    else {
                        remainingIngredients.push({ id: value._id, name: value.ingredient.name, quantity: temp_quantity, metric: value.metric, selected: 0 });
                    }
                    
                }
                

            });



            console.log("remainingIngredients: ", remainingIngredients);
        }
        catch {
            historynavigator.push("/");
        }
    }

    const updateRemainingIngredients = (index, selected) => {
        remainingIngredients[index].selected = selected;
        console.log("remainingIngredients: ", remainingIngredients);
    }

    if (remainingIngredients.length === 0) {

        console.log("1-CALCUL REMAINING INGREDIENTS");
        calculRemainingIngredients();
    }
   /* if (stateRemainingIngredients.length === 0) {
        if (remainingIngredients.length === 0) {
            console.log("1-CALCUL REMAINING INGREDIENTS");
            calculRemainingIngredients();
            console.log("REMAINING INGREDIENT TABLE : ", remainingIngredients);
            UpdateTempIngredientMap(dispatch, remainingIngredients);
            //setStateRemainingIngredients(remainingIngredients);
        }


    }
    else {
        console.log("2-STATE REMAINING INGREDIENTS ALREADY FILLED, COPYING");
        remainingIngredients = stateRemainingIngredients;
        console.log("REMAINING INGREDIENT TABLE FROM STATE : ", remainingIngredients);
    }
    */
    

    const handleListItemClick = (event, index) => {
        console.log("ITEM SELECTIONNE : ", index);
        setSelectedIndex(index);

    };
    const handleListActionClick = (event, index, id) => {
        //Hide the selection panels and show the system to update the counts
        setSelectedActionId(id);
        console.log("ITEM SELECTIONNE CONFIRMED ID: ", id);
        //Find the index of this action id
        for (let index = 0; index < actions.length; index++)
        {
            //console.log("theingred : ", theingred.ingredient);
            //console.log("value._id : ", value._id);
            if (actions[index]._id === id) {
                //console.log("MATCH : ", temp_quantity, " | ", theingred.quantity_count);
                setSelectedActionIndex(index);
                
            }
        };
        //console.log("ITEM SELECTIONNE CONFIRMED INDEX: ", index);
        //setSelectedActionIndex(index);
        
        
        DiscardActionCreationError(dispatch);
        //setReadyToCreateAction(true);
        setActionMode("create");


    };
    const cancel = () => {
        //setSelectedIndex();
        //setSelectedIngredientIndex();
        setReadyToCreateAction(false);
        let url =  "/receipe/draft/" + props.match.params.receipe_slug;
        historynavigator.push(url);
        //onClose();
    }
    const checkIfPossibleToCreateAction = () => {
        //console.log(duration_needed, setTemperature_needed, ingredient_needed);
        if (duration_needed === false && temperature_needed === false && ingredient_needed === false) {
            setPossibleToCreateAction(false);
        }
        else {
            setPossibleToCreateAction(true);
        }
    }
    const handleNameChanged = (name) => {
        setCurrentName(name);
    }
    const handleTemperatureChanged = (temp) => {
        temperature = temp;
        console.log("Temperature : ", temperature);
    }
    const handleDurationChanged = (temp) => {
        duration = temp;
        console.log("Duration : ", duration);
    }

    const handleChangeIngredient = (nombre) => {
        if (ingredient_needed === true) {
            setIngredient_needed(false);
        }
        else {
            setIngredient_needed(true);
        }
        //checkIfPossibleToCreateAction();
    }
    const handleChangeTemperature = (nombre) => {
        if (temperature_needed === true) {
            setTemperature_needed(false);
        }
        else {
            setTemperature_needed(true);
        }
        //checkIfPossibleToCreateAction();
    }
    const handleChangeDuration = (nombre) => {
        if (duration_needed === true) {
            setDuration_needed(false);
        }
        else {
            setDuration_needed(true);
        }
        //checkIfPossibleToCreateAction();
    }

    React.useEffect(() => {
        //console.log("States changed: ",isEmailValid, isPasswordValid ,isPasswordValid2 );
        checkIfPossibleToCreateAction();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [duration_needed, ingredient_needed, temperature_needed ]
    );

    
    /*
    const returnToSelection = () => {
        setActionMode('select');
    }
    */
    const addNewAction = () => {

        if (currentName !== undefined) {
            
            CreateAction(dispatch, token, currentName, ingredient_needed, duration_needed, temperature_needed, actionTypes[selectedIndex].name);
            setCurrentName("");
            setIngredient_needed(false);
            setDuration_needed(false);
            setTemperature_needed(false);
            setOpen(false);
        }
    }

    const SaveNewAction = () => {

        console.log("Enregistrement de l'action dans la recette");
        let tableofingredients = '{"ingredients" : '  + JSON.stringify(remainingIngredients) + "}";
        console.log("Table JSON des Ingredients : ", tableofingredients);
        AddActionToReceipe(dispatch, token, myreceipe._id, actions[selectedActionIndex]._id, temperature, duration, remainingIngredients, index);
        cancel();
        //DISPATCH AU STORE



    }

    const handleClose = () => {
        setOpen(false);
    };
    /*
    const HandleClickChip = (id) => {
        console.log("Click on Chip index ", id);
        //console.log("Remaining ingredient table: ", remainingIngredients);
        // METTRE TOUT CA SUR LE STATE ET PAS SUR L INDEX !!!!!!
        for (let i = 0; i < remainingIngredients.length; i++) {
            if (remainingIngredients[i].id === id) {
                if (remainingIngredients[i].selected) {
                    remainingIngredients[i].selected = false;
                }
                else {
                    remainingIngredients[i].selected = true;
                }
            }
        }
       

        console.log("RemainingIngredientsUpdated : ", remainingIngredients);
        UpdateTempIngredientMap(dispatch, remainingIngredients);
        //onClose();
        //open();
        //setStateRemainingIngredients(remainingIngredients);
        console.log("RemainingIngredientsUpdated STATE: ", stateRemainingIngredients);
        setActionMode("select");
        setActionMode("create");
        //useForceUpdate();
        //React.useCallback(() => updateState({}), []);
    };
    */
    /*
    const createUnselectedChip = (item, index) => {
        console.log("State REMAINGING CONTEN createUnselectedChip: ", item.name, item.selected);
        if (item.selected === false) {
            return (<Chip key={item._id} label={item.name} variant="outlined" onClick={() => { HandleClickChip(index) }} />)
        }
        else {
            console.log("Ingredient ", item.name, " not created");
        }
    }
    */
    if (actionMode === "select") {
        return (

            < Container maxWidth='lg' style={{ marginBottom: "60px" }}>
                <Container maxWidth="xl" component="main">
                    <Typography variant="h4" align="left">Ajouter une action à votre recette</Typography>
                    <Grid id="values" container spacing={2} alignItems="stretch" style={{ marginBottom: "60px" }}>
                        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                            <Typography>Veuillez sélectioner votre action dans la liste selon le type d'action.</Typography>
                        </Grid>
                        {errorDuringActionCreation ? <Alert severity="error" action={<Button onClick={(event) => DiscardActionCreationError(dispatch)}>Effacer</Button>}><AlertTitle>Erreur</AlertTitle>Votre action n'a pas été créé car est déjà existante. <strong>Veuillez utiliser l'action existante</strong> </Alert> : <></>}

                        
                            <Grid item xs={6} sm={6} md={6} lg={6} xl={6} >
                                <List component="nav" aria-label="action type list" subheader={<ListSubheader>Type</ListSubheader>}>
                                    {actionTypes.map((types, index) => (
                                        <ListItemButton key={types.name}
                                            selected={selectedIndex === index}
                                            onClick={(event) => handleListItemClick(event, index)}
                                        >
                                            <ListItemText primary={types.description} />
                                        </ListItemButton>
                                    ))}
                                </List>
                            </Grid>
                            <Grid item xs={6} sm={6} md={6} lg={6} xl={6} >
                                <List component="nav" aria-label="ingredient name list" subheader={<ListSubheader>Action</ListSubheader>}>
                                    {actions.filter(({ type }) => type === actionTypes[selectedIndex].name).map((action, index) => (
                                        <ListItemButton key={action._id}
                                            selected={selectedActionIndex === index}
                                            onClick={(event) => handleListActionClick(event, index, action._id)}
                                        >
                                            <ListItemText primary={action.name} />
                                        </ListItemButton>
                                    ))}
                                    <ListItemButton key='but'>
                                    <Button onClick={() => { setOpen(true); }} color="primary">
                                            Ajouter une action
                                        </Button>
                                    </ListItemButton>
                                </List>
                            </Grid>
                        
                        
                            
                           
                            
                                <Button onClick={cancel} color="secondary">
                                    Annuler
                            </Button>
                                {readyToAddIngredient ?
                                    <Button onClick={console.log("click")} color="primary">
                                        Continuer
                                </Button> : <></>}
                           
                        














                        

                    </Grid>



                </Container>
                <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
                    <DialogTitle id="form-dialog-title">Ajoutez une action à la liste</DialogTitle>
                    <DialogContent>
                        <DialogContentText>
                            Veuillez indiquer l'action à créer. Par exemple : "Couper en julienne", "Mettre au four", "Mettre dans une poile", "Cuire à feu doux".
                        </DialogContentText>
                        <TextField
                            margin="dense"
                            id="name"
                            label="Nom de l'action"
                            type="text"
                            fullWidth
                            onChange={e => { handleNameChanged(e.target.value) }}
                            value={currentName}
                        />
                        <FormGroup>
                            <FormControlLabel
                                control={
                                    <Switch
                                        checked={ingredient_needed}
                                        onChange={handleChangeIngredient}
                                        inputProps={{ 'aria-label': 'controlled' }}
                                    />
                                }
                                label="Cette action DOIT être liée à un ou des ingrédients"
                            />
                            <FormControlLabel
                                control={
                                    <Switch
                                        checked={temperature_needed}
                                        onChange={handleChangeTemperature}
                                        inputProps={{ 'aria-label': 'controlled' }}
                                    />
                                }
                                label="Cette action nécessite une température"
                            />
                            <FormControlLabel
                                control={
                                    <Switch
                                        checked={duration_needed}
                                        onChange={handleChangeDuration}
                                        inputProps={{ 'aria-label': 'controlled' }}
                                    />
                                }
                                label="Cette action nécessite une durée"
                            />
                        </FormGroup>
                    </DialogContent>

                    <DialogActions>
                        <Button onClick={() => { handleClose() }} color="secondary">
                            Annuler
                        </Button>
                        {possibleToCreateAction ? <Button onClick={() => { addNewAction(); }} color="primary">Créer mon action manquante</Button> : <Button disabled onClick={() => { addNewAction(); }} color="primary">Créer mon action manquante</Button>}
                        
                    </DialogActions>
                </Dialog>
            </Container>


        );


    }
    /*
     *   {stateRemainingIngredients.filter(ingredients=>ingredients.selected===false).map((ingredient) => (
                                    <Chip key={ingredient.id} label={ingredient.name} variant="outlined" onClick={() => { HandleClickChip(ingredient.id) }} />





                                ))}
    setSelectedActionId */
    if (actionMode === "create") {
        return(
        < Container maxWidth='lg' style={{ marginBottom: "60px" }}>
            <Container maxWidth="xl" component="main">
                <Typography variant="h4" align="left">{actions[selectedActionIndex].name}</Typography>
               
                    {actions[selectedActionIndex].ingredient_needed ? 
                        <Grid id="values" container spacing={2} alignItems="stretch" style={{ marginBottom: "60px" }}>
                            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                <Typography>Veuillez sélectionner les ingrédients disponibles de la liste pour cette action:</Typography>
                            </Grid>
                            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                {remainingIngredients.map((item, index) => (
                                    <AdjustIngredientActionUse ingredient={item} updateaction={updateRemainingIngredients} index={index} />
                                ))}
                            </Grid>
                           
                        </Grid>
                            :
                        <></>
                    }
                    {actions[selectedActionIndex].temperature_needed ?
                        <Grid id="values" container spacing={2} alignItems="stretch" style={{ marginBottom: "60px" }}>
                            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                <Typography>A quelle temperature doit être faite l'action ?</Typography>
                                <AdjustTemperatureActionUse updateaction={handleTemperatureChanged}/>
                                
                            </Grid>
                        </Grid>
                        :
                        <></>
                    }
                    {actions[selectedActionIndex].duration_needed ?
                        <Grid id="values" container spacing={2} alignItems="stretch" style={{ marginBottom: "60px" }}>
                            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                <Typography>Combien de temps doit durer l'action ?</Typography>
                                <AdjustDurationActionUse updateaction={handleDurationChanged} />
                            </Grid>
                        </Grid>
                        :
                        <></>
                    }
                        
                    <Button onClick={() => { setActionMode("select"); }} color="secondary">
                        Retour à selection des actions
                </Button>
                    <Button onClick={() => { SaveNewAction(); }} color="primary">
                        Enregistrer
                </Button>
            </Container>
            </Container>
        )

    }


    }


/*
 *  <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title">

                    
                    

                        

                    
                    
                </Dialog>
 * */

/*
function mapStateToProps({ authentication, currentUser, pointofsale }) {
  return { authentication, currentUser, pointofsale };

}

export default connect(mapStateToProps) (Header);

*/
export default CreateActionInReceipe;
