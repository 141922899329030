/*######################################################
Creation Date: 14/07/2021
Author: Jean-Baptiste ROUGET
Updates:
# 29/09/2021: Creation in order to rewrite code a better way
#
#
######################################################*/
import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useCookies } from 'react-cookie';
import { selectCookieSetup, ReadLegalCookie, selectAuthCookie, selectIdpassreminder } from '../../store/reducers/legal';
import { selectIsLoggedIn, selectToken, LoadToken, LogOut } from '../../store/reducers/authentication';
import { selectUserDetailError, getUserInfo } from '../../store/reducers/user';

const AuthenticationLayer = (props) => {
    const dispatch = useDispatch();                                                     //Allow calling reducers
    const isLoggedin = useSelector(selectIsLoggedIn); 
    const errorDuringLogin = useSelector(selectUserDetailError);
    //const cookiesLoaded = useSelector(selectCookiesLoaded); 
    const cookieSetup = useSelector(selectCookieSetup);
    const cookieAuthCookie = useSelector(selectAuthCookie);
    const cookieIdpassreminder = useSelector(selectIdpassreminder);
    const token = useSelector(selectToken);
    // eslint-disable-next-line
    const [cookies, setCookie, removeCookie] = useCookies(["token"]);

    if (cookies.token !== undefined && token === "") {
        console.log("# Cookie Token exists but token was removed -> Reensuring we DELETE the cookie token");
        setCookie('token', undefined, { path: "/", maxAge: 60 * 60 * 24 * 365, });
    }

    //Read cookies and store info in state
    //If token is found, launch loggin via token

        console.log("#### AUTHENTICATION LAYER ####");
        console.log("#### Logged in = ", isLoggedin);
        console.log("# Reading cookie at startup");
        ReadLegalCookie(dispatch, cookieSetup, cookieAuthCookie, cookieIdpassreminder);
        console.log("# If cookies exists");
        if (cookies.token !== undefined && cookies.token !== "" && isLoggedin !== undefined) {
            console.log("## - Loading oauth token");
            if (cookies.token && token === undefined && isLoggedin === false) {
                console.log("### Cookie token was found but token state is undefined -> Logging in via OAuth token");
                LoadToken(dispatch, cookies.token);
            }
        }
        console.log("#### FIN AUTHENTICATION LAYER ####");


    //Creating cookie token if logged in
    if (isLoggedin) {
        if (!cookies.token || cookies.token === "" || cookies.token === undefined || cookies.token === 'undefined') {
            console.log("Cookie function Loggedin dashboard")
            //console.log("AuthCookie: ", cookieslegal.AuthCookie, " OR ", cookies.AuthCookie)
            if (cookies.AuthCookie === 'true') {
                //console.log("We have the right to log token in cookie");
                console.log("TOKEN : ", token);
                setCookie('token', token, { path: "/", maxAge: 60 * 60 * 24 * 365, });
            }
        }
        //Check if we can obtain user info from call
        //If no, means that the token is too old and user has to be logged out for security reason
        getUserInfo(dispatch);
        if (errorDuringLogin != "" && errorDuringLogin != undefined) {
            //Disconnect
            console.log("Disconnect : ", errorDuringLogin);
            LogOut(dispatch);
        }
        
    }
    console.log("Content of Disconnect : ", errorDuringLogin);
    




    return (
        <>
            {props.children}
        </>
        );
}

export default AuthenticationLayer;
