/*######################################################
Creation Date: 01/02/2021
Author: Jean-Baptiste ROUGET
Updates:
# 01/02/2021: Creation
# 27/09/2021: Refine and comment code
######################################################*/
import petitdejparfait from '../../assets/images/PetitDejParfait.jpg';
import gouter from '../../assets/images/Gouter.jpg';
import momentdepartage from '../../assets/images/MomentDePartage.jpg';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import CardEmphasis from '../library/CardEmphasis';
import Container from '@material-ui/core/Container';
import styles from "../../theme/style";


function Values(props) {
    const classes = styles();


    return (

        <Container style={{ padding: '50px' }} >
            <Typography variant="h3">Nos valeurs</Typography>
            <CardEmphasis
                style={{marginBottom:"40px"}}
                direction="left"
                title="Manger sain sans se priver"
                text1={<>Manger sain et équilibré est simple à condition d'avoir les bonnes clés et les bonnes habitudes. Bien connaître les aliments, leurs valeurs énergétiques, leurs complémentarité n'est en revanche pas chose aisée.</>}
                text2={<>Manger sain est varié est le gage d'un corps en bonne santé, mais aussi d'un regain d'énergie, d'un meilleur sommeil, d'une énergie retrouvée.</>}
                text3={<>Les Toqués vous permettront d'y arriver, facilement et sans vous priver.</>}
                altimage="Petit Déjeuner Parfait"
                image={petitdejparfait}
                button={<Button style={{ fontFamily: "Lobster", fontSize:"18px", color: "#FFFFFF", textTransform: 'none', maxHeight:"32px" }} href="/" color="primary" variant="contained" className={classes.pushToAction}>Montrez-moi</Button>}
            />
            <CardEmphasis
                style={{marginBottom:"40px"}}
                direction="right"
                title="Manger savoureux sans se ruiner"
                text1={<>Bien manger, avec du goût et de la saveur, ne vaut pas nécessairement dire dépenser plus. Avec le  bon choix d'ingrégients, les bonnes recettes, et l'expérience de la communauté, tout devient possible.</>}
                text2={<>Respecter la nature permet aussi de consommer plus responsable et à coûts maîtrisés.</>}
                text3={<>Les Toqués font autant attention à votre plaisir qu'à votre porte monnaie.</>}
                altimage="Mon goûter économique"
                image={gouter}
                button={<Button style={{ fontFamily: "Lobster", fontSize:"18px", color: "#FFFFFF", textTransform: 'none', maxHeight:"32px" }} href="/" color="primary" variant="contained" className={classes.pushToAction}>Je veux économiser</Button>}
            />
            <CardEmphasis
                style={{marginBottom:"40px"}}
                direction="left"
                title="Faire ensemble et partager des moments alléchants"
                text1={<>Faire la cuisine doit être un plaisir, et ce plaisir se partage. Trouvez ici des recettes à faire seul, en couple ou avec vos enfants, en respectant vos préférences, vos restrictions alimentaires, ou le contexte du jour.</>}
                text2={<>Nos recettes s'adaptent au nombre de vos convives, à leur âge.</>}
                text3={<>Les Toqués vous permettront d'organiser vos menus et de les réaliser sans prise de tête.</>}
                altimage="De bons moments conviviaux"
                image={momentdepartage}
                button={<Button style={{ fontFamily: "Lobster", fontSize:"18px", color: "#FFFFFF", textTransform: 'none', maxHeight:"32px" }} href="/" color="primary" variant="contained" className={classes.pushToAction}>Qu'est-ce qu'on mange ce soir ?</Button>}
            />
            


        </Container>

    );
}

export default Values;
