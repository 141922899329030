/*######################################################
Creation Date: 01/01/2021
Author: Jean-Baptiste ROUGET
Definition: Basic theming system
Updates:
# 11/01/2021: Creation
# 26/08/2021: Update code and refacture
#
######################################################*/
import lightTheme from './lightTheme';
import { makeStyles } from '@material-ui/core/styles';
import backgroundFooter from '../assets/background/BackgroundFooter.png';
import WaveDown from '../assets/background/WaveDown.png';
// eslint-disable-next-line
const theme = lightTheme;

const styles = makeStyles((theme) => ({
    '@global': {
        body: {
            backgroundColor: 'white',
        },
        ul: {
            margin: 0,
            padding: 0,
            listStyle: 'none'
        },
        backgroundColor: 'white',
        Link: {
            color: theme.primary
        }
    },
    main: {
        marginTop: '55px',
        backgroundColor: 'white',
        width: '100%'
    },
    containerXL: {
        maxWidth: 'xl',
        backgroundColor: 'white',
        color: 'white'
    },
    containerWaveDown : {
        backgroundImage: `url(${WaveDown})`,
        backgroundPosition: 'bottom',
        backgroundSize: 'cover'
    },
    appBar: {
        position: "fixed",
        color: "white",
        backgroundColor: "white",
        elevation:1,
        maxWidth:'xl',
        zIndex: 10
    },
    features: {
        backgroundColor: 'white',
        spacing: theme.spacing(2),
    },
    footer: {

        backgroundSize: 'cover',
        backgroundColor:'white',
        backgroundImage: `url(${backgroundFooter})`
    },
    cookieContainer:{
        position: 'fixed',
        bottom: 0,
        transition: '400ms',
        boxShadow: '0 -2px 16px rgba(47 54 64 0.5)'
    },

    brandValues: {
        backgroundImage: `url(${WaveDown})`,
        backgroundPosition: 'bottom',
        backgroundSize: 'cover'
     },
    fixedDrawerContainer: {
        zIndex: - 1,
        overflow: 'auto',
        whiteSpace: 'nowrap',
        overflowX: 'hidden'
    },
    drawerOpen: {
        zIndex: - 1,
        elevation: 0,
        width: 240,
        overflowX: 'hidden',
        whiteSpace: 'nowrap',
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    },
    drawerClose: {
        elevation: 0,
        zIndex: - 1,
        whiteSpace: 'nowrap',
        overflowX: 'hidden',
        width: 30,
     },
        toolbarTitle: {
        fontFamily: "Lobster",
        fontSize:"18px",
        color: "#2BB413",
        textTransform: 'none',
        marginRight:'30px'
    },
    pushToAction: {
        borderRadius: "16px",
        heigth: "32px",
        fontFamily: "Lobster",
        fontSize:"18px",
        color: "#FFFFFF",
        textTransform: 'none',
        maxHeight:"32px"
    },
    featureCard: {
        backgroundColor: 'white',
    }
}));

export default styles;
