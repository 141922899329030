/*######################################################
Creation Date: 11/07/2021
Author: Jean-Baptiste ROUGET
Updates:
# 09/01/2022: Creation "ingredients"
# 
# 
######################################################*/
import { apiUrl } from '../../config/keys';                                     // URL for APIs

//import store from './store';
//import store from './store';
//import { useNavigation } from '@react-navigation/native';
//import { selectToken } from '../../store/Reducers/authentication';
//const navigation = useNavigation();


const initialState = {
    ingredients: [],  //List of all ingredients
    types: [{ name: "spice", description: "Epices et condiments" }, { name: "cheese", description: "Fromage" }, { name: "fruits", description: "Fruits" }, { name: "ice", description: "Glaces et glaçons" }, { name: "vegetable", description: "Légumes" }, { name: "liquids", description: "Liquides et huiles" }, { name: "fish", description: "Poisson" },  { name: "meat", description:  "Viande"}, { name: "other", description:  "Autre"}],
    createIngredientError: false,
  //idpassreminder: false,
  //cookiesLoaded: false
};



const GET_INGREDIENT_LIST_REQUEST = 'GET_INGREDIENT_LIST_REQUEST';
const GET_INGREDIENT_LIST_SUCCESS = 'GET_INGREDIENT_LIST_SUCCESS';
const GET_INGREDIENT_LIST_FAILURE = 'GET_INGREDIENT_LIST_FAILURE';
const CREATE_INGREDIENT_REQUEST = 'CREATE_INGREDIENT_REQUEST';
const CREATE_INGREDIENT_SUCCESS = 'CREATE_INGREDIENT_SUCCESS';
const CREATE_INGREDIENT_FAILURE = 'CREATE_INGREDIENT_FAILURE';
const CREATE_INGREDIENT_ERROR_DISCARD = 'CREATE_INGREDIENT_ERROR_DISCARD';



export const CreateIngredient = async (dispatch, token, name, type) => {
    // Function to create a new ingredient in the ingredient list
    //console.log("TEST FUNC: ", name);
    console.log("Ready to create ingredient: ", name);
    console.log('Token : ', token);

    // Saves the receipe
    dispatch({ type: CREATE_INGREDIENT_REQUEST });
    try {
      const headers = {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      };
      if (token) {       headers.Authorization = `Bearer ${token}`;     }

      //console.log("Body container");
      let body = {
          name: name,
          type: type
      };

      await fetch(`${apiUrl}${'/ingredients'}`, {
        method: 'POST',
        headers,
        ...(body && { body: JSON.stringify(body) }),
      })
      .then( (response) => {
                  //console.log(" ########## RESULT", response.status);
                  //console.log("Fetch should have finished, receiving response or premise ", `${apiUrl}${'/users'}`);
                  //console.log("Response: ", response);
                  // eslint-disable-next-line
                  const data = response.json()
                      .then((datajson)=>{
                          if (!response.ok) {
                              console.log("Response is not ok");
                              dispatch({ type: CREATE_INGREDIENT_FAILURE, value: datajson.code });
                            }
                            else{
                              //console.log("Response is OK");
                              dispatch({ type: CREATE_INGREDIENT_SUCCESS, value: datajson });
                            }
                      })
              })
              .catch(error => {
                  console.log("CATCHED ERROR DURING INGREDIENT CREATION INITIATION");
                  //this.setState({ errorMessage: error.toString() });
                  //console.error('There was an error!', error);
              });

    } catch (err) {
        console.log("CATCHED ERROR DURING INGREDIENT CREATION");
        dispatch({ type: CREATE_INGREDIENT_FAILURE, value: err });
    }

    // Get back the receipe
    // Add the receipe to myReceipeDrafts
}

export const DiscardIngredientCreationError = async (dispatch) => {
    console.log("ERASE ERROR INGREDIENT CREATION");
    dispatch({ type: CREATE_INGREDIENT_ERROR_DISCARD, value: "" });
}



export const GetIngredientList = async (dispatch) => {
    // Function to create a receipe draft and save it as a draft to allow further edition
    //console.log("TEST FUNC: ", name);
    console.log("Ready to get ingredient list: ");
    

    // Saves the receipe
    dispatch({ type: GET_INGREDIENT_LIST_REQUEST });
    try {
      const headers = {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      };
     

      //console.log("Body container");

      await fetch(`${apiUrl}${'/ingredients'}`, {
        method: 'GET',
        headers,
      })
      .then( (response) => {
                  //console.log(" ########## RESULT", response.status);
                  //console.log("Fetch should have finished, receiving response or premise ", `${apiUrl}${'/users'}`);
                  //console.log("Response: ", response);
                  // eslint-disable-next-line
                   const data = response.json()
                      .then((data)=>{
                          if (!response.ok) {
                              console.log("Response is not OK");
                              dispatch({ type: GET_INGREDIENT_LIST_FAILURE, value: data.code });
                            }
                            else{
                              console.log("Response is OK");
                              dispatch({ type: GET_INGREDIENT_LIST_SUCCESS, value: data });
                            }
                      })
              })
              .catch(error => {
                  console.log("ERROR")
                  //this.setState({ errorMessage: error.toString() });
                  //console.error('There was an error!', error);
              });

    } catch (err) {
        console.log("ERROR")
        dispatch({ type: GET_INGREDIENT_LIST_FAILURE, value: err });
    }

    // Get back the receipe
    // Add the receipe to myReceipeDrafts
}


/*
export const UpdateDraftReceipeInformation = async (dispatch, token, receipe_id, index, receipe_field_name, receipe_field_new_value) => {
    // Function to create a receipe draft and save it as a draft to allow further edition
    //console.log("TEST FUNC: ", name);
    console.log("Ready toupdate receipe information for id: ", receipe_id);
    //console.log('Token : ', token);
    let body = {
        receipe_id: receipe_id,
        receipe_field_name: receipe_field_name,
        receipe_field_new_value: receipe_field_new_value
    };
    
    dispatch({ type: UPDATE_RECEIPE_DRAFT_REQUEST });
    try {
        const headers = {
            Accept: 'application/json',
            'Content-Type': 'application/json',
        };
        if (token) { headers.Authorization = `Bearer ${token}`; }

        //console.log("Body container");

        await fetch(`${apiUrl}${'/receipes/draft'}`, {
            method: 'POST',
            headers,
            ...(body && { body: JSON.stringify(body) }),
        })
            .then((response) => {
                //console.log(" ########## RESULT", response.status);
                //console.log("Fetch should have finished, receiving response or premise ", `${apiUrl}${'/users'}`);
                //console.log("Response: ", response);
                // eslint-disable-next-line
                const data = response.json()
                    .then((data) => {
                        if (!response.ok) {
                            dispatch({ type: UPDATE_RECEIPE_DRAFT_FAILURE, value: data.code });
                        }
                        else {
                            //console.log("Response is OK");
                            dispatch({ type: UPDATE_RECEIPE_DRAFT_SUCCESS, value: data, index: index });
                        }
                    })
            })
            .catch(error => {
                console.log("ERROR RECEIVING RECEIPE INFORMATION ")
                this.setState({ errorMessage: error.toString() });
                //console.error('There was an error!', error);
            });

    } catch (err) {
        dispatch({ type: GET_RECEIPE_INFORMATION_FAILURE, value: err });
    }

    // Get back the receipe
    // Add the receipe to myReceipeDrafts
}
*/

function reducer(state = initialState, action) {
    switch (action.type) {
      /*
    case CREATE_RECEIPE_DRAFT_REQUEST:
      return {
        ...state,
        myReceipeLoading: true,
      }
    case CREATE_RECEIPE_DRAFT_SUCCESS:
      return {
        ...state,
        myDraftReceipes: [...state.myDraftReceipes, action.value],
        myActiveDraftReceipe: action.value
      }
    case CREATE_RECEIPE_DRAFT_FAILURE:
      return {
          ...state,
          myActiveDraftReceipe:undefined

      }*/
    case GET_INGREDIENT_LIST_REQUEST:
      return {
        ...state,
      }
    case GET_INGREDIENT_LIST_SUCCESS:
      return {
          ...state,
        ingredients: action.value,
      }
    case GET_INGREDIENT_LIST_FAILURE:
      return {
        ...state,
            }
        case CREATE_INGREDIENT_REQUEST:
            return {
                ...state,
                createIngredientError: false,
            }
        case CREATE_INGREDIENT_SUCCESS:
            return {
                ...state,
                ingredients: [...state.ingredients, action.value],
                createIngredientError: false,
            }
        case CREATE_INGREDIENT_FAILURE:
            return {
                ...state,
                createIngredientError: true,
            }
        case CREATE_INGREDIENT_ERROR_DISCARD:
            return {
                ...state,
                createIngredientError: false,
            }
        /*
     case GET_RECEIPE_DRAFT_REQUEST:
      return {
        ...state,
        myReceipeLoading: true,
      }
      case GET_RECEIPE_DRAFT_SUCCESS:
          return {
              ...state,
              myDraftReceipes: action.value,
              myReceipeLoading: false,
          }
      case GET_RECEIPE_DRAFT_FAILURE:
          return {
              ...state,
              myReceipeLoading: false,

          }
      case UPDATE_RECEIPE_DRAFT_REQUEST:
          return {
              ...state,
              myReceipeLoading: true,
          }
      case UPDATE_RECEIPE_DRAFT_SUCCESS:
          console.log("ACTION : ", action);
          return {
              ...state,
              myDraftReceipes: state.myDraftReceipes.map(
                  (content, i) => i === action.index ? action.value
                      : content
              ),
             
              //myDraftReceipes[action.index] = action.value,
              myReceipeLoading: false,
          }
      case UPDATE_RECEIPE_DRAFT_FAILURE:
          return {
              ...state,
              myReceipeLoading: false,

            }
        */
    default:
      return state
  }
}

export const selectIngredients = (state) => state.ingredient.ingredients;
export const selectIngredientTypes = (state) => state.ingredient.types;
export const selectCreateIngredientError = (state) => state.ingredient.createIngredientError;
//export const selectMyActiveDraftReceipe = (state) => state.receipe.myActiveDraftReceipe;
//export const selectMyReceipes = (state) => state.receipe.myReceipes;
//export const selectSelectedReceipeInformationLoading = (state) => state.receipe.selectedReceipeInformationLoading;
//export const selectSelectedReceipeInformation = (state) => state.receipe.selectedReceipeInformation;
//export const selectMyReceipeLoading = (state) => state.receipe.myReceipeLoading;



export default reducer;
