/*######################################################
Creation Date: 14/07/2021
Author: Jean-Baptiste ROUGET
Updates:
# 29/09/2021: Creation in order to rewrite code a better way
#
#
######################################################*/
import argent from '../../assets/icones/argent.png';
import choix from '../../assets/icones/choix.png';
import four from '../../assets/icones/four.png';
import menus from '../../assets/icones/menus.png';
import radis from '../../assets/icones/radis.png';
import repas from '../../assets/icones/repas.png';
import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';
import CardFeature from '../library/CardFeature';
import Grid from '@material-ui/core/Grid';
import styles from "../../theme/style";

function Functionalities(props) {
    const classes = styles();

    return (
        <Container mt={2} mb={10} style={{ padding: '50px' }}>
            <Typography variant="h3" >Nos fonctionalités</Typography>
            <Grid container className={classes.features} alignItems="stretch" spacing={10}>
                <Grid item xs={12} sm={6} md={6} lg={4} xl={4}>
                    <CardFeature
                        text={<><p>Trouver vos recettes par mot clé, type de cuisine, régime alimentaire</p></>}
                        altimage="Recettes"
                        image={menus}
                    />
                </Grid>
                <Grid item xs={12} sm={6} md={6} lg={4} xl={4}>
                    <CardFeature
                        text={<><p>Générez vos menus de la semaine selon vos envies</p></>}
                        altimage="Menus"
                        image={choix}
                    />
                </Grid>
                <Grid item xs={12} sm={6} md={6} lg={4} xl={4}>
                    <CardFeature
                        text={<><p>Apprenez tout sur les légumes, leur saison, ce avec quoi ils se marient le mieux</p></>}
                        altimage="Légumes"
                        image={radis}
                    />
                </Grid>
                <Grid item xs={12} sm={6} md={6} lg={4} xl={4}>
                    <CardFeature
                        text={<><p>Sélectionnez par type d'équipement et niveau de difficulté</p></>}
                        altimage="Instruments"
                        image={four}
                    />
                </Grid>
                <Grid item xs={12} sm={6} md={6} lg={4} xl={4}>
                    <CardFeature
                        text={<><p>Trouvez des recettes en fonction de ce qu'il vous reste au réfrigérateur</p></>}
                        altimage="Recherches"
                        image={repas}
                    />
                </Grid>
                <Grid item xs={12} sm={6} md={6} lg={4} xl={4}>
                    <CardFeature
                        text={<><p>Economisez en trouvant les producteurs locaux et en comparant les prix</p></>}
                        altimage="Economies"
                        image={argent}
                    />
                </Grid>
            </Grid>
            
        </Container>

  );
}

export default Functionalities;
