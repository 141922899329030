/*######################################################
Creation Date: 02/03/2022
Author: Jean-Baptiste ROUGET
Updates:
# 02/03/2021: Creation
# 
######################################################*/
import React from 'react';
import Grid from '@material-ui/core/Grid';
//import styles from "../../theme/style";
import OutlinedInput from '@mui/material/OutlinedInput';
import InputAdornment from '@mui/material/InputAdornment';


function AdjustDurationActionUse(props) {
    //const classes = styles();
    const [hour, setHour] = React.useState('');
    const [minute, setMinute] = React.useState('');
    const [second, setSecond] = React.useState('');
    //console.log("MATCHES: ", matches, " and initial orientation: ", orientation);

    //let urlimage = 'url("' + props.image + '")'
    //console.log("ORIENTATION: ", orientation);
    const handleChangeHour = (event) => {
        //console.log(event.target);
        
        setHour(event.target.value);

        props.updateaction((Number(event.target.value)*3600)+(Number(minute)*60)+Number(second));

     
        
    };
    const handleChangeMinute = (event) => {
        //console.log(event.target);

        setMinute(event.target.value);

        props.updateaction((Number(hour) * 3600) + (Number(event.target.value) * 60) + (Number(second)));



    };
    const handleChangeSecond = (event) => {
        //console.log(event.target);

        setSecond(event.target.value);

        props.updateaction((Number(hour) * 3600) + (Number(minute) * 60) + Number(event.target.value));



    };

    return (


        <Grid id="actions_types" container spacing={2} alignItems="stretch" >
            <Grid item xs={4} sm={3} md={3} lg={3} xl={3}>
                
                <OutlinedInput
                    id="outlined-adornment-temperature"
                    value={hour}
                    onChange={e => { handleChangeHour(e) }}
                    endAdornment={<InputAdornment position="end">H</InputAdornment>}

                />
            </Grid>
            <Grid item xs={4} sm={3} md={3} lg={3} xl={3}>

                <OutlinedInput
                    id="outlined-adornment-temperature"
                    value={minute}
                    onChange={e => { handleChangeMinute(e) }}
                    endAdornment={<InputAdornment position="end">min</InputAdornment>}

                />
            </Grid>
            <Grid item xs={4} sm={3} md={3} lg={3} xl={3}>

                <OutlinedInput
                    id="outlined-adornment-temperature"
                    value={second}
                    onChange={e => { handleChangeSecond(e) }}
                    endAdornment={<InputAdornment position="end">s</InputAdornment>}

                />
            </Grid>
           

        </Grid>

    );

}

export default AdjustDurationActionUse;
