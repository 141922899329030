/*######################################################
Creation Date: 11/07/2021
Author: Jean-Baptiste ROUGET
Updates:
# 27/01/2022: Creation "ingredients"
# 
# 
######################################################*/
import { apiUrl } from '../../config/keys';                                     // URL for APIs

//import store from './store';
//import store from './store';
//import { useNavigation } from '@react-navigation/native';
//import { selectToken } from '../../store/Reducers/authentication';
//const navigation = useNavigation();


const initialState = {
    actions: [],  //List of all ingredients
    types: [{ name: "add_and_mix", description: "Ajout et mélange" }, { name: "season", description: "Assaisonnement" }, { name: "cut", description: "Coupe" }, { name: "baking", description: "Cuisson" }, { name: "prepare", description: "Préparation des ingrédients" }, { name: "other", description: "Autre" }],
    tempIngredientMap: [],
    createActionError: false,
  
  //idpassreminder: false,
  //cookiesLoaded: false
};



const GET_ACTION_LIST_REQUEST = 'GET_ACTION_LIST_REQUEST';
const GET_ACTION_LIST_SUCCESS = 'GET_ACTION_LIST_SUCCESS';
const GET_ACTION_LIST_FAILURE = 'GET_ACTION_LIST_FAILURE';
const CREATE_ACTION_REQUEST = 'CREATE_ACTION_REQUEST';
const CREATE_ACTION_SUCCESS = 'CREATE_ACTION_SUCCESS';
const CREATE_ACTION_FAILURE = 'CREATE_ACTION_FAILURE';
const CREATE_ACTION_ERROR_DISCARD = 'CREATE_INGREDIENT_ERROR_DISCARD';
const UPDATE_TEMP_INGREDIENT_MAP_REQUEST = 'UPDATE_TEMP_INGREDIENT_MAP_REQUEST';
const UPDATE_TEMP_INGREDIENT_MAP_SUCCESS = 'UPDATE_TEMP_INGREDIENT_MAP_SUCCESS';
const UPDATE_TEMP_INGREDIENT_MAP_FAILURE = 'UPDATE_TEMP_INGREDIENT_MAP_FAILURE';

export const UpdateTempIngredientMap = async (dispatch, ingredientmap) => {
    // Function to create a receipe draft and save it as a draft to allow further edition
    //console.log("TEST FUNC: ", name);
    console.log("Ready to update ingredient map : ", ingredientmap);
    //console.log('Token : ', token);

    // Saves the receipe
    dispatch({ type: UPDATE_TEMP_INGREDIENT_MAP_REQUEST, value: ingredientmap });
    dispatch({ type: UPDATE_TEMP_INGREDIENT_MAP_SUCCESS, value: ingredientmap });
    dispatch({ type: UPDATE_TEMP_INGREDIENT_MAP_FAILURE, value: ingredientmap });

    // Get back the receipe
    // Add the receipe to myReceipeDrafts
}

export const CreateAction = async (dispatch, token, name, ingredient_needed, duration_needed, temperature_needed, type) => {
    // Function to create a receipe draft and save it as a draft to allow further edition
    //console.log("TEST FUNC: ", name);
    console.log("Ready to create action: ", name);
    console.log('Token : ', token);

    // Saves the receipe
    dispatch({ type: CREATE_ACTION_REQUEST });
    try {
      const headers = {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      };
      if (token) {       headers.Authorization = `Bearer ${token}`;     }

      //console.log("Body container");
      let body = {
          name: name,
          type: type,
          ingredient_needed: ingredient_needed,
          duration_needed: duration_needed,
          temperature_needed: temperature_needed
      };

      await fetch(`${apiUrl}${'/actions'}`, {
        method: 'POST',
        headers,
        ...(body && { body: JSON.stringify(body) }),
      })
      .then( (response) => {
                  //console.log(" ########## RESULT", response.status);
                  //console.log("Fetch should have finished, receiving response or premise ", `${apiUrl}${'/users'}`);
                  //console.log("Response: ", response);
                  // eslint-disable-next-line
                  const data = response.json()
                      .then((datajson)=>{
                          if (!response.ok) {
                              dispatch({ type: CREATE_ACTION_FAILURE, value: datajson.code });
                            }
                            else{
                              //console.log("Response is OK");
                              dispatch({ type: CREATE_ACTION_SUCCESS, value: datajson });
                            }
                      })
              })
              .catch(error => {
                console.log("ERROR ")
                  //this.setState({ errorMessage: error.toString() });
                  //console.error('There was an error!', error);
              });

    } catch (err) {
        dispatch({ type: CREATE_ACTION_FAILURE, value: err });
    }

    // Get back the receipe
    // Add the receipe to myReceipeDrafts
}
export const DiscardActionCreationError = async (dispatch) => {
    console.log("ERASE ERROR ACTION CREATION");
    dispatch({ type: CREATE_ACTION_ERROR_DISCARD, value: "" });
}

export const GetActionList = async (dispatch) => {
    // Function to create a receipe draft and save it as a draft to allow further edition
    //console.log("TEST FUNC: ", name);
    console.log("Ready to get ingredient list: ");
    

    // Saves the receipe
    dispatch({ type: GET_ACTION_LIST_REQUEST });
    try {
      const headers = {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      };
     

      //console.log("Body container");

      await fetch(`${apiUrl}${'/actions'}`, {
        method: 'GET',
        headers,
      })
      .then( (response) => {
                  //console.log(" ########## RESULT", response.status);
                  //console.log("Fetch should have finished, receiving response or premise ", `${apiUrl}${'/users'}`);
                  //console.log("Response: ", response);
                  // eslint-disable-next-line
                   const data = response.json()
                      .then((data)=>{
                          if (!response.ok) {
                              console.log("Response is not OK");
                              dispatch({ type: GET_ACTION_LIST_FAILURE, value: data.code });
                            }
                            else{
                              console.log("Response is OK");
                              dispatch({ type: GET_ACTION_LIST_SUCCESS, value: data });
                            }
                      })
              })
              .catch(error => {
                console.log("ERROR")
                  //this.setState({ errorMessage: error.toString() });
                  //console.error('There was an error!', error);
              });

    } catch (err) {
        dispatch({ type: GET_ACTION_LIST_FAILURE, value: err });
    }

    // Get back the receipe
    // Add the receipe to myReceipeDrafts
}


/*
export const UpdateDraftReceipeInformation = async (dispatch, token, receipe_id, index, receipe_field_name, receipe_field_new_value) => {
    // Function to create a receipe draft and save it as a draft to allow further edition
    //console.log("TEST FUNC: ", name);
    console.log("Ready toupdate receipe information for id: ", receipe_id);
    //console.log('Token : ', token);
    let body = {
        receipe_id: receipe_id,
        receipe_field_name: receipe_field_name,
        receipe_field_new_value: receipe_field_new_value
    };
    
    dispatch({ type: UPDATE_RECEIPE_DRAFT_REQUEST });
    try {
        const headers = {
            Accept: 'application/json',
            'Content-Type': 'application/json',
        };
        if (token) { headers.Authorization = `Bearer ${token}`; }

        //console.log("Body container");

        await fetch(`${apiUrl}${'/receipes/draft'}`, {
            method: 'POST',
            headers,
            ...(body && { body: JSON.stringify(body) }),
        })
            .then((response) => {
                //console.log(" ########## RESULT", response.status);
                //console.log("Fetch should have finished, receiving response or premise ", `${apiUrl}${'/users'}`);
                //console.log("Response: ", response);
                // eslint-disable-next-line
                const data = response.json()
                    .then((data) => {
                        if (!response.ok) {
                            dispatch({ type: UPDATE_RECEIPE_DRAFT_FAILURE, value: data.code });
                        }
                        else {
                            //console.log("Response is OK");
                            dispatch({ type: UPDATE_RECEIPE_DRAFT_SUCCESS, value: data, index: index });
                        }
                    })
            })
            .catch(error => {
                console.log("ERROR RECEIVING RECEIPE INFORMATION ")
                this.setState({ errorMessage: error.toString() });
                //console.error('There was an error!', error);
            });

    } catch (err) {
        dispatch({ type: GET_RECEIPE_INFORMATION_FAILURE, value: err });
    }

    // Get back the receipe
    // Add the receipe to myReceipeDrafts
}
*/

function reducer(state = initialState, action) {
    switch (action.type) {
      /*
    case CREATE_RECEIPE_DRAFT_REQUEST:
      return {
        ...state,
        myReceipeLoading: true,
      }
    case CREATE_RECEIPE_DRAFT_SUCCESS:
      return {
        ...state,
        myDraftReceipes: [...state.myDraftReceipes, action.value],
        myActiveDraftReceipe: action.value
      }
    case CREATE_RECEIPE_DRAFT_FAILURE:
      return {
          ...state,
          myActiveDraftReceipe:undefined

      }*/
    case GET_ACTION_LIST_REQUEST:
      return {
        ...state,
      }
    case GET_ACTION_LIST_SUCCESS:
      return {
          ...state,
          actions: action.value,
      }
    case GET_ACTION_LIST_FAILURE:
      return {
        ...state,
            }
        case CREATE_ACTION_REQUEST:
            return {
                ...state,
                createActionError: false,
            }
        case CREATE_ACTION_SUCCESS:
            return {
                ...state,
                actions: [...state.actions, action.value],
                createActionError: false,
            }
        case CREATE_ACTION_FAILURE:
            return {
                ...state,
                createActionError: true,
            }
        case CREATE_ACTION_ERROR_DISCARD:
            return {
                ...state,
                createActionError: false,
            }
        case UPDATE_TEMP_INGREDIENT_MAP_SUCCESS:
            return {
                ...state,
                tempIngredientMap: action.value
            }

            
        /*
     case GET_RECEIPE_DRAFT_REQUEST:
      return {
        ...state,
        myReceipeLoading: true,
      }
      case GET_RECEIPE_DRAFT_SUCCESS:
          return {
              ...state,
              myDraftReceipes: action.value,
              myReceipeLoading: false,
          }
      case GET_RECEIPE_DRAFT_FAILURE:
          return {
              ...state,
              myReceipeLoading: false,

          }
      case UPDATE_RECEIPE_DRAFT_REQUEST:
          return {
              ...state,
              myReceipeLoading: true,
          }
      case UPDATE_RECEIPE_DRAFT_SUCCESS:
          console.log("ACTION : ", action);
          return {
              ...state,
              myDraftReceipes: state.myDraftReceipes.map(
                  (content, i) => i === action.index ? action.value
                      : content
              ),
             
              //myDraftReceipes[action.index] = action.value,
              myReceipeLoading: false,
          }
      case UPDATE_RECEIPE_DRAFT_FAILURE:
          return {
              ...state,
              myReceipeLoading: false,

            }
        */
    default:
      return state
  }
}

export const selectActions = (state) => state.action.actions;
export const selectActionTypes = (state) => state.action.types;
export const selectTempIngredientMap = (state) => state.action.tempIngredientMap;
export const selectCreateActionError = (state) => state.action.createActionError;
//export const selectMyActiveDraftReceipe = (state) => state.receipe.myActiveDraftReceipe;
//export const selectMyReceipes = (state) => state.receipe.myReceipes;
//export const selectSelectedReceipeInformationLoading = (state) => state.receipe.selectedReceipeInformationLoading;
//export const selectSelectedReceipeInformation = (state) => state.receipe.selectedReceipeInformation;
//export const selectMyReceipeLoading = (state) => state.receipe.myReceipeLoading;



export default reducer;
