/*######################################################
Creation Date: 01/01/2021
Author: Jean-Baptiste ROUGET
Definition: This page aims at loging in 
Updates:
# 11/01/2021: Creation
# 26/08/2021: Refining code, adding comprehensive comments, decreasing file size
# 27/09/2021: Correcting warnings and avoiding ES lint warnings
######################################################*/
import React from 'react';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import CircularProgress from '@material-ui/core/CircularProgress';
import Checkbox from '@material-ui/core/Checkbox';
import Link from '@material-ui/core/Link';
import Grid from '@material-ui/core/Grid';
import { Alert } from '@material-ui/lab';
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';
//import { connect } from 'react-redux';
import { useDispatch, useSelector } from 'react-redux';
import { createAccount, selectCreateAccountError, selectCreateAccountLoading } from '../../store/reducers/authentication';
import styles from '../../theme/style';
import detectBrowserLanguage from 'detect-browser-language';
import {  useHistory } from 'react-router-dom';



const SignIn = (props) => {
    //console.log("PROPS SIGNUP: ", props);
    let historynavigator = useHistory();
    const dispatch = useDispatch();
    const isLoading = useSelector(selectCreateAccountLoading);

    const [currentFirstName, setCurrentFirstName] = React.useState('');
    const handleFirstNameChanged = async (newValue) => {
        setCurrentFirstName(newValue);
    }
    const [currentLastName, setCurrentLastName] = React.useState('');
    const handleLastNameChanged = async (newValue) => {
        setCurrentLastName(newValue);
    }

    const createAccountError = useSelector(selectCreateAccountError);
    // eslint-disable-next-line
    const [alertMailSent, setAlertMailSent] = React.useState(false);
    const [currentEmail, setCurrentEmail] = React.useState('');
    const [isEmailValid, setEmailValidity] = React.useState(true);
    const [showingError, setShowingError] = React.useState('');
    const handleEmailChanged = async (newValue) => {
        // eslint-disable-next-line
        if (/^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/.test(newValue) === false) {
            //console.log("Email invalid: ", newValue);
            setEmailValidity(false);
        }
        else {
            //console.log("Email valid: ", newValue);
            setEmailValidity(true);
        }
        setCurrentEmail(newValue);
        //checkButtonInactivity();
    }
    //PSEUDONYME
    const [currentPseudo, setCurrentPseudo] = React.useState('');
    // eslint-disable-next-line
    const [isPseudoValid, setIsPseudoValid] = React.useState(true);
    const handlePseudoChanged = (newValue) => {
        setCurrentPseudo(newValue);
    }
    //const [isPseudoErreur, setIsPseudoErreur] = React.useState('');
    //TYPE DE compte
    // eslint-disable-next-line
    const [accountPricing, setAccountPricing] = React.useState('Freemium');
    //PASSWORD
    const [currentPassword, setCurrentPassword] = React.useState('');
    const [isPasswordValid, setPasswordValidity] = React.useState(true);
    //const [secureTextEntry, setSecureTextEntry] = React.useState(true);
    //const handleSecureTextEntryPressed = () => setSecureTextEntry(!secureTextEntry);
    const [passwordValidationError, setPasswordValidationError] = React.useState('Doit contenir au moins 8 caractères, une majuscule, une minuscule, un chiffre et un caractère spécial')
    const handlePasswordChanged = (newValue) => {
        if (/^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[a-zA-Z])(?=.*[&@$*=+?/!%_\-#]).{8,}$/.test(newValue) === false) {
            //console.log("Password is not valid: ", newValue);
            setPasswordValidity(false);
            if (newValue.length < 8){
                setPasswordValidationError('Doit contenir au moins 8 caractères');
                setPasswordValidity(false);
            }
            else if (/^(?=.*[a-z])(?=.*[A-Z])(?=.*[a-zA-Z]).{8,}$/.test(newValue) === false){
                //(/^(?=.*\d)(?=.*[&@$*=+?/!%_\-#])$/.test(newValue) === false)
                setPasswordValidationError('Doit contenir au moins une majuscule et une minuscule')
                setPasswordValidity(false);
            }
            else if (/^(?=.*[&@$*=+?/!%_\-#]).{8,}$/.test(newValue) === false) {
                setPasswordValidationError('Doit contenir au moins un caractère spécial ( & @ $ * = + ? ! % _ - #)');
                setPasswordValidity(false);
            }
            else if (/^(?=.*\d).{8,}$/.test(newValue) === false) {
                setPasswordValidationError('Doit contenir au moins un chiffre');
                setPasswordValidity(false);
            }
        }
        else {
            //console.log("Password is valid: ", newValue)
            setPasswordValidationError('Le mot de passe est valide');
            setPasswordValidity(true);
        }
        setCurrentPassword(newValue);
        //checkButtonInactivity();
    }
    //PASSWORD2
    const [currentPassword2, setCurrentPassword2] = React.useState('');
    const [isPasswordValid2, setPasswordValidity2] = React.useState(true);
    const [passwordValidationError2, setPasswordValidationError2] = React.useState('Doit contenir au moins 8 caractères, une majuscule, une minuscule, un chiffre et un caractère spécial')
    const handlePasswordChanged2 = (newValue) => {
        if (/^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[a-zA-Z])(?=.*[&@$*=+?/!%_\-#]).{8,}$/.test(newValue) === false) {
            //console.log("Password is not valid: ", newValue);
            setPasswordValidity2(false);
            if (newValue.length < 8){
                setPasswordValidationError2('Doit contenir au moins 8 caractères');
                setPasswordValidity2(false);
            }
            else if (/^(?=.*[a-z])(?=.*[A-Z])(?=.*[a-zA-Z]).{8,}$/.test(newValue) === false){
                //(/^(?=.*\d)(?=.*[&@$*=+?/!%_\-#])$/.test(newValue) === false)
                setPasswordValidationError2('Doit contenir au moins une majuscule et une minuscule')
                setPasswordValidity2(false);
            }
            else if (/^(?=.*[&@$*=+?/!%_\-#]).{8,}$/.test(newValue) === false) {
                setPasswordValidationError2('Doit contenir au moins un caractère spécial ( & @ $ * = + ? ! % _ - #)');
                setPasswordValidity2(false);
            }
            else if (/^(?=.*\d).{8,}$/.test(newValue) === false) {
                setPasswordValidationError2('Doit contenir au moins un chiffre');
                setPasswordValidity2(false);
            }
        }
        else if (currentPassword !== newValue){
            setPasswordValidationError2('Les mots de passe ne sont pas identiques');
            setPasswordValidity2(false);
        }
        else {
            //console.log("Password is valid: ", newValue)
            setPasswordValidationError2('Le mot de passe est valide');
            setPasswordValidity2(true);
        }
        setCurrentPassword2(newValue);
        //checkButtonInactivity();
    }
    //TERMS AND Agreement
    const [termsChecked, setTermsChecked] = React.useState(false);
    const [marketingChecked, setMarketingChecked] = React.useState(false);
    /*
    // ONLY FOR MOBILE APP
    const [termsModalVisibility, setTermsModalVisibility] = React.useState(false);
    const lookingterms = () => {
      //console.log("Press");
        setTermsModalVisibility(true);
        //console.log("...");
    }
    const closingterms = () => {
      //console.log("Press");
        setTermsModalVisibility(false);
        //console.log("...");
    }
    */
    //SAVE BUTTON
    const [isSaveButtonInactive, setSaveButtonActivity] = React.useState(true);
    const checkButtonInactivity = () => {
        //console.log("ITEM: ", termsChecked, isPasswordValid, isEmailValid);
        if (termsChecked && isPasswordValid && isEmailValid && isPasswordValid2 && (currentEmail !== "")){
            //console.log("bouton valid: ", termsChecked, isPasswordValid, isPasswordValid2, isEmailValid );
            setSaveButtonActivity(false);
        }
        else {
            //console.log("bouton invalid: ", termsChecked, isPasswordValid, isPasswordValid2, isEmailValid);
            setSaveButtonActivity(true);
        }
    }
    const handleSubmitCreateAccount = () => {
        //console.log("CLICK SUR ENREGISTRER");
        createAccount(dispatch, historynavigator, currentFirstName, currentLastName, currentEmail, currentPseudo, currentPassword, detectBrowserLanguage(), termsChecked, accountPricing, marketingChecked);
        setAlertMailSent(true);
        //historynavigator.push("login");
    };

    const showErrorMessage = (toBeShown) => {
        if (toBeShown === true){
            return(
                <Grid item xs={12} sm={6}>
                    <Alert severity="error">
                        {showingError}
                    </Alert>
                </Grid>
            )
        }

    }





    //UPDATE LOOP ON STATE CHANGE
    React.useEffect(() => {
        //console.log("States changed: ",isEmailValid, isPasswordValid ,isPasswordValid2 );
        checkButtonInactivity();
        // eslint-disable-next-line react-hooks/exhaustive-deps
        }, [isEmailValid, isPasswordValid, isPasswordValid2, termsChecked]
    );

    //Generate Pseudonyme
    React.useEffect(() => {
        //console.log("Generate pseudo");
        let temppseudo = "";
        //if (currentFirstName[0] != undefined)
        temppseudo = temppseudo.concat(currentFirstName[0], currentLastName);
        //console.log(temppseudo);
        if (temppseudo !== undefined && temppseudo !== "undefined") {
            setCurrentPseudo(temppseudo);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
        }, [currentFirstName, currentLastName]
    );

    React.useEffect(() => {
        //console.log("States changed: ", createAccountError );
        if (createAccountError === 1) {
            setShowingError("L'adresse email fournie existe déjà");
            setEmailValidity(false);
        }
        else if (createAccountError === 2) {
            setShowingError('Ce pseudonyme existe déjà');
            setIsPseudoValid(false);
        }
        else {
            setShowingError(undefined);
        }
        }, [createAccountError]
    );

    //console.log("ICI log");
    //console.log("LOG : ", this.classes);
    const classes = styles();

    return (
        <div className={classes.main} >
            <Container component="main" maxWidth="xs" className={classes.main} style={{paddingTop:'60px'}}>
                <Container style={{textAlign:'center'}}>
                    <Typography component="h1" variant="h5">Créer un compte</Typography>
                </Container>
                <div className={classes.paper}>
                    {showingError ? showErrorMessage(true):showErrorMessage(false)}
                    <Grid container spacing={2}>
                        <Grid item xs={12} sm={6}>
                            <TextField
                                autoComplete="fname"
                                name="firstName"
                                variant="outlined"
                                required
                                fullWidth
                                id="firstName"
                                label="Prénom"
                                value={currentFirstName}
                                onChange={e => {handleFirstNameChanged(e.target.value)}}
                                autoFocus
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <TextField
                                variant="outlined"
                                required
                                fullWidth
                                id="lastName"
                                label="Nom"
                                value={currentLastName}
                                onChange={e => {handleLastNameChanged(e.target.value)}}
                                name="lastName"
                                autoComplete="lname"
                             />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                variant="outlined"
                                required
                                fullWidth
                                id="pseudo"
                                label="Pseudonyme"
                                value={currentPseudo}
                                onChange={e => {handlePseudoChanged(e.target.value)}}
                                name="pseudo"
                                autoComplete="pseudo"
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                variant="outlined"
                                required
                                fullWidth
                                id="email"
                                label="Email"
                                value={currentEmail}
                                onChange={e => {handleEmailChanged(e.target.value)}}
                                error={isEmailValid ? false:true}
                                name="email"
                                autoComplete="email"
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                variant="outlined"
                                required
                                fullWidth
                                name="password"
                                label="Mot de passe"
                                value={currentPassword}
                                onChange={e => {handlePasswordChanged(e.target.value)}}
                                helperText={passwordValidationError}
                                error={isPasswordValid ? false:true}
                                type="password"
                                id="password"
                                autoComplete="current-password"
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                variant="outlined"
                                required
                                fullWidth
                                name="password2"
                                label="Confirmez le mot de passe"
                                value={currentPassword2}
                                onChange={e => {handlePasswordChanged2(e.target.value)}}
                                helperText={passwordValidationError2}
                                error={isPasswordValid2 ? false:true}
                                type="password"
                                id="password2"
                                autoComplete="current-password2"
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <FormControlLabel
                                id="tickbox1" control={<Checkbox value="allowMarketing" color="primary" onChange={(event)=>{setMarketingChecked(event.target.checked)}} />}
                                label="J'accepte de recevoir de l'inspiration, des informations marketing et promotionnelles ainsi que nos nouvelles par email."
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <FormControlLabel
                                id="tickbox2" control={<Checkbox id="tickbox2a" value="allowTerms" color="primary" onChange={(event)=>{setTermsChecked(event.target.checked)}} />}
                                label={(<label htmlFor = {"linkToTermsFromCreateAccount"}>
                                    <span>Je reconnais avoir lu, compris et accepté les </span>
                                    <Link onClick={()=>{historynavigator.push("/legal/terms")}}>conditions d'utilisation</Link>
                                    <span> et </span>
                                    <Link onClick={()=>{historynavigator.push("/legal/privacy")}}>la politique de confidentialité</Link>
                                    </label>)}
                            />
                        </Grid>
                    </Grid>
                    <Button
                        type="submit"
                        fullWidth
                        variant="outlined"
                        color="primary"
                        className={classes.submit}
                        onClick={()=>{handleSubmitCreateAccount()}}
                        disabled={isSaveButtonInactive}
                    >{isLoading && <CircularProgress />}ENREGISTRER
                    </Button>
                    <Grid container styles={{marginTop:'20px'}}>
                        <Grid item xs>
                        </Grid>
                        <Grid item>
                            <Link href="/auth/login" variant="body2">
                                Vous avez déjà un compte ? Connectez-vous
                            </Link>
                        </Grid>
                    </Grid>
                </div>
            </Container>
      </div>
    );


}
/*
function mapStateToProps({ authentication }) {
    return { authentication };
}

export default connect(mapStateToProps) (SignIn);
*/
export default SignIn;