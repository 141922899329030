/*######################################################
Creation Date: 01/02/2021
Author: Jean-Baptiste ROUGET
Updates:
# 01/02/2021: Creation
# 27/09/2021: Refine and comment code
######################################################*/
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import styles from "../../theme/style";


function CardFeature(props) {
  const classes = styles();
//console.log("MATCHES: ", matches, " and initial orientation: ", orientation);

  //let urlimage = 'url("' + props.image + '")'
  //console.log("ORIENTATION: ", orientation);


    return (


      <Grid container className={classes.featureCard} spacing={2} alignItems="stretch" style={{borderColor:'#2BB413', borderStyle:'solid'}}>
            <Grid item component={Box} style={{height: '100px'}} xs={12} sm={12} md={12} lg={12} xl={12}>
                <Box align="center">
                    <img src={props.image} style={{ align: 'center', maxWidth: '80px'}} alt={props.altimage}/>
                </Box>

            </Grid>
            <Grid item component={Box} style={{height: '200px'}} xs={12} sm={12} md={12} lg={12} xl={12}>
                <Box align="center">
                    <Typography variant="h5" align="center">{props.text}</Typography>
                </Box>
            </Grid>
      </Grid>

    );

}

export default CardFeature;
