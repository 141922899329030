/*######################################################
Creation Date: 01/02/2021
Author: Jean-Baptiste ROUGET
Updates:
# 01/02/2021: Creation
# 27/09/2021: Refine and comment code
######################################################*/
import React from 'react';
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';
import CircularProgress from '@material-ui/core/CircularProgress';
import Button from '@material-ui/core/Button';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import styles from '../../theme/style';
import { useDispatch, useSelector } from 'react-redux';
import CheckCircleRoundedIcon from '@material-ui/icons/CheckCircleRounded';
import WarningRoundedIcon from '@material-ui/icons/WarningRounded';
import { apiUrl } from '../../config/keys';
import {  useHistory } from 'react-router-dom';
import {selectValidateAccount, selectValidateAccountLoading, selectValidateAccountCode, selectValidateAccountError, validateAccount } from '../../store/reducers/authentication';


const AccountValidation = (props) => {
    const dispatch = useDispatch();
    const classes = styles();
    let historynavigator = useHistory();
    const isLoading = useSelector(selectValidateAccountLoading);
    const isValidAccount = useSelector(selectValidateAccount);
    const isValidAccountCode = useSelector(selectValidateAccountCode);
    const isValidAccountError = useSelector(selectValidateAccountError);
    


    const HandleSendEmail = () => {
        //console.log("CLICK ON SEND A NEW MAIL with code: ", isValidAccountCode);
        try {
            const headers = {
                Accept: 'application/json',
                'Content-Type': 'application/json',
            };

            //console.log("Body container");
            let body = {
                code: isValidAccountCode
            };
            //console.log("HERE IT STARTS");
            // eslint-disable-next-line
            let finalresponse = fetch(`${apiUrl}${'/users/validationcode'}`, {
                method: 'POST',
                headers,
                ...(body && { body: JSON.stringify(body) }),
            })
            historynavigator.push("/");

        }
        catch (err) {
            console.log("ERROR new email: ", err )
            //dispatch({ type: VALIDATE_ACCOUNT_FAILURE, value: err });
        }
        //const mytoken = selectToken;

        //setAlertMailSent(true);
    };

    //console.log("isLoading: ", isLoading);
    //console.log("isValidAccount: ", isValidAccount);
    //console.log("isValidAccountError: ", isValidAccountError);
    //console.log("code: ", props.match.params.validationkey);




    if (isValidAccount === undefined && isLoading === false) {
        console.log("Lancement validation");
        validateAccount(dispatch, props.match.params.validationkey); //+ validationkey;
    }


    if(isValidAccount === undefined){
        //console.log("Step 1 - activation");
        return (
            <div className={classes.main}>
                <Container maxWidth="lg" component="main" style={{paddingTop:'60px'}}>
                    <Grid container style={{ alignItems:"center", justify:"center"}}>
                        <Grid item component={Box} xs={12} sm={12} md={12} lg={12} xl={12}>
                            <Box display="flex"  justifyContent="center"><Typography variant="h5">Veuillez patienter pendant que nous vérifions votre compte.</Typography></Box>
                        </Grid>
                        <Grid item component={Box} xs={12} sm={12} md={12} lg={12} xl={12}>
                            <Box display="flex"  justifyContent="center" style={{marginTop:'60px', marginBottom:'300px'}}>
                                { isLoading ? <CircularProgress color="primary" />:<></>}
                            </Box>
                        </Grid>

                    </Grid>
                </Container>
            </div>
        );
    }
    if(isValidAccount === true){
        return (
            <div className={classes.main}>
                <Container maxWidth="lg" component="main" style={{paddingTop:'60px'}}>
                    <Grid container style={{ alignItems:"center", justify:"center"}}>
                        <Grid item component={Box} xs={12} sm={12} md={12} lg={12} xl={12}>
                            <Box display="flex"  justifyContent="center"><Typography variant="h5">Félicitations, votre compte est activé !</Typography></Box>
                        </Grid>
                        <Grid item component={Box} xs={12} sm={12} md={12} lg={12} xl={12}>
                            <Box display="flex"  justifyContent="center" style={{marginTop:'60px', marginBottom:'300px'}}>
                                <CheckCircleRoundedIcon color="primary" fontSize="large"/>
                            </Box>
                        </Grid>
                        <Grid item component={Box} xs={12} sm={12} md={12} lg={12} xl={12}>
                            <Box display="flex"  justifyContent="center" style={{marginTop:'60px', marginBottom:'100px'}}>
                                <Button
                                    type="submit"
                                    variant="outlined"
                                    color="primary" 
                                    className={classes.link} style={{maxHeight:"32px", marginBottom:'20px'}}
                                    onClick={()=>{historynavigator.push("/auth/login")}}
                                >
                                Me connecter
                                </Button>
                            </Box>
                        </Grid>
                    </Grid>
                </Container>
            </div>
        );
    }

    if(isValidAccount === false | isValidAccountError!==undefined ){
        return (
            <div className={classes.main}>
                <Container maxWidth="lg" component="main" style={{paddingTop:'60px'}}>
                    <Grid container style={{ alignItems:"center", justify:"center"}}>
                        <Grid item component={Box} xs={12} sm={12} md={12} lg={12} xl={12}>
                            <Box display="flex"  justifyContent="center"><Typography variant="h5" color="secondary" >Un problème est survenu</Typography></Box>
                        </Grid>
                        <Grid item component={Box} xs={12} sm={12} md={12} lg={12} xl={12}>
                            <Box display="flex"  justifyContent="center" style={{marginTop:'60px', marginBottom:'300px'}}>
                                <WarningRoundedIcon color="secondary" fontSize="large" />
                            </Box>
                        </Grid>
                        <Grid item component={Box} xs={12} sm={12} md={12} lg={12} xl={12}>
                            <Box display="flex"  justifyContent="center" style={{marginTop:'60px', marginBottom:'100px'}}>
                                <Button
                                    type="submit"
                                    variant="outlined"
                                    color="primary"
                                    className={classes.link} style={{maxHeight:"32px", marginBottom:'20px'}}
                                    onClick={()=>{HandleSendEmail()}}
                                >
                                Recevoir un nouveaucode
                                </Button>
                            </Box>
                        </Grid>
                    </Grid>
                </Container>
            </div>
        );
     }

    return (
        <div className={classes.main}>
            <Container maxWidth="lg" component="main" style={{paddingTop:'60px'}}>
                { isLoading ? <CircularProgress color="primary" />:<><Typography>test</Typography>
                    { isValidAccount ?
                        <Container><Typography>test2</Typography>
                            Compte valide
                        </Container> :
                        <Container><Typography>test3</Typography>
                            Erreur durant la validation
                        </Container>
                    }

                </>}
            </Container>
        </div>
    );

}

export default AccountValidation;
