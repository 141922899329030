/*######################################################
Creation Date: 02/03/2022
Author: Jean-Baptiste ROUGET
Updates:
# 02/03/2021: Creation
# 
######################################################*/
import React from 'react';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
//import styles from "../../theme/style";
import OutlinedInput from '@mui/material/OutlinedInput';
import InputAdornment from '@mui/material/InputAdornment';



function AdjustIngredientActionUse(props) {
    
    //const classes = styles();
    const [valeur, setValeur] = React.useState('');
    //console.log("MATCHES: ", matches, " and initial orientation: ", orientation);

    //let urlimage = 'url("' + props.image + '")'
    //console.log("ORIENTATION: ", orientation);
    const handleChange = (event) => {
        console.log(event.target);
        if (event.target.value <= props.ingredient.quantity) {
            if (event.target.value === "") {
                setValeur(0);
                props.updateaction(props.index, 0);
            }
            else {
                setValeur(event.target.value);
                props.updateaction(props.index, event.target.value);
            }
            
        }
        else {
            setValeur(props.ingredient.quantity);
            props.updateaction(props.index, props.ingredient.quantity);
        }
        
    };

    return (


        <Grid key={props.ingredient.id} id="actions_types" container spacing={2} alignItems="stretch" >
            <Grid  item xs={4} sm={3} md={3} lg={3} xl={3}>
                <Typography >
                    {props.ingredient.name}
                </Typography>
            </Grid>
            <Grid item xs={4} sm={3} md={3} lg={3} xl={3}>
                <OutlinedInput
                    id="outlined-adornment-weight"
                    value={valeur}
                    onChange={e => { handleChange(e) }}
                    endAdornment={<InputAdornment position="end">{props.ingredient.metric}</InputAdornment>}
                    
                />
            </Grid>
            <Grid item xs={4} sm={3} md={3} lg={3} xl={3}>
                <Typography >
                    {props.ingredient.metric}
                </Typography>
            </Grid>
            <Grid item xs={4} sm={3} md={3} lg={3} xl={3}>
                <Typography >
                    (max: {props.ingredient.quantity})
                </Typography>
            </Grid>

        </Grid>

    );

}

export default AdjustIngredientActionUse;
