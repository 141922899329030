/*######################################################
Creation Date: 14/07/2021
Author: Jean-Baptiste ROUGET
Updates:
# 14/07/2021: Creation
#
#
######################################################*/
import Typography from '@material-ui/core/Typography';
//import Button from '@material-ui/core/Button';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
//import Link from '@material-ui/core/Link';
import React from 'react';
import StarOutlined from '@material-ui/icons/StarOutlined';
import StarHalfOutlined from '@material-ui/icons/StarHalfOutlined';
import StarBorderOutlined from '@material-ui/icons/StarBorderOutlined';
import { useHistory } from 'react-router-dom';
//import useMediaQuery from '@material-ui/core/useMediaQuery';
//import theme from '../../theme/lightTheme';
import styles from "../../theme/style";


function CardReceipe(props) {
    const classes = styles();
    let historynavigator = useHistory();

//console.log("MATCHES: ", matches, " and initial orientation: ", orientation);

  //let urlimage = 'url("' + props.image + '")'
  //console.log("ORIENTATION: ", orientation);
    //<Link href={url} >
    //</Link >
    let url = "";
    if (props.draft.status === 'draft') {
        url = "/receipe/draft/" + props.draft.slug;
    }
    else {
        url = "/receipe/" + props.draft.slug;
    }


    const openReceipe = () => {
        historynavigator.push(url);
    };

    return (
       
      
            <Grid container className={classes.featureCard} spacing={2} alignItems="stretch" style={{ borderColor: '#2BB413', borderStyle: 'solid', borderWidth: '1px' }} >
            <Grid item component={Box} style={{width: '80%' }} xs={12} sm={12} md={12} lg={12} xl={12}>
                <Box align="center">
                    <img src={props.image} style={{ align: 'center', maxWidth: '100%' }} alt={props.altimage} onClick={() => openReceipe()}  />
                </Box>

            </Grid>
            <Grid item component={Box}  xs={9} sm={9} md={9} lg={9} xl={9}>
                <Box align="left">
                    <Typography variant="h5" align="left">{props.draft.name}</Typography>
                </Box>
                </Grid>
            <Grid item component={Box}  xs={3} sm={3} md={3} lg={3} xl={3}>
                <Box align="left">
                        <StarOutlined fontSize='small' style={{ margin: '-3px' }} /><StarHalfOutlined fontSize='small' style={{ margin: '-3px' }} /><StarBorderOutlined fontSize='small' style={{ margin: '-3px' }} /><StarBorderOutlined fontSize='small' style={{ margin: '-3px' }} /><StarBorderOutlined fontSize='small' style={{ margin: '-3px' }}/>
                </Box>
                </Grid>
                <Grid item component={Box}  xs={12} sm={12} md={12} lg={12} xl={12}>
                    <Box align="left">
                        <Typography variant="h6" align="left">Description</Typography>
                    </Box>
                </Grid>
                <Grid item component={Box} style={{ height: '200px' }} xs={12} sm={12} md={12} lg={12} xl={12}>
                    <Box align="left">
                        <Typography variant="body1" align="left">{props.draft.description}</Typography>
                    </Box>
                </Grid>
            </Grid>
           

    );

}

export default CardReceipe;
