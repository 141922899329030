/*######################################################
Creation Date: 01/02/2021
Author: Jean-Baptiste ROUGET
Updates:
# 01/02/2021: Creation
# 28/09/2021: Document the code
######################################################*/
import React from 'react';
import Button from '@material-ui/core/Button';
import Alert from '@material-ui/lab/Alert';
import TextField from '@material-ui/core/TextField';
import Link from '@material-ui/core/Link';
import Grid from '@material-ui/core/Grid';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';
import styles from '../../theme/style';
import { useDispatch, useSelector } from 'react-redux';
import { useCookies } from "react-cookie";
import { selectLoginError, selectLoginErrorMessage, selectCheckYourMailMessage, LoginCustom } from '../../store/reducers/authentication';
import {  useHistory } from 'react-router-dom';


const LogIn = (props) => {
    const dispatch = useDispatch();
    // eslint-disable-next-line
    const [cookies, setCookie] = useCookies(["user"]);
    const [currentEmail, setCurrentEmail] = React.useState('');
    const [isEmailValid, setEmailValidity] = React.useState(true);
    let historynavigator = useHistory();

    const handleEmailChanged = async (newValue) => {
        //CHECK VALIDITY EMAIL - TO BE DONE
        // eslint-disable-next-line
        if (/^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/.test(newValue) === false) {
            //console.log("Email invalid: ", newValue);
            setEmailValidity(false);
        }
        else {
            //console.log("Email valid: ", newValue);
            setEmailValidity(true);
        }
        setCurrentEmail(newValue);
        //checkButtonInactivity();
    }

    const handlePasswordChanged = async (newValue) => {
        setCurrentPassword(newValue);
    }
    const [currentPassword, setCurrentPassword] = React.useState('');
    //const isLoggedin = useSelector(selectIsLoggedIn);
    const isLoggedinError = useSelector(selectLoginError);
    const isLoggedinErrorMessage = useSelector(selectLoginErrorMessage);
    //const token = useSelector(selectToken);
    const alertEmail = useSelector(selectCheckYourMailMessage);
    /*
    React.useEffect(() => {
        //console.log("States changed: ",isEmailValid, isPasswordValid ,isPasswordValid2 );
        //console.log("TOKEN STATE HAS CHANGED: ", token)
        if (token !== "" && token !== undefined && isLoggedin===true){
            setCookie("token", token, {
                path: "/",
                maxAge: 60*60*24*30,
            });
            //history.push("/dashboard");
            historynavigator.push("/");
        }
    
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [token, isLoggedin]);
    */

    const HandleLogin = () => {
        console.log("CLICK SUR LOGIN button");
        LoginCustom(dispatch, historynavigator, currentEmail, currentPassword);
    };

    // eslint-disable-next-line
    const refreshTokenSetup = async (res) => {
        // Timing to renew access token
        let refreshTiming = (res.tokenObj.expires_in || 3600 - 5 * 60) * 1000;

        const refreshToken = async () => {
            const newAuthRes = await res.reloadAuthResponse();
            refreshTiming = (newAuthRes.expires_in || 3600 - 5 * 60) * 1000;
             //console.log('newAuthRes:', newAuthRes);
            // saveUserToken(newAuthRes.access_token);  <-- save new token
            //localStorage.setItem('authToken', newAuthRes.id_token);

            // Setup the other timer after the first one
            setTimeout(refreshToken, refreshTiming);
        };

        // Setup first refresh timer
        setTimeout(refreshToken, refreshTiming);
    };
     /*
  const onFailure = (res) => {
    //console.log('Login failed: res:', res);
    alert(
      `Failed to login.`
    );
  };

  const onSuccess = (res) => {
    //console.log('Login Success: currentUser:', res.profileObj);

    refreshTokenSetup(res);
    //console.log("Ready to do login function");
    //loginGoogle(dispatch, res.profileObj.email, res.profileObj.googleId, res.profileObj.givenName, res.profileObj.familyName, detectBrowserLanguage());




    };
   
  const clientId = keys.googleClientID;
  const { signInGoogleHook } = useGoogleLogin({
    onSuccess,
    onFailure,
    clientId,
    isSignedIn: false,
    accessType:'offline',

  });
  */
    const classes = styles();



    return (
        <div className={classes.main} >
            <Container component="main" maxWidth="xs" className={classes.main} style={{paddingTop:'60px'}}>
                <Container style={{textAlign:'center'}}>
                    <Typography component="h1" variant="h5">Me connecter</Typography>
                    {alertEmail ? <Alert iconMapping={{ success: <CheckCircleOutlineIcon fontSize="inherit" /> }} severity="success">{alertEmail}</Alert> : <></>}
                </Container>
                {isLoggedinError ? <Alert iconMapping={{ success: <CheckCircleOutlineIcon fontSize="inherit" /> }} severity="error">{isLoggedinErrorMessage}</Alert> : <></>}
                <form>    
                <TextField
                    variant="outlined"
                    margin="normal"
                    required
                    fullWidth
                    id="email"
                    label="Email"
                    name="email"
                    autoComplete="email"
                    onChange={e => {handleEmailChanged(e.target.value)}}
                    value={currentEmail}
                    error={isEmailValid ? false:true}
                    autoFocus
                />
                <TextField
                    variant="outlined"
                    margin="normal"
                    required
                    fullWidth
                    name="password"
                    label="Mot de passe"
                    type="password"
                    onChange={e => {handlePasswordChanged(e.target.value)}}
                    value={currentPassword}
                    id="password"
                    autoComplete="current-password"
                    />
                </form>
                <Button
                    type="submit"
                    fullWidth
                    variant="contained"
                    color="primary"
                    className={classes.pushToAction} style={{ fontFamily: "Lobster", fontSize: "18px", color: "#FFFFFF", textTransform: 'none', maxHeight: "32px", marginTop: '20px', marginBottom: '20px' }}
                        onClick={() => { console.log("Click login button");HandleLogin() }}
                >Connection</Button>
              
                <Grid container direction="row-reverse" justify="flex-start" style={{marginTop:'20px', marginBottom:'20px'}}>
                    <Grid item >
                        <Link href="/auth/forgottenpassword" variant="body2">Mot de passe oublié ?</Link>
                    </Grid>
                </Grid>
                <Button
                    type="submit"
                    fullWidth
                    variant="outlined"
                    color="primary" 
                    className={classes.link} style={{ maxHeight: "32px", marginTop: '20px', marginBottom:'20px' }}
                    onClick={() => { historynavigator.push("/auth/signin"); }}
                >Créer un compte</Button>
            </Container>
        </div>
    );
}

export default LogIn;

/* METTRE CA POUR LOGIN GOOGLE
<Typography variant="h5" style={{marginTop:'40px', marginBottom:'40px', textAlign:'center'}}>
  Ou
</Typography>
<Button
  type="submit"
  fullWidth
  variant="contained"
  color="primary" variant="outlined"
  className={classes.link} style={{maxHeight:"32px", marginBottom:'20px'}}
  onClick={()=>{HandleLogin()}}
>
  Connection avec Google
</Button>
*/


/*
function mapStateToProps({ authentication }) {
  return { authentication };

}

export default connect(mapStateToProps) (LogIn);
*/
