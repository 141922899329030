import { createMuiTheme }  from '@material-ui/core/styles'
//import blue from '@material-ui/core/colors/blue';
import red from '@material-ui/core/colors/red';
const lightTheme = createMuiTheme({
  overrides: {
    MuiButton: {
      root: {
        borderRadius: 16

      },
    },
  },
  typography: {
    fontFamily: [
      '-apple-system',
      'BlinkMacSystemFont',
      '"Segoe UI"',
      'Roboto',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
      '"Lobster"',
      '"Montserrat"',
    ].join(','),
    h1: {fontFamily: "Lobster"},
    h2: {fontFamily: "Lobster"},
    h3: {fontFamily: "Lobster", color: "#2BB413", marginBottom: "40px"},
    h4: {fontFamily: "Lobster", color: "#2BB413"},
    h5: {fontFamily: "Lobster", color: "#2BB413", marginBottom: "40px"},
    h6: {fontFamily: "Lobster", color: "#2BB413"},
    subtitle1: {fontFamily: "Lobster", color: "#2BB413"},
    body1: {fontFamily: "Roboto", color: "#000000"},
    body2: {fontFamily: "Roboto", color: "#000000"}
  },
  palette: {
    primary: {
      main:"#2BB413",
    },
    secondary: {
      main:red[700],
    },

  },
  spacing: 4,
});
export default lightTheme
