import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import keys from "../../config/keys";
import Container from '@material-ui/core/Container';


function VersionInformation(props) {
  //const classes = styles();
//console.log("MATCHES: ", matches, " and initial orientation: ", orientation);

  //let urlimage = 'url("' + props.image + '")'
  //console.log("ORIENTATION: ", orientation);
    //console.log("COOKIE KEY is : ", keys.cookieKey);

    return (


<Container  mt={2} mb={10}>
      <Grid container spacing={10} alignItems="stretch" >

            <Grid item component={Box} style={{height: '200px'}} xs={12} sm={12} md={12} lg={12} xl={12}>
                <Box align="center">
                    <Typography variant="h5" align="center">Environment: {keys.environment} </Typography>

                </Box>
            </Grid>
      </Grid>
</Container>
    );

}

export default VersionInformation;
