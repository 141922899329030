import { useCookies } from 'react-cookie';
//import store from './store';
//import { useNavigation } from '@react-navigation/native';
//import { selectToken } from '../../store/Reducers/authentication';
//const navigation = useNavigation();


const initialState = {
  cookieSetup: undefined,
  authCookie: false,
  //idpassreminder: false,
  cookiesLoaded: false
};



const CREATE_COOKIE_SETUP_REQUEST = 'CREATE_COOKIE_SETUP_REQUEST';
const CREATE_COOKIE_SETUP_SUCCESS = 'CREATE_COOKIE_SETUP_SUCCESS';
const CREATE_COOKIE_SETUP_FAILURE = 'CREATE_COOKIE_SETUP_FAILURE';

export const ReadLegalCookie = async (dispatch,  cookieSetup, authCookie, idpassreminder) => {
    
    const [cookieRight] = useCookies(["CookieRight"]);
    //console.log("cookie setup : ", cookieSetup, "cookieRight.AuthCookie: ", cookieRight.AuthCookie, "cookieRight.token : " , cookieRight.token);

    if (cookieSetup === undefined) {
        console.log("Ensure Cookie information are stored in store");
        if (cookieRight.AuthCookie !== undefined && cookieRight.AuthCookie !== "") {
            console.log("Authcookie exists - storing cookie in state");
            const value = { cookieSetup: true, authCookie: cookieRight.AuthCookie };
            dispatch({ type: CREATE_COOKIE_SETUP_SUCCESS, value });
        }
        
    }
    //const value = {cookieSetup: true, authCookie: cookieRight.AuthSetup};
    //dispatch({ type: CREATE_COOKIE_SETUP_SUCCESS, value });

}
/*
export const ReadLegalCookie = async (dispatch, cookieSetup, authCookie, idpassreminder) => {
  const [cookieRight, setCookieRight] = useCookies(["CookieRight"]);
  const [authSetup, setAuthSetup] = useCookies(["AuthSetup"]);
  const [credRemindSetup, setCredRemindSetup] = useCookies(["CredRemindSetup"]);
  const cookiealreadyloaded = useSelector(selectCookiesLoaded);
  if (cookiealreadyloaded)
  {
    console.log("COOKIES ALREADY LOADED, NO NEED TO READ AGAIN");
  }
  else {
    console.log("Reading cookies");
    //const state = getState();
    //const cookieSetup = this.props. ; //selectCookieSetup(state);
    //console.log("Launch of reducer/legal/ReadLegalCookie: ", cookieSetup);
    //console.log("Launch of reducer/legal/ReadLegalCookie: ", authCookie);
    //console.log("Launch of reducer/legal/ReadLegalCookie: ", idpassreminder);
    try {
      //console.log("Initiate LEGAL state");
      //const valuesetup = cookies.cookieSetup;
      //const valueAuth = cookies.authCookie
      //Si l'etat de cookieSetup = undefined, vaut dire que l'on vient de lancer l'appli ->
      if (cookieSetup === undefined){
          console.log("CookieSetup = undefined");

          if (!cookieRight.CookieRight) {
            console.log("No cookie found, we have to show the banner and save parameters");
            dispatch({ type: CREATE_COOKIE_SETUP_FAILURE, value: "Not able to load cookie" });
            //console.log("Parameters updated in store - cookie still not created, and banner to be shown");
          }
          else {
          //  console.log("My cookie: ", cookieRight, cookieRight.CookieRight);
          //  console.log("CookieRightx: ", cookieRight.CookieRight, cookieRight.AuthSetup, cookieRight.CredRemindSetup);
            //console.log("Cookie found, we have to update the state");
            //console.log("authSetup.authSetup: ", cookieRight.AuthSetup, " credRemindSetup.credRemindSetup", cookieRight.CredRemindSetup);
            //console.log("authSetup VALUE: ", authSetup.value);
            const value = {cookieSetup: cookieRight.CookieRight, authCookie: cookieRight.AuthSetup, idpassreminder: cookieRight.CredRemindSetup}
            //setCookieRight("CookieRight", cookieRight.CookieRight, { path: "/" , maxAge: 60*60*24*365,});
            //setCookieRight("AuthSetup", cookieRight.AuthSetup, { path: "/", maxAge: 60*60*24*365 });
            //setCookieRight("CredRemindSetup", cookieRight.CredRemindSetup, { path: "/", maxAge: 60*60*24*365, });

            dispatch({ type: CREATE_COOKIE_SETUP_SUCCESS, value });

          }
      }
      else {
        if (cookieSetup === false) {
            //console.log("CookieSetup = NOT undefined: ", cookieSetup);
          console.log("We have to show the banner and save parameters");
        }
        else {
            console.log("State of the choices updated for the first time (yes), creation of cookies");
            //console.log("STATE EN COURS: ", cookieSetup, authCookie, idpassreminder );
            //const [credRemindSetup, setCredRemindSetup] = useCookies(["CredRemindSetup"]);
            setCookieRight("CookieRight", cookieSetup, { path: "/" , maxAge: 60*60*24*365,});
            setCookieRight("AuthSetup", authCookie, { path: "/", maxAge: 60*60*24*365 });
            setCookieRight("CredRemindSetup", idpassreminder, { path: "/", maxAge: 60*60*24*365, });

        }






      }




    }
    catch {
      console.log("ERROR cookieReading")
      dispatch({ type: CREATE_COOKIE_SETUP_FAILURE, value: "Not able to load cookie" });
    }
  }


}
*/
export const AcceptCookies = async (dispatch, cookiesValues) => {
  //const [legalCookie, setLegalCookie] = useCookies(["legal"]);
  console.log("AcceptCookie reducer");
  //const [cookieRight, setCookieRight] = useCookies(["CookieRight"]);
  //console.log("Here in legal then");
    //const [cookies, setCookie] = useCookies(["legal"]);

    //console.log("Access legal cookie");
    console.log("DISPATCH: AcceptCookies: ", cookiesValues);
    /*
    setLegalCookie("cookieSetup", cookiesValues.cookieSetup, {
      path: "/"
    });

    setCookie("authCookie", cookiesValues.authCookie, {
      path: "/"
    });
    setCookie("idpassreminder", cookiesValues.idpassreminder, {
      path: "/"
    });
*/
    //const values = {cookieSetup: true, authCookie: cookieChoice.cookie1, idpassreminder: cookieChoice.cookie2}
    //setCookieRight("CookieRight", cookiesValues.cookieSetup, { path: "/" , maxAge: 60*60*24*365,});
    //setCookieRight("AuthSetup", cookiesValues.authCookie, { path: "/", maxAge: 60*60*24*365 });
    //setCookieRight("CredRemindSetup", cookiesValues.idpassreminder, { path: "/", maxAge: 60*60*24*365, });

    dispatch({ type: CREATE_COOKIE_SETUP_SUCCESS, value:cookiesValues });


}


function reducer(state = initialState, action) {
    switch (action.type) {
    case CREATE_COOKIE_SETUP_REQUEST:
      return {
         ...state
          
      }
    case CREATE_COOKIE_SETUP_SUCCESS:
      return {
        ...state,
        cookieSetup: action.value.cookieSetup,
        authCookie: action.value.authCookie,
        idpassreminder: action.value.idpassreminder,
        cookiesLoaded: true
      }
    case CREATE_COOKIE_SETUP_FAILURE:
      return {
        ...state
        

      }
    default:
      return state
  }
}

export const selectCookieSetup = (state) => state.legal.cookieSetup;
export const selectAuthCookie = (state) => state.legal.authCookie;
export const selectIdpassreminder = (state) => state.legal.idpassreminder;
export const selectCookiesLoaded = (state) => state.legal.cookiesLoaded;

export default reducer;
