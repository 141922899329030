/*######################################################
Creation Date: 14/07/2021
Author: Jean-Baptiste ROUGET
Updates:
# 29/09/2021: Creation
#
#
######################################################*/
import brandlogosmall from '../../assets/logo/Brand.png';
import Container from '@material-ui/core/Container';

function Brand(props) {
    let imagefile = brandlogosmall;



    return (
        <Container className="BrandComplete" >
            <Container className="brandIcon" style={{verticalAlign: "center"}}>
                { props.small ? <></> : <img src={imagefile} alt="Les Toqués logo" style={{maxHeigth: '40px'}}/>}
                {props.title}
            </Container>
            
        </Container>
    );
}

export default Brand;
