/*######################################################
Creation Date: 01/01/2021
Author: Jean-Baptiste ROUGET
Definition: This page aims at loging in
Updates:
# 11/01/2021: Creation
# 26/08/2021: Refining code, adding comprehensive comments, decreasing file size
# 28/09/2021: Correcting warnings and avoiding ES lint warnings
######################################################*/
import React from 'react';
import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';
import styles from '../../theme/style';

const Privacy = (props) => {

    const classes = styles();
    return (
        <div className={classes.main}>
            <Container maxWidth="lg" component="main">
                <Typography variant="h3">Politique de confidentialité</Typography>
                <Typography variant="body2" align="justify" style={{marginBottom:"30px"}}>
                    Notre politique de confidentialité vous aidera à comprendre comment Les Toqués ("nous", "notre") utilise et protège les données que les utilisateurs de Les Toqués ("vous", "vos") nous partagez.
                    Nous nous réservons le droit de modifier notre politique de confidentilité à tout moment, en vous en notifiant systématiquement.
                    Si vous souhaitez vous assurer d'être à jour dans votre connaissance de notre politique de confidentialité, nous vous conseillons de vous reporter à cette page régulièrement.
                </Typography>
                <Typography variant="h5">Quelles données nous collectons</Typography>
                <Typography variant="body2" align="justify">
                    Lorsque vous visitez notre site web, nous collecterons potentiellement:
                </Typography>
                <Typography variant="body2" align="justify">
                    - Votre adresse IP. Cette adresse est l'identifiant unique de votre accès à internet. Celui-ci nous permet notament d'identifier votre reconnection à partir du même endroit. Veuillez noter que cette adresse IP fait partie intégrante de tout message transitant sur le Web, et sert d'identifiant unique de la source de chaque message.
                </Typography>
                <Typography variant="body2" align="justify">
                    - Des informations relatives à vos intérêts et préférences sur ce site.
                </Typography>
                <Typography variant="body2" align="justify" style={{marginBottom:"30px"}}>
                    - Des données à propos de votre comportement sur ce site.
                </Typography>


                <Typography variant="h5">Pourquoi collectons-nous ces données ?</Typography>
                <Typography variant="body2" align="justify">
                    Nous collectons ces données pour de nombreuses raisons:
                </Typography>
                <Typography variant="body2" align="justify">
                    - Afin de mieux comprendre vos besoins sur ce site.
                </Typography>
                <Typography variant="body2" align="justify">
                    - Afin d'améliorer nos produits et services
                </Typography>
                <Typography variant="body2" align="justify">
                    - Afin de vous communiquer des informations qui nous semblent d'intérêt pour vous, via email notament.
                </Typography>
                <Typography variant="body2" align="justify">
                    - Afin de vous contacter pour remplir nos questionnaires et ainsi participer à notre connaissance des attentes de nos clients et de notre marché.
                </Typography>
                <Typography variant="body2" align="justify" style={{marginBottom:"30px"}}>
                    - Afin de faire évoluer notre site web d'après la connaissance de votre comportement sur notre site et de vos préférences.
                </Typography>


                <Typography variant="h5">Protection de vos données</Typography>
                <Typography variant="body2" align="justify" style={{marginBottom:"30px"}}>
                    Nous prenons très au sérieux la protection de vos données afin de les garder confidentielles. Les Toqués a fait tout ce qui est en son pouvoir pour empêcher le vol et l'accès non autorisé à vos données
                    grâce à l'utilisation des dernières technologies à notre portée.
                </Typography>



                <Typography variant="h5">Notre politique sur les Cookies</Typography>
                <Typography variant="body2" align="justify">
                    Une fois que vous acceptez l'usage de cookies par notre site web, vous acceptez simultanément l'utilisation des données collectées par ces cookies à propos de votre comportement et de vos choix sur ce site (analyse du traffic web, pages web sur lesquelles vous passez le plus de temps, et sites que vous visitez).
                </Typography>
                <Typography variant="body2" align="justify">
                    Les données collectées par ces cookies servent à améliorer notre site selon vos besoins.
                </Typography>
                <Typography variant="body2" align="justify">
                    Veuillez prendre note que les cookies ne permettent pas de prendre le contrôle de votre ordinateur, d'une quelconque manière. Les cookies sont strictement limités à votre navigatuer web et n'ont donc accès qu'aux informations du site Les Toqués.
                </Typography>
                <Typography variant="body2" align="justify" style={{marginBottom:"30px"}}>
                    Si vous souhaitez annuler l'utilisation des cookies, vous pouvez le faire à tout moment dans les paramètres de votre compte Les Toqués.
                </Typography>

                <Typography variant="h5">Détails sur la liste des cookies que nous utilisons</Typography>
                <Typography variant="body2" align="justify">
                    Une fois que vous acceptez l'usage de cookies par notre site web, totalement ou partiellement, les cookies acceptés correspondront à ceux-ci:
                </Typography>
                <Typography variant="body2" align="justify">
                    - CookieRigtht: celui-ci est le seul cookie qui existera, avec ou sans votre accord, car ne contient que la définition de si vous avez ou non indiqué votre choix. Ce cookie détermine uniquement l'affichage ou non de la bannière de cookie.
                </Typography>
                <Typography variant="body2" align="justify">
                    - AuthSetup: cookie facultatif, permettant d'enregistrer les paramètres d'accès automatique au site en mode connecté. Ainsi, en acceptant ce cookie, vous vous retrouverez connecté automatiquement après avoir été connecté une fois.
                </Typography>
                <Typography variant="body2" align="justify" style={{marginBottom:"30px"}}>
                    - CredMindSetup: cookie facultatif permettant de stocker dans votre navigateur votre identifiant et mot de passe afin de remplir automatiquement le formaulaire de connection. Le mot de passe y est crypté. Veuillez noter que le navigateur peut de lui-même vous demander d'enregistrer ces informations dans son système, hors cadre d'usage de nos cookies.
                </Typography>
                <Typography variant="body2" align="justify" style={{marginBottom:"30px"}}>
                    Veuillez noter que vous pouvez à tout moment effacer les cookies de votre navigateur, et ainsi modifier comme bon vous semble votre choix à l'égard de ces cookies.
                </Typography>

                <Typography variant="h5">Liens vers d'autres sites web</Typography>
                <Typography variant="body2" align="justify" style={{marginBottom:"30px"}}>
                    Notre site web contient des liens pouvant mener à d'autres sites web. Lorsque vous cliquez sur ces liens, Les Toqués n'est pas responsable de la protection de vos données ou de votre vie privée sur ces sites.
                    Visiter ces sites n'est en aucun cas protégé par la politique de confidentialité de Les Toqués. Veuillez vous assurer de lire la politique de confidentialité du site sur lequel vous allez à partir de notre site web.
                </Typography>

                <Typography variant="h5">Diminuer la collection de vos données personnelles</Typography>
                <Typography variant="body2" align="justify">
                    A un moment donné, vous souhaiterez peut-être restreindre l'usage ou la collection de vos données personnelles. Vous pouvez le faire comme suit:
                </Typography>
                <Typography variant="body2" align="justify">
                    - Lorsque vous remplissez un formulaire sur notre site, veuillez vous assurer qu'il y a une section que vous pouvez maintenir non activée, si vous ne souhaitez pas partager vos informations personnelles.
                </Typography>
                <Typography variant="body2" align="justify">
                    - Si vous avez au préalable accepté de partager vos données avec nous, veuillez nous contacter sans hésiter par email et nous nous ferons un plaisir d'appliquer ce changement sur votre compte.
                </Typography>
                <Typography variant="body2" align="justify">
                    Les Toqués ne louera, vendra ou distribuera jamais vos informations personnelles à des sociétés tierces, à moins d'obtenir votre permission au préalable. Nous le ferons dans le cas contraire seulement si la loi nous y contraint.
                    Vos informations personnelles seront utilisées pour vous envoyer des contenus promotionnels si vous accepté notre poiltique de confidentialité
                </Typography>
            </Container>
        </div>
    );


}




/*
function mapStateToProps({ authentication, currentUser, pointofsale }) {
  return { authentication, currentUser, pointofsale };

}

export default connect(mapStateToProps) (Header);

*/
export default Privacy;
